import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { CartDistributorInfo } from './CartDistributorInfo';
import { CartItem } from './CartItem';

export class Cart {
    constructor (
        private readonly cartItems : Array<CartItem>,
        private readonly cartDistributorInfoById : StringValueMap<DistributorId | null, CartDistributorInfo>,
        private readonly cartHashValue : string,
    ) { }

    public getCartItems() : Array<CartItem> {
        return this.cartItems;
    }

    public getCartDistributorInfoById() : StringValueMap<DistributorId | null, CartDistributorInfo> {
        return this.cartDistributorInfoById;
    }

    public getCartHashValue() : string {
        return this.cartHashValue;
    }
}
