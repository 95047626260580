import { Cart } from 'api/Cart/model/Cart';
import { CartItem } from 'api/Cart/model/CartItem';
import { InventoryColumnOption } from 'api/Cart/model/InventoryColumnOption';
import { UsageColumnOption } from 'api/Cart/model/UsageColumnOption';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRepId } from 'api/Distributor/model/DistributorRepId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';
import { CartDistributorInfo } from '../model/CartDistributorInfo';

interface ICartJSONObject {
    cart_items : Array<{
        product_id : string;
        distributor_id : string | null;
        product_quantity_unit : string;
        unit_quantity : number;
    }>;
    cart_distributor_info_by_id : {
        [distributorIdValue : string] : {
            saved_for_later : boolean,
            retailer_note : string,
            reps : Array<string>,
            purchase_order_number : string;
        }
    };
    cart_hash : string;
}

export type groupByOptionValue = 'no_grouping' | 'distributor' | 'product_category_id' | 'product_type';
export type usageColumnOptionValue = 'par' | 'weekly_usage' | 'actual_usage' | 'quantity_to_order';
export type referenceInventoryOptionValue = 'draft' | 'latest' | 'expected';

export interface IAdditionalCartBuilderDataJSONObject {
    comparison_option : usageColumnOptionValue | null;
    group_by_option :  groupByOptionValue | null;
    reference_inventory_option: referenceInventoryOptionValue | null;
    default_product_quantity_unit_by_id : { [productIdValue : string] : string };
    expected_inventory_bottle_count_by_product_id : { [productIdValue : string] : number } | null;
    // expected_inventory_breakdown: {}
}

export class CartJSONToObjectSerializer {
    constructor(
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
    ) {}

    public getCart(
        cartJSONObject : ICartJSONObject
    ) : Cart {

        const cartItems = cartJSONObject.cart_items.map((cartItem) => {
            return new CartItem(
                this.productJSONToObjectSerializer.getProductId(cartItem.product_id),
                cartItem.distributor_id ? new DistributorId(cartItem.distributor_id) : null,
                this.productJSONToObjectSerializer.getProductQuantityUnit(cartItem.product_quantity_unit),
                cartItem.unit_quantity
            );
        });

        const cartDistributorInfoById = new StringValueMap<DistributorId | null, CartDistributorInfo>();
        Object.keys(cartJSONObject.cart_distributor_info_by_id).forEach((distributorIdValue) => {
            const cartDistributorInfoJSON = cartJSONObject.cart_distributor_info_by_id[distributorIdValue];

            const includedDistributorReps = new StringValueSet(cartDistributorInfoJSON.reps.map((repIdValue) => {
                return new DistributorRepId(repIdValue);
            }));

            cartDistributorInfoById.set(
                (distributorIdValue === 'null') ? null : new DistributorId(distributorIdValue),
                new CartDistributorInfo(
                    cartDistributorInfoJSON.saved_for_later,
                    cartDistributorInfoJSON.retailer_note,
                    includedDistributorReps,
                    cartDistributorInfoJSON.purchase_order_number,
                )
            );
        });

        return new Cart(
            cartItems,
            cartDistributorInfoById,
            cartJSONObject.cart_hash
        );
    }

    public getAdditionalCartBuilderData(
        additionalCartBuilderDataJSONObject : IAdditionalCartBuilderDataJSONObject
    ) {
        const {
            comparison_option,
            group_by_option,
            reference_inventory_option,
            default_product_quantity_unit_by_id,
            expected_inventory_bottle_count_by_product_id,
        } = additionalCartBuilderDataJSONObject;

        const defaultProductQuantityUnitByProductId = new StringValueMap<ProductId, ProductQuantityUnit>();
        Object.keys(default_product_quantity_unit_by_id).forEach((productIdValue : string) => {
            defaultProductQuantityUnitByProductId.set(
                this.productJSONToObjectSerializer.getProductId(productIdValue),
                this.productJSONToObjectSerializer.getProductQuantityUnit(default_product_quantity_unit_by_id[productIdValue])
            );
        });

        let expectedInventoryBottleCountByProductId : StringValueMap<ProductId, number> | null = null;
        if (expected_inventory_bottle_count_by_product_id) {
            const inventoryBottleCountByProductId = new StringValueMap<ProductId, number>();
            Object.keys(expected_inventory_bottle_count_by_product_id).forEach((productIdValue : string) => {
                inventoryBottleCountByProductId.set(
                    this.productJSONToObjectSerializer.getProductId(productIdValue),
                    expected_inventory_bottle_count_by_product_id[productIdValue]
                );
            });

            expectedInventoryBottleCountByProductId = inventoryBottleCountByProductId;
        }

        let groupByOption : GroupByOption | null;
        switch (group_by_option) {
            case 'no_grouping':
                groupByOption = GroupByOption.ALL_ITEMS;
                break;
            case 'distributor':
                groupByOption = GroupByOption.DISTRIBUTOR;
                break;
            case 'product_category_id':
                groupByOption = GroupByOption.CATEGORY;
                break;
            case 'product_type':
                groupByOption = GroupByOption.ITEM_TYPE;
                break;
            case null:
                groupByOption = null;
                break;
            default:
                throw new RuntimeException(`unexpected groupByOption: ${ group_by_option }`);
        }

        let inventoryColumnOption : InventoryColumnOption | null;
        switch (reference_inventory_option) {
            case 'draft':
                inventoryColumnOption = InventoryColumnOption.INVENTORY_DRAFT;
                break;
            case 'latest':
                inventoryColumnOption = InventoryColumnOption.LATEST_FINALIZED_INVENTORY;
                break;
            case 'expected':
                inventoryColumnOption = InventoryColumnOption.EXPECTED_INVENTORY;
                break;
            case null:
                inventoryColumnOption = null;
                break;
            default:
                throw new RuntimeException(`unexpected inventoryColumnOption: ${ reference_inventory_option }`);
        }

        let usageColumnOption;
        switch (comparison_option) {
            case 'weekly_usage':
                usageColumnOption = UsageColumnOption.WEEKLY_USAGE;
                break;
            case 'actual_usage':
                usageColumnOption = UsageColumnOption.ACTUAL_USAGE;
                break;
            case 'quantity_to_order':
                usageColumnOption = UsageColumnOption.QUANTITY_TO_ORDER;
                break;
            case 'par':
                usageColumnOption = UsageColumnOption.PRODUCT_PAR;
                break;
            case null:
                usageColumnOption = null;
                break;
            default:
                throw new RuntimeException(`unexpected usageColumnOption: ${ comparison_option }`);
        }

        return {
            usageColumnOption,
            groupByOption,
            inventoryColumnOption,
            defaultProductQuantityUnitByProductId,
            expectedInventoryBottleCountByProductId,
        };
    }
}
