import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorAdjustmentId } from 'api/Distributor/model/DistributorAdjustmentId';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { DeliveryLineItem } from 'api/Ordering/model/DeliveryLineItem';
import { DeliveryMetadata } from 'api/Ordering/model/DeliveryMetadata';
import { DeliveryRepInfo } from 'api/Ordering/model/DeliveryRepInfo';
import { InvoiceProcessingData } from 'api/Ordering/model/InvoiceProcessingData';
import { InvoiceProcessingResult } from 'api/Ordering/model/InvoiceProcessingResult';
import { InvoiceProcessingResultAdjustment } from 'api/Ordering/model/InvoiceProcessingResultAdjustment';
import { InvoiceProcessingResultLineItem } from 'api/Ordering/model/InvoiceProcessingResultLineItem';
import { InvoiceUpload } from 'api/Ordering/model/InvoiceUpload';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { PriceAdjustment } from 'api/Ordering/model/PriceAdjustment';
import { IDeliveryJSONObject, IDeliveryMetadataJSONObject } from 'api/Ordering/serializer/IDeliveryJSONObject';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';
import moment from 'moment-timezone';

import { NULL_DISTRIBUTOR_ID } from 'shared/constants';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { InvoiceProcessingResultSource } from '../model/InvoiceProcessingResultSource';
import { NormalizedLineItem } from '../model/NormalizedLineItem';

interface IInvoiceUploadJSON {
    name : string;
    url : string;
    upload_time : string;
    file_extension : string;
    order_id : string | null;
    invoice_processing_data : IInvoiceProcessingDataJSON | null;
}

interface INormalizedPackagingJSON {
    content: INormalizedPackagingJSON | null;
    quantity_of_content: number;
    name: string;
    unit: string;
}

interface IInvoiceProcessingDataJSON {
    request_user_id : string | null;
    request_time : string | null;
    processed_invoice_upload_ids_by_child_invoice_external_id : { [key : string] : string | null };
    result : {
        invoice_number : string | null;
        date : string | null;   // LOCAL date WITHOUT time
        distributor_name : string | null;
        total_amount : number | null;
        line_items : Array<{
            line_item_id : string,
            name : string,
            sku : string | null,
            price : number | null,
            quantity : number | null,
            unit : string | null,
            unit_deposit : number | null,
            discount: number | null,
            tax: number | null,
            other_adjustments: number | null,
            pack_size: string | null,
            normalized_line_item: {
                'id': string,
                'brand': string,
                'category': string,
                'name': string,
                'sku': string,
                'tags': { [key : string] : string | null },
            } | null,
            normalized_packaging: INormalizedPackagingJSON;
        }>;
        adjustments : Array<{
            name: string | null,
            amount: number | null,
        }>
        error_message : string | null;
        source: string;
        bevspot_distributor_id: string,
        normalized_distributor_id: string,
        normalized_distributor_name: string,
    } | null;
    result_time : string | null;
    review_user_id : string | null;
    review_time : string | null;
}

export class OrderingJSONToObjectSerializer {

    constructor(
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
    ) {}

    public getDeliveryId(
        deliveryIdValue : string
    ) : DeliveryId {
        return new DeliveryId(deliveryIdValue);
    }

    public getInvoiceUploadId(
        invoiceUploadIdValue : string
    ) : InvoiceUploadId {
        return new InvoiceUploadId(invoiceUploadIdValue);
    }

    public getInvoiceProcessingResultSource(
        invoiceProcessingResultSourceValue : string
    ) : InvoiceProcessingResultSource {
        switch (invoiceProcessingResultSourceValue) {
            case 'PLATE_IQ':
                return InvoiceProcessingResultSource.PLATE_IQ;
            case 'FINTECH':
                return InvoiceProcessingResultSource.FINTECH;
            case 'ROSSUM':
                return InvoiceProcessingResultSource.ROSSUM;
            case 'STEDI_SYSCO':
                return InvoiceProcessingResultSource.STEDI_SYSCO;
            case 'STEDI_USFOODS':
                return InvoiceProcessingResultSource.STEDI_USFOODS;
            default:
                throw new RuntimeException(`unexpected invoiceProcessingResultSource: ${ invoiceProcessingResultSourceValue }`);
        }
    }

    public getDeliveryMetadataById(
        deliveryMetadataJSONObjects : { [idValue : string] : IDeliveryMetadataJSONObject }
    ) : StringValueMap<DeliveryId, DeliveryMetadata> {
        const deliveries = new StringValueMap<DeliveryId, DeliveryMetadata>();

        Object.keys(deliveryMetadataJSONObjects).forEach((idValue) => {
            const json = deliveryMetadataJSONObjects[idValue];
            deliveries.set(
                this.getDeliveryId(idValue),
                this.getDeliveryMetadata(json)
            );
        });

        return deliveries;
    }

    public getDeliveriesById(
        deliveriesByIdJSONObject : { [idValue : string] : IDeliveryJSONObject }
    ) : StringValueMap<DeliveryId, Delivery> {
        const deliveries = new StringValueMap<DeliveryId, Delivery>();

        Object.keys(deliveriesByIdJSONObject).forEach((idValue) => {
            const deliveryJSONObject = deliveriesByIdJSONObject[idValue];
            deliveries.set(
                this.getDeliveryId(idValue),
                this.getDelivery(deliveryJSONObject)
            );
        });

        return deliveries;
    }

    public getInvoiceUploadsById(
        invoiceUploadsByIdJSON : {[invoiceUploadIdValue : string] : IInvoiceUploadJSON},
    ) : StringValueMap<InvoiceUploadId, InvoiceUpload> {
        const invoiceUploadsById = new StringValueMap<InvoiceUploadId, InvoiceUpload>();
        Object.keys(invoiceUploadsByIdJSON).forEach((invoiceUploadIdValue) => {
            const invoiceUploadJSON = invoiceUploadsByIdJSON[invoiceUploadIdValue];
            invoiceUploadsById.set(
                this.getInvoiceUploadId(invoiceUploadIdValue),
                this.getInvoiceUpload(invoiceUploadJSON)
            );
        });

        return invoiceUploadsById;
    }

    public getInvoiceUploadsByDeliveryId(
        invoiceUploadsByIdByDeliveryIdJSONData : {[deliveryIdValue : string] : {[invoiceUploadIdValue : string] : IInvoiceUploadJSON}},
    ) : StringValueMap<DeliveryId, StringValueMap<InvoiceUploadId, InvoiceUpload>> {
        const invoiceUploadsByDeliveryId = new StringValueMap<DeliveryId, StringValueMap<InvoiceUploadId, InvoiceUpload>>();

        Object.keys(invoiceUploadsByIdByDeliveryIdJSONData).forEach((deliveryIdValue) => {
            invoiceUploadsByDeliveryId.set(
                this.getDeliveryId(deliveryIdValue),
                this.getInvoiceUploadsById(invoiceUploadsByIdByDeliveryIdJSONData[deliveryIdValue])
            );
        });

        return invoiceUploadsByDeliveryId;
    }

    public getInvoiceUpload(uploadJSON : IInvoiceUploadJSON) : InvoiceUpload {
        const invoiceProcessingDataJSON = uploadJSON.invoice_processing_data;
        return new InvoiceUpload(
            uploadJSON.name,
            uploadJSON.url,
            moment.utc(uploadJSON.upload_time),
            uploadJSON.file_extension,
            uploadJSON.order_id ? this.getDeliveryId(uploadJSON.order_id) : null,
            invoiceProcessingDataJSON ? this.getInvoiceProcessingData(invoiceProcessingDataJSON) : null,
        );
    }

    public getInvoiceProcessingData(invoiceProcessingDataJSON : IInvoiceProcessingDataJSON) : InvoiceProcessingData {
        let invoiceProcessingResult : InvoiceProcessingResult | null = null;
        if (invoiceProcessingDataJSON.result) {
            const resultJSON = invoiceProcessingDataJSON.result;

            invoiceProcessingResult = new InvoiceProcessingResult(
                resultJSON.invoice_number,
                resultJSON.date ? moment.utc(resultJSON.date) : null,
                resultJSON.distributor_name,
                resultJSON.line_items.map((lineItemJSON) => {
                    let normalizedLineItem = null;
                    if (lineItemJSON.normalized_line_item) {
                        normalizedLineItem = new NormalizedLineItem(
                            lineItemJSON.normalized_line_item.id,
                            lineItemJSON.normalized_line_item.brand,
                            lineItemJSON.normalized_line_item.name,
                            lineItemJSON.normalized_line_item.sku,
                            lineItemJSON.normalized_line_item.category,
                        );
                    }

                    return new InvoiceProcessingResultLineItem(
                        lineItemJSON.line_item_id,
                        lineItemJSON.name,
                        lineItemJSON.sku,
                        lineItemJSON.price,
                        lineItemJSON.quantity,
                        lineItemJSON.unit,
                        lineItemJSON.unit_deposit,
                        lineItemJSON.discount,
                        lineItemJSON.tax,
                        lineItemJSON.other_adjustments,
                        lineItemJSON.pack_size,
                        normalizedLineItem
                    );
                }),
                resultJSON.adjustments.map((adjustment) => {
                    return new InvoiceProcessingResultAdjustment(adjustment.name, adjustment.amount);
                }),
                resultJSON.total_amount,
                resultJSON.error_message,
                this.getInvoiceProcessingResultSource(resultJSON.source),
            );
        }

        let requestUserId : UserAccountId | null = null;
        if (invoiceProcessingDataJSON.request_user_id) {
            requestUserId = new UserAccountId(invoiceProcessingDataJSON.request_user_id);
        }

        let requestTime : moment.Moment | null = null;
        if (invoiceProcessingDataJSON.request_time) {
            requestTime = moment.utc(invoiceProcessingDataJSON.request_time);
        }

        const processedInvoiceUploadIdsByChildInvoiceExternalId : { [k: string] : null | InvoiceUploadId } = {};
        Object.keys(invoiceProcessingDataJSON.processed_invoice_upload_ids_by_child_invoice_external_id).forEach((k : string) => {
            const v = invoiceProcessingDataJSON.processed_invoice_upload_ids_by_child_invoice_external_id[k];
            processedInvoiceUploadIdsByChildInvoiceExternalId[k] = v === null ? null : new InvoiceUploadId(v);
        });

        let resultTime : moment.Moment | null = null;
        if (invoiceProcessingDataJSON.result_time) {
            resultTime = moment.utc(invoiceProcessingDataJSON.result_time);
        }

        let reviewUserId : UserAccountId | null = null;
        if (invoiceProcessingDataJSON.review_user_id) {
            reviewUserId = new UserAccountId(invoiceProcessingDataJSON.review_user_id);
        }

        let reviewTime : moment.Moment | null = null;
        if (invoiceProcessingDataJSON.review_time) {
            reviewTime = moment.utc(invoiceProcessingDataJSON.review_time);
        }

        return new InvoiceProcessingData(
            requestUserId,
            requestTime,
            processedInvoiceUploadIdsByChildInvoiceExternalId,
            invoiceProcessingResult,
            resultTime,
            reviewUserId,
            reviewTime,
        );
    }

    public getDeliveryMetadata(
        deliveryJSONObject : IDeliveryMetadataJSONObject
    ) : DeliveryMetadata {
        let recordedByUserId : UserAccountId | null;
        if (deliveryJSONObject.recorded_by === null) {
            recordedByUserId = null;
        } else {
            recordedByUserId = new UserAccountId(deliveryJSONObject.recorded_by);
        }
        let dateDelivered : moment.Moment;
        let deliveryConfirmationEventTimeUTC : moment.Moment | null = null;
        let deliveryConfirmationUserId : UserAccountId | null = null;
        if (deliveryJSONObject.delivery_report) {
            dateDelivered = moment.utc(deliveryJSONObject.delivery_report.time_delivered);
            deliveryConfirmationEventTimeUTC = moment.utc(deliveryJSONObject.delivery_report.time_reported);
            deliveryConfirmationUserId = new UserAccountId(deliveryJSONObject.delivery_report.reporter_user_id);
        } else if (deliveryJSONObject.predicted_delivery_time) {
            dateDelivered = moment.utc(deliveryJSONObject.predicted_delivery_time);
        } else {
            throw new RuntimeException('unexpectedly able to parse "dateDelivered"');
        }

        const datePlaced = moment.utc(deliveryJSONObject.time_placed);
        const dateRecorded = moment.utc(deliveryJSONObject.time_recorded);

        const distributorId = deliveryJSONObject.distributor_id === NULL_DISTRIBUTOR_ID ? null : new DistributorId(deliveryJSONObject.distributor_id);

        const distributorReps = deliveryJSONObject.distributor_reps.map((distributorRepJSON) => {
            return new DeliveryRepInfo(
                distributorRepJSON.name,
                distributorRepJSON.email_address,
                distributorRepJSON.phone_number,
                distributorRepJSON.bevspot_sent_email_to_email_address,
                distributorRepJSON.bevspot_sent_sms_to_phone_number,
                distributorRepJSON.attach_csv,
            );
        });

        return new DeliveryMetadata(
            datePlaced,
            dateDelivered,
            distributorId,
            deliveryJSONObject.invoice_number,
            deliveryJSONObject.note_to_self,
            recordedByUserId,
            dateRecorded,
            deliveryConfirmationEventTimeUTC,
            deliveryConfirmationUserId,
            deliveryJSONObject.note_to_rep,
            distributorReps
        );
    }

    public getDelivery(
        deliveryJSONObject : IDeliveryJSONObject
    ) : Delivery {
        const productLineItems = deliveryJSONObject.line_items.map((lineItem) => {

            return new DeliveryLineItem(
                new ProductId(lineItem.product_id),
                this.productJSONToObjectSerializer.getProductQuantityUnit(lineItem.product_quantity_unit),
                lineItem.unit_quantity,
                lineItem.unit_price.value,
                lineItem.unit_deposit.value,
                lineItem.discount.value,
                lineItem.tax.value,
                lineItem.other_adjustments.value,
                lineItem.invoice_upload_id ? this.getInvoiceUploadId(lineItem.invoice_upload_id) : null,
                lineItem.invoice_upload_line_item_id ? lineItem.invoice_upload_line_item_id : null,
            );
        });

        let dateDelivered : moment.Moment;
        let deliveryConfirmationEventTimeUTC : moment.Moment | null = null;
        let deliveryConfirmationUserId : UserAccountId | null = null;
        if (deliveryJSONObject.delivery_report) {
            dateDelivered = moment.utc(deliveryJSONObject.delivery_report.time_delivered);
            deliveryConfirmationEventTimeUTC = moment.utc(deliveryJSONObject.delivery_report.time_reported);
            deliveryConfirmationUserId = new UserAccountId(deliveryJSONObject.delivery_report.reporter_user_id);
        } else if (deliveryJSONObject.predicted_delivery_time) {
            dateDelivered = moment.utc(deliveryJSONObject.predicted_delivery_time);
        } else {
            throw new RuntimeException('unexpectedly able to parse "dateDelivered"');
        }

        const datePlaced = moment.utc(deliveryJSONObject.time_placed);
        const dateRecorded = moment.utc(deliveryJSONObject.time_recorded);

        const distributorId = deliveryJSONObject.distributor_id === NULL_DISTRIBUTOR_ID ? null : new DistributorId(deliveryJSONObject.distributor_id);

        const priceAdjustmentsByProductCategoryId = new Map<string, Array<PriceAdjustment>>();
        Object.keys(deliveryJSONObject.price_adjustments_by_product_category_id).forEach((productCategoryId : string) => {
            const priceAdjustmentsForCategory = deliveryJSONObject.price_adjustments_by_product_category_id[productCategoryId].map((priceAdjustmentJSON) => {
                return new PriceAdjustment(
                    priceAdjustmentJSON.note ,
                    priceAdjustmentJSON.price.value,
                    priceAdjustmentJSON.standard_adjustment_id === null ? null : new DistributorAdjustmentId(priceAdjustmentJSON.standard_adjustment_id),
                    priceAdjustmentJSON.gl_code);
            });

            priceAdjustmentsByProductCategoryId.set(productCategoryId, priceAdjustmentsForCategory);
        });

        const uncategorizedPriceAdjustments = deliveryJSONObject.uncategorized_price_adjustments.map((priceAdjustmentJSON) => {
            return new PriceAdjustment(
                priceAdjustmentJSON.note ,
                priceAdjustmentJSON.price.value,
                priceAdjustmentJSON.standard_adjustment_id === null ? null : new DistributorAdjustmentId(priceAdjustmentJSON.standard_adjustment_id),
                priceAdjustmentJSON.gl_code);
        });

        const distributorReps = deliveryJSONObject.distributor_reps.map((distributorRepJSON) => {
            return new DeliveryRepInfo(
                distributorRepJSON.name,
                distributorRepJSON.email_address,
                distributorRepJSON.phone_number,
                distributorRepJSON.bevspot_sent_email_to_email_address,
                distributorRepJSON.bevspot_sent_sms_to_phone_number,
                distributorRepJSON.attach_csv,
            );
        });

        const hiddenInvoiceUploadLineItemIdsByInvoiceUploadId = new StringValueMap<InvoiceUploadId, Set<string>>();
        Object.keys(deliveryJSONObject.hidden_invoice_upload_line_item_ids_by_invoice_upload_id).forEach((invoiceUploadIdValue : string) => {
            hiddenInvoiceUploadLineItemIdsByInvoiceUploadId.set(
                new InvoiceUploadId(invoiceUploadIdValue),
                new Set(deliveryJSONObject.hidden_invoice_upload_line_item_ids_by_invoice_upload_id[invoiceUploadIdValue])
            );
        });

        const approvalEvent : UserAccountIdAndTimestamp | null = deliveryJSONObject.approval_event === null ?
            null :
            UserAccountUtils.getUserAccountIdAndTimestampObjectFromJSON(deliveryJSONObject.approval_event);

        const readyForApprovalEvent : UserAccountIdAndTimestamp | null = deliveryJSONObject.ready_for_approval_event === null ?
            null :
            UserAccountUtils.getUserAccountIdAndTimestampObjectFromJSON(deliveryJSONObject.ready_for_approval_event);

        const lastUpdateEvent : UserAccountIdAndTimestamp | null = deliveryJSONObject.last_update_event === null ?
            null :
            UserAccountUtils.getUserAccountIdAndTimestampObjectFromJSON(deliveryJSONObject.last_update_event);
        const taxesByName = new Map<string, number>();
        deliveryJSONObject.taxes.forEach((taxJSON) => {
            taxesByName.set(taxJSON.name, taxJSON.price.value);
        });
        return new Delivery(
            datePlaced,
            dateDelivered,
            distributorId,
            productLineItems,
            priceAdjustmentsByProductCategoryId,
            uncategorizedPriceAdjustments,
            taxesByName,
            deliveryJSONObject.invoice_number,
            deliveryJSONObject.user_defined_total,
            deliveryJSONObject.note_to_rep,
            deliveryJSONObject.note_to_self,
            deliveryJSONObject.purchase_order_number,
            distributorReps,
            deliveryJSONObject.recorder_name,
            dateRecorded,
            deliveryConfirmationEventTimeUTC,
            deliveryConfirmationUserId,
            hiddenInvoiceUploadLineItemIdsByInvoiceUploadId,
            approvalEvent,
            readyForApprovalEvent,
            lastUpdateEvent
    );
    }
}
