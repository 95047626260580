import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { productUtils } from 'api/Product/utils/productUtils';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import _ from 'lodash';
import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { DefaultPour, ILocationSettingsService } from 'api/Location/interfaces/ILocationSettingsService';
import { LocationId } from 'api/Location/model/LocationId';
import { IProductQuickAddService } from 'api/Onboarding/interfaces/IProductQuickAddService';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { ProductQuickAddUtils } from 'api/Onboarding/utils/ProductQuickAddUtils';
import { IProductCostService } from 'api/Product/interfaces/IProductCostService';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { ItemLevelSalesReportConfigurationId } from 'api/Reports/model/ItemLevelSalesReportConfigurationId';
import { ISalesItemService } from 'api/SalesItem/interfaces/ISalesItemService';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';
import { IUserAccountInfoReader } from 'api/UserAccount/interfaces/IUserAccountInfoReader';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { momentObjectToThriftSerializer, productJSONToObjectSerializer } from 'shared/lib/manager';
import { GroupByOption } from 'shared/models/GroupByOption';
import { batchActions, IAction } from 'shared/models/IAction';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { SortDirection } from 'shared/models/SortDirection';
import { Observer } from 'shared/utils/observer';
import { StringUtils } from 'shared/utils/stringUtils';

import { CreateOrEditSalesItemFormUtils, NOT_CALCULABLE_FIELD_VALUE } from '../utils/CreateOrEditSalesItemFormUtils';

import { ProductQuickAddSortingUtil, ProductSearchBarSortingUtil, SalesItemSearchBarSortingUtil } from 'apps/CreateOrEditSalesItem/utils/ProductAndSalesItemSearchBarSortingUtil';
import { getCachedSalesItemCostsForIds } from 'apps/SalesItemManager/utils/CachedSalesItemCostUtils';
import { SalesItemManagerUtils } from 'apps/SalesItemManager/utils/SalesItemManagerUtils';

import { PosItemId } from 'api/SalesData/model/PosItemId';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import { NOTIFICATION_TIMEOUT_IN_MILLISECONDS } from 'shared/constants';
import {
    ComponentName,
    ICreateOrEditSalesItemState,
    IngredientFormFieldName,
    IngredientFormValidationByFieldName,
    IPmixItemInformation,
    ISalesItemForm,
    ISalesItemFormData,
    ProductIngredientRowFormFieldName,
    ProductIngredientRowInfoByFormFieldName,
    ProductQuickAddRowFormFieldName,
    SalesInformationFormFieldName,
    SalesInformationFormValidationByFieldName,
    SalesItemFormFieldName,
    SalesItemFormValidationByFieldName,
    SalesItemIngredientRowFormFieldName,
    SalesItemIngredientRowInfoByFormFieldName,
    SalesItemSaveOption,
    slimCreateSalesItemFormFieldNames
} from './../reducers/reducers';
import normalizeToTitleCase = StringUtils.normalizeToTitleCase;

export const CreateOrEditSalesItemActionTypes = {
    SET_SALES_ITEM_ID: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_ID',
    SET_NEEDS_ATTENTION_CATEGORY: 'CREATE_OR_EDIT_SALES_ITEM/SET_NEEDS_ATTENTION_CATEGORY',
    SET_SELECTED_INGREDIENT_TYPE: 'CREATE_OR_EDIT_SALES_ITEM/SET_SELECTED_INGREDIENT_TYPE',
    SET_SALES_ITEM_FORM_VALIDATION_BY_FIELD_NAME: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_FORM_VALIDATION_BY_FIELD_NAME',
    SET_INGREDIENT_FORM_VALIDATION_BY_FIELD_NAME: 'CREATE_OR_EDIT_SALES_ITEM/SET_INGREDIENT_FORM_VALIDATION_BY_FIELD_NAME',
    SET_SALES_INFORMATION_FORM_VALIDATION_BY_FIELD_NAME: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_INFORMATION_FORM_VALIDATION_BY_FIELD_NAME',
    SET_SALES_ITEM_FORM_DATA: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_FORM_DATA',
    SET_LINKED_ITEMS: 'CREATE_OR_EDIT_SALES_ITEM/SET_LINKED_ITEMS',
    SET_COMPONENT_IS_SHOWN: 'CREATE_OR_EDIT_SALES_ITEM/SET_COMPONENT_IS_SHOWN',
    SET_POPOVER_INGREDIENT_ID_IS_SHOWN: 'CREATE_OR_EDIT_SALES_ITEM/SET_POPOVER_INGREDIENT_ID_IS_SHOWN',
    UPDATE_MENU_GROUP: 'CREATE_OR_EDIT_SALES_ITEM/UPDATE_MENU_GROUP',
    SET_SORTED_MENU_GROUP_OPTIONS: 'CREATE_OR_EDIT_SALES_ITEM/SET_SORTED_MENU_GROUP_OPTIONS',
    SET_SALES_ITEM_FORM: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_FORM',
    SET_PRODUCT_INGREDIENT_ITEMS_ROW: 'CREATE_OR_EDIT_SALES_ITEM/SET_PRODUCT_INGREDIENT_ITEMS_ROW',
    SET_PRODUCT_QUICK_ADD_ITEMS_ROW: 'CREATE_OR_EDIT_SALES_ITEM/SET_PRODUCT_QUICK_ADD_ITEMS_ROW',
    SET_SALES_ITEM_INGREDIENT_ITEMS_ROW: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_INGREDIENT_ITEMS_ROW',
    RESET_SALES_ITEM_FORM: 'CREATE_OR_EDIT_SALES_ITEM/RESET_SALES_ITEM_FORM',
    SET_SELECTED_SAVE_OPTION: 'CREATE_OR_EDIT_SALES_ITEM/SET_SELECTED_SAVE_OPTION',
    SET_INGREDIENT_SEARCH_BAR_HIGHLIGHTED_ID: 'CREATE_OR_EDIT_SALES_ITEM/SET_INGREDIENT_SEARCH_BAR_HIGHLIGHTED_ID',
    SET_INGREDIENT_SEARCH_BAR_STATE: 'CREATE_OR_EDIT_SALES_ITEM/SET_INGREDIENT_SEARCH_BAR_STATE',
    SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_IDS_TO_DISPLAY: 'CREATE_OR_EDIT_SALES_ITEM/SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_IDS_TO_DISPLAY',
    SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_SALES_ITEM_IDS_TO_DISPLAY: 'CREATE_OR_EDIT_SALES_ITEM/SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_SALES_ITEM_IDS_TO_DISPLAY',
    SET_SELECTED_INDREDIENT_ID: 'CREATE_OR_EDIT_SALES_ITEM/SET_SELECTED_INDREDIENT_ID',
    RESET_ADD_NEW_INGREDIENT_FORM_SECTION: 'CREATE_OR_EDIT_SALES_ITEM/RESET_ADD_NEW_INGREDIENT_FORM_SECTION',
    SET_DEFAULT_POUR_BY_PRODUCT_CATEGORY: 'CREATE_OR_EDIT_SALES_ITEM/SET_DEFAULT_POUR_BY_PRODUCT_CATEGORY',
    SET_NEXT_AND_PREVIOUS_IDS: 'CREATE_OR_EDIT_SALES_ITEM/SET_NEXT_AND_PREVIOUS_IDS',
    SET_ID_TO_GO_TO_AFTER_SAVE: 'CREATE_OR_EDIT_SALES_ITEM/SET_ID_TO_GO_TO_AFTER_SAVE',
    SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_QUICK_ADD_ITEMS_TO_DISPLAY: 'SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_QUICK_ADD_ITEMS_TO_DISPLAY',
    SET_SALES_ITEM_IMAGE_UPLOAD_URL: 'CREATE_OR_EDIT_SALES_ITEM/SET_SALES_ITEM_IMAGE_UPLOAD',
};

export interface ICreateOrEditSalesItemStore {
    createOrEditSalesItemState : ICreateOrEditSalesItemState;
}

export namespace CreateOrEditSalesItemActionInterfaces {
    export interface ISetSalesItemId extends IAction {
        payload : {
            salesItemId : SalesInputRowId | null;
        };
    }

    export interface ISetNeedsAttentionCategory extends IAction {
        payload : {
            needsAttentionCategory : string | null;
        };
    }

    export interface ISetSalesItemFormValidationByFieldName extends IAction {
        payload : {
            fieldName : SalesItemFormFieldName;
            value : string;
            errorMessage : string;
            isValid : boolean;
        };
    }

    export interface ISetIngredientFormValidationByFieldName extends IAction {
        payload : {
            fieldName : IngredientFormFieldName;
            value : string;
            errorMessage : string;
            isValid : boolean;
        };
    }

    export interface ISetSalesInformationFormValidationByFieldName extends IAction {
        payload : {
            fieldName : SalesInformationFormFieldName;
            value : string;
            errorMessage : string;
            isValid : boolean;
        };
    }

    export interface ISetSalesItemFormData extends IAction {
        payload : {
            salesItemFormData : ISalesItemFormData;
        };
    }

    export interface ISetLinkedItems extends IAction {
        payload : {
            linkedItems : StringValueSet<SalesItemId>;
        };
    }

    export interface ISetComponentIsShown extends IAction {
        payload : {
            componentName : ComponentName;
            isShown : boolean;
        };
    }

    export interface ISetPopoverIngredientIdIsShown extends IAction {
        payload : {
            popoverIngredientIdIsShown : StringValueSet<ProductId | SalesItemId>;
            isShown : boolean;
        };
    }

    export interface IUpdateMenuGroup extends IAction {
        payload : {
            value : string;
        };
    }
    export interface ISetIngredientSearchBarHighlightedIngredientId extends IAction {
        payload : {
            ingredientId : ProductId | SalesItemId | ProductQuickAdd | null,
        };
    }

    export interface ISetSortedMenuGroupOptions extends IAction {
        payload : {
            sortedOptions : Array<IOption>;
        };
    }

    export interface ISetSalesItemForm extends IAction {
        payload : {
            salesItemForm : ISalesItemForm;
        };
    }

    export interface ISetProductIngredientItemsRow extends IAction {
        payload : {
            ingredientId : ProductId,
            formInfo : ProductIngredientRowInfoByFormFieldName | null,
        };
    }

    export interface ISetSalesItemIngredientItemsRow extends IAction {
        payload : {
            ingredientId : SalesItemId,
            formInfo : SalesItemIngredientRowInfoByFormFieldName | null,
        };
    }

    export interface IResetSalesItemForm extends IAction {
        payload : {};
    }

    export interface ISetSelectedSaveOption extends IAction {
        payload : {
            selectedSaveOption : SalesItemSaveOption;
        };
    }

    export interface ISetIngredientSearchBarState extends IAction {
        payload : {
            searchBar : ISearchBarState,
        };
    }

    export interface ISetIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay extends IAction {
        payload : {
            labelNamesAndSortedProductIdsToDisplay : Array<[string, Array<ProductId>]>,
        };
    }
    export interface ISetIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay extends IAction {
        payload : {
            labelNamesAndSortedProductQuickAddItemsToDisplay : Array<[string, Array<ProductQuickAdd>]>,
        };
    }

    export interface ISetIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay extends IAction {
        payload : {
            labelNamesAndSortedSalesItemIdsToDisplay : Array<[string, Array<SalesItemId>]>,
        };
    }

    export interface ISetSelectedIngredientId extends IAction {
        payload : {
            selectedIngredientId : ProductId | SalesItemId | ProductQuickAdd | null,
        };
    }

    export interface IResetAddNewIngredientFormSection extends IAction {
        payload : {};
    }

    export interface ISetDefaultPourByProductCategory extends IAction {
        payload : {
            defaultPourByProductCategory : DefaultPour | null,
        };
    }

    export interface ISetNextAndPreviousItemIds extends IAction {
        payload : {
            nextSalesItemId : SalesItemId | null,
            previousSalesItemId : SalesItemId | null
        };
    }

    export interface ISetIdToGoToAfterSave extends IAction {
        payload : {
            idToGoToAfterSave : SalesItemId | null
        };
    }

    export interface ISetSalesItemImageUploadUrl extends IAction {
        payload: {
            salesItemImageUploadUrl : string | null,
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        userAccountInfoReader : IUserAccountInfoReader;
        locationProductService : ILocationProductService;
        productService : IProductService;
        productCostService : IProductCostService;
        salesItemService : ISalesItemService;
        locationSettingsService : ILocationSettingsService;
        productQuickAddService : IProductQuickAddService;
        categoryService : ICategoryService;
    }

    export interface ICreateOrEditSalesItemExtraArguments extends IExtraArguments {
        services : IServices;
    }
}

const setSalesItemId = (
    salesItemId : SalesInputRowId | null
) : CreateOrEditSalesItemActionInterfaces.ISetSalesItemId => ({
    payload: {
        salesItemId,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_ID,
});

const setNeedsAttentionCategory = (
    needsAttentionCategory : string | null
) : CreateOrEditSalesItemActionInterfaces.ISetNeedsAttentionCategory => ({
    payload: {
        needsAttentionCategory,
    },
    type: CreateOrEditSalesItemActionTypes.SET_NEEDS_ATTENTION_CATEGORY,
});

const setSalesItemFormValidationByFieldName = (
    fieldName : SalesItemFormFieldName,
    value : string,
    errorMessage : string,
    isValid : boolean
) : CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormValidationByFieldName => ({
    payload: {
        fieldName,
        value,
        errorMessage,
        isValid,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM_VALIDATION_BY_FIELD_NAME,
});

const setIngredientFormValidationByFieldName = (
    fieldName : IngredientFormFieldName,
    value : string,
    errorMessage : string,
    isValid : boolean
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientFormValidationByFieldName => ({
    payload: {
        fieldName,
        value,
        errorMessage,
        isValid,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_FORM_VALIDATION_BY_FIELD_NAME,
});

const setSalesInformationFormValidationByFieldName = (
    fieldName : SalesInformationFormFieldName,
    value : string,
    errorMessage : string,
    isValid : boolean
) : CreateOrEditSalesItemActionInterfaces.ISetSalesInformationFormValidationByFieldName => ({
    payload: {
        fieldName,
        value,
        errorMessage,
        isValid,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_INFORMATION_FORM_VALIDATION_BY_FIELD_NAME,
});

const setSalesItemFormData = (
    salesItemFormData : ISalesItemFormData
) : CreateOrEditSalesItemActionInterfaces.ISetSalesItemFormData => ({
    payload: {
        salesItemFormData,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM_DATA
});

const setLinkedItems = (
    linkedItems : StringValueSet<SalesItemId>
) : CreateOrEditSalesItemActionInterfaces.ISetLinkedItems => ({
    payload: {
        linkedItems,
    },
    type: CreateOrEditSalesItemActionTypes.SET_LINKED_ITEMS,
});

const setComponentIsShown = (
    componentName : ComponentName,
    isShown : boolean,
) : CreateOrEditSalesItemActionInterfaces.ISetComponentIsShown => ({
    payload: {
        isShown,
        componentName,
    },
    type: CreateOrEditSalesItemActionTypes.SET_COMPONENT_IS_SHOWN
});

const setPopoverIngredientIdIsShown = (
    popoverIngredientIdIsShown : StringValueSet<ProductId | SalesItemId>,
    isShown : boolean
) => ({
    payload: {
        popoverIngredientIdIsShown,
        isShown,
    },
    type: CreateOrEditSalesItemActionTypes.SET_POPOVER_INGREDIENT_ID_IS_SHOWN,
});

const updateMenuGroup = (value : string) => ({
    payload: { value },
    type: CreateOrEditSalesItemActionTypes.UPDATE_MENU_GROUP,
});

const setSortedMenuGroupOptions = (sortedOptions : Array<IOption>) => ({
    payload: { sortedOptions },
    type: CreateOrEditSalesItemActionTypes.SET_SORTED_MENU_GROUP_OPTIONS,
});

const setSalesItemForm = (salesItemForm : ISalesItemForm) : CreateOrEditSalesItemActionInterfaces.ISetSalesItemForm => ({
    payload: {
        salesItemForm,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_FORM,
});

const setProductIngredientItemsRow = (
    ingredientId : ProductId,
    formInfo : ProductIngredientRowInfoByFormFieldName | null,
) : CreateOrEditSalesItemActionInterfaces.ISetProductIngredientItemsRow => {
    return ({
        payload: {
            ingredientId,
            formInfo,
        },
        type: CreateOrEditSalesItemActionTypes.SET_PRODUCT_INGREDIENT_ITEMS_ROW,
    });
};

const setSalesItemIngredientItemsRow = (
    ingredientId : SalesItemId,
    formInfo : SalesItemIngredientRowInfoByFormFieldName | null,
) : CreateOrEditSalesItemActionInterfaces.ISetSalesItemIngredientItemsRow => ({
    payload: {
        ingredientId,
        formInfo,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_INGREDIENT_ITEMS_ROW,
});

const resetSalesItemForm = () : CreateOrEditSalesItemActionInterfaces.IResetSalesItemForm => ({
    payload: {},
    type: CreateOrEditSalesItemActionTypes.RESET_SALES_ITEM_FORM,
});

const setSelectedSaveOption = (selectedSaveOption : SalesItemSaveOption) : CreateOrEditSalesItemActionInterfaces.ISetSelectedSaveOption => ({
    payload: {
        selectedSaveOption,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SELECTED_SAVE_OPTION,
});

const setIngredientSearchBarHighlightedIngredientId = (
    ingredientId : ProductId | SalesItemId | ProductQuickAdd | null,
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarHighlightedIngredientId => ({
    payload: {
        ingredientId,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_HIGHLIGHTED_ID,
});

const setIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay = (
    labelNamesAndSortedProductIdsToDisplay : Array<[string, Array<ProductId>]>,
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay => ({
    payload: {
        labelNamesAndSortedProductIdsToDisplay,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_IDS_TO_DISPLAY
});

const setIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay = (
    labelNamesAndSortedSalesItemIdsToDisplay : Array<[string, Array<SalesItemId>]>,
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay => ({
    payload: {
        labelNamesAndSortedSalesItemIdsToDisplay,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_SALES_ITEM_IDS_TO_DISPLAY
});

const setIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay = (
    labelNamesAndSortedProductQuickAddItemsToDisplay : Array<[string, Array<ProductQuickAdd>]>,
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay => ({
    payload: {
        labelNamesAndSortedProductQuickAddItemsToDisplay,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_LABEL_NAME_AND_SORTED_PRODUCT_QUICK_ADD_ITEMS_TO_DISPLAY
});

const setIngredientSearchBarState = (
    searchBar : ISearchBarState,
) : CreateOrEditSalesItemActionInterfaces.ISetIngredientSearchBarState => ({
    payload: {
        searchBar,
    },
    type: CreateOrEditSalesItemActionTypes.SET_INGREDIENT_SEARCH_BAR_STATE,
});

const setSelectedIngredientId = (
    selectedIngredientId : ProductId | SalesItemId | ProductQuickAdd | null
) : CreateOrEditSalesItemActionInterfaces.ISetSelectedIngredientId => ({
    payload: {
        selectedIngredientId,
    },
    type: CreateOrEditSalesItemActionTypes.SET_SELECTED_INDREDIENT_ID,
});

const resetAddNewIngredientFormSection = () => ({
    payload: {},
    type: CreateOrEditSalesItemActionTypes.RESET_ADD_NEW_INGREDIENT_FORM_SECTION
});

const setDefaultPourByProductCategory = (
    defaultPourByProductCategory : DefaultPour | null,
) => ({
    payload: {
        defaultPourByProductCategory
    },
    type: CreateOrEditSalesItemActionTypes.SET_DEFAULT_POUR_BY_PRODUCT_CATEGORY,
});

const setNextAndPreviousItemIds = (
    nextSalesItemId : SalesInputRowId | null,
    previousSalesItemId : SalesInputRowId | null
) => ({
    payload: {
        nextSalesItemId,
        previousSalesItemId
    },
    type: CreateOrEditSalesItemActionTypes.SET_NEXT_AND_PREVIOUS_IDS,
});

const setIdToGoToAfterSave = (
    idToGoToAfterSave : SalesInputRowId | null,
) => ({
    payload: {
        idToGoToAfterSave,
    },
    type: CreateOrEditSalesItemActionTypes.SET_ID_TO_GO_TO_AFTER_SAVE,
});

const setSalesItemImageUploadUrl = (
    salesItemImageUploadUrl : string | null,
) => ({
    payload : { salesItemImageUploadUrl },
    type : CreateOrEditSalesItemActionTypes.SET_SALES_ITEM_IMAGE_UPLOAD_URL,
});

const onSalesItemFormFieldChange = (
    field : SalesItemFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);

        dispatch(setSalesItemFormValidationByFieldName(field, value, errorMessage, isValid));
        dispatch(setComponentIsShown('saveIsDisabled', !isValid));
    };
};

const onIngredientInfoFormFieldChange = (
    field : IngredientFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);

        dispatch(setIngredientFormValidationByFieldName(field, value, errorMessage, isValid));

        // the only fields that actually affect the final sales item
        if (field === 'yieldAmount' || field === 'yieldUnit' || field === 'servingSizeAmount' || field === 'servingSizeUnit') {
            dispatch(setComponentIsShown('saveIsDisabled', !isValid));
        }
    };
};

const onIngredientRowFormFieldBlur = (
    ingredientId : ProductId | SalesItemId,
    field : ProductIngredientRowFormFieldName | SalesItemIngredientRowFormFieldName | ProductQuickAddRowFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const salesItemForm = getState().createOrEditSalesItemState.salesItemForm;
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);

        if (ingredientId instanceof ProductId) {
            const oldIngredientInfo = salesItemForm.productIngredientItemsById.get(ingredientId);
            if (typeof oldIngredientInfo === 'undefined') {
                throw new RuntimeException('unexpected ingredient row not found: ' + ingredientId.getValue());
            }
            const newIngredientRowInfo = {
                ...oldIngredientInfo.formInfo,
                [field]: {
                    value,
                    isValid,
                    errorMessage,
                }
            };
            dispatch(setProductIngredientItemsRow(ingredientId, newIngredientRowInfo));
            dispatch(onGetAndUpdateTotalCost());
        } else {
            const oldIngredientInfo = salesItemForm.salesItemIngredientItemsById.get(ingredientId);
            if (typeof oldIngredientInfo === 'undefined') {
                throw new RuntimeException('unexpected ingredient row not found: ' + ingredientId.getValue());
            }
            const newIngredientRowInfo = {
                ...oldIngredientInfo.formInfo,
                [field]: {
                    value,
                    isValid,
                    errorMessage,
                }
            };
            dispatch(setSalesItemIngredientItemsRow(ingredientId, newIngredientRowInfo));
            dispatch(onGetAndUpdateTotalCost());
        }
        dispatch(setComponentIsShown('saveIsDisabled', !isValid));
    };
};

const onProductRowFormFieldChange = (
    productId : ProductId,
    field : ProductIngredientRowFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);

        const previousProductIngredientRowInfo = getState().createOrEditSalesItemState.salesItemForm.productIngredientItemsById.get(productId);

        if (typeof previousProductIngredientRowInfo === 'undefined') {
            throw new RuntimeException('unexpected ingredient row not found: ' + productId.getValue());
        }
        const newProductIngredientRowInfo = {
            ...previousProductIngredientRowInfo.formInfo,
            [field]: {
                value,
                isValid,
                errorMessage,
            }
        };

        dispatch(setProductIngredientItemsRow(productId, newProductIngredientRowInfo));
        dispatch(setComponentIsShown('saveIsDisabled', !isValid));
    };
};

const onSalesItemRowFormFieldChange = (
    salesItemId : SalesItemId,
    field : SalesItemIngredientRowFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);

        const previousProductIngredientRowInfo = getState().createOrEditSalesItemState.salesItemForm.salesItemIngredientItemsById.get(salesItemId);

        if (typeof previousProductIngredientRowInfo === 'undefined') {
            throw new RuntimeException('unexpected ingredient row not found: ' + salesItemId.getValue());
        }
        const newSalesItemIngredientRowInfo = {
            ...previousProductIngredientRowInfo.formInfo,
            [field]: {
                value,
                isValid,
                errorMessage,
            }
        };

        dispatch(setSalesItemIngredientItemsRow(salesItemId, newSalesItemIngredientRowInfo));
        dispatch(setComponentIsShown('saveIsDisabled', false));
    };
};

const onSalesInformationFormFieldChange = (
    field : SalesInformationFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);
        dispatch(setSalesInformationFormValidationByFieldName(field, value, errorMessage, isValid));
        dispatch(setComponentIsShown('saveIsDisabled', !isValid));
    };
};

const onSetIngredientSearchBarTerm = (
    searchTerm : string | null,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        dispatch(setIngredientSearchBarState({
            searchTerm,
            isDisabled : false,
            isFocused : false,
        }));
        dispatch(setIngredientSearchBarHighlightedIngredientId(null));
        if (searchTerm === null) {
            dispatch(setSelectedIngredientId(null));
        }
        dispatch(handleSortingFilteringAndGrouping());
    };
};

// needs more logic, when one field is changed all fields have to update
export type LinkedPriceField = 'salesPrice' | 'costPercentage' | 'salesProfit' | 'priceAndTax'; // must be a subset of SalesInformationFormFieldChange
const LINKED_PRICE_FIELDS : Set<SalesInformationFormFieldName> = new Set(['salesPrice', 'costPercentage', 'salesProfit', 'priceAndTax']);
const onSalesInformationFormFieldBlur = (
    field : SalesInformationFormFieldName,
    value : string,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(field, value);
        dispatch(setSalesInformationFormValidationByFieldName(field, value, errorMessage, isValid));

        // TODO if invalid, what do we want to do? (can't update the others...). Current behavior: just don't update anything else
        if (isValid) {
            const state = getState().createOrEditSalesItemState;
            const costValue = state.salesItemForm.salesInformationForm.totalCost.value;

            if (field === 'miscCost') {
                dispatch(onGetAndUpdateTotalCost());
            } else if (LINKED_PRICE_FIELDS.has(field)) {
                // salesPrice, costPercentage, salesProfit, and priceAndTax all affect each other - if one field is edited, we must update all others.
                let itemCost : number | null;
                if (costValue === NOT_CALCULABLE_FIELD_VALUE) {
                    if (field === 'costPercentage' || field === 'salesProfit') {
                        throw new RuntimeException('field should not be editable if cost is not calculable');
                    }
                    itemCost = null;
                } else {
                    itemCost = parseFloat(costValue);
                }

                const data = state.salesItemFormData;
                if (data === null) {
                    throw new RuntimeException('unexpected');
                }
                const taxPercent = data.retailerTaxPercentage;
                const valueAsNumber = parseFloat(value);

                const formStringValues = CreateOrEditSalesItemFormUtils.getDependentSalesInformationFormValues(field as LinkedPriceField, valueAsNumber, itemCost, taxPercent);

                Object.keys(formStringValues).forEach((key) => {
                    const keyAsCorrectType = key as LinkedPriceField;
                    if (key !== field) { // don't need to update the one we already dispatched.
                        const newValue = formStringValues[keyAsCorrectType];
                        dispatch(setSalesInformationFormValidationByFieldName(keyAsCorrectType, newValue, '', true)); // if this util is correct, validation always correct.
                    }
                });
            }
        }
    };
};

const getCostFromIngredientRows = (
) : ThunkAction<number | null, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : number | null => {
        const state = getState().createOrEditSalesItemState;
        const salesItemForm = state.salesItemForm;
        const data = state.salesItemFormData;
        if (data === null) {
            throw new RuntimeException('unexpected');
        }
        const salesItemCostsById = getCachedSalesItemCostsForIds(
            new StringValueSet(Array.from(salesItemForm.salesItemIngredientItemsById.keys())),
            data.salesItemsById,
            data.productsById,
            data.productCostsByProductId
        );

        let totalProductIngredientCost : number | null = 0;
        let totalSalesItemIngredientCost : number | null = 0;

        salesItemForm.productIngredientItemsById.forEach((productIngredientRow, productId) => {
            const formInfoForRow : ProductIngredientRowInfoByFormFieldName = productIngredientRow.formInfo;
            if (!formInfoForRow.quantity.isValid || !formInfoForRow.unit.isValid || formInfoForRow.unit.value === '' || formInfoForRow.quantity.value === '') {
                totalProductIngredientCost = null;
            } else {
                const productQuantity = new QuantityInUnit(parseFloat(formInfoForRow.quantity.value), productJSONToObjectSerializer.getProductQuantityUnit(formInfoForRow.unit.value));

                const productCostPerQuantity = SalesItemUtils.getCostOfProductComponentInSalesItem(productId, productQuantity, data.productsById, data.productCostsByProductId);

                if (totalProductIngredientCost !== null) {
                    totalProductIngredientCost += productCostPerQuantity;
                }
            }
        });

        salesItemForm.salesItemIngredientItemsById.forEach((salesItemIngredientRow, salesItemId) => {
            const formInfoForRow : SalesItemIngredientRowInfoByFormFieldName = salesItemIngredientRow.formInfo;
            if (!formInfoForRow.quantity.isValid || formInfoForRow.quantity.value === '') {
                totalSalesItemIngredientCost = null;
            } else {
                const salesItem = data.salesItemsById.get(salesItemId);
                if (typeof salesItem === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                const salesItemCost = salesItemCostsById.get(salesItemId);
                if (typeof salesItemCost === 'undefined') {
                    throw new RuntimeException ('unexpected');
                }
                const numberOfServings = parseFloat(salesItemIngredientRow.formInfo.quantity.value);
                const salesItemCostPerQuantity = SalesItemUtils.getCostOfComponentSalesItem(salesItem.getSalesItem(), numberOfServings, salesItemCost);

                if (salesItemCostPerQuantity === null) {
                    totalSalesItemIngredientCost = null;
                } else if (totalSalesItemIngredientCost !== null) {
                    totalSalesItemIngredientCost += salesItemCostPerQuantity;
                }
            }
        });

        if (totalSalesItemIngredientCost === null || totalProductIngredientCost === null) {
            return null;
        } else {
            return totalSalesItemIngredientCost + totalProductIngredientCost;
        }
    };
};

const onUpdateTotalCost = (
    newTotalCost : number | null,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        // update cost on form
        dispatch(setSalesInformationFormValidationByFieldName('totalCost', CreateOrEditSalesItemFormUtils.getNumberFieldStringFromValue(newTotalCost), '', true));

        // need to update all linked items in the sales information form section. can do this by pretending to update sales price and then letting that action update all linked fields
        const salesItemPriceForm = getState().createOrEditSalesItemState.salesItemForm.salesInformationForm.salesPrice;
        if (salesItemPriceForm.value !== '') {
            dispatch(onSalesInformationFormFieldBlur('salesPrice', salesItemPriceForm.value));
        }
    };
};

const onGetAndUpdateTotalCost = () : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const salesItemForm = getState().createOrEditSalesItemState.salesItemForm;

        let newTotalCost : number | null;
        if (!salesItemForm.salesInformationForm.miscCost.isValid) {
            newTotalCost = null;
        } else {
            const miscCostValue = salesItemForm.salesInformationForm.miscCost.value;
            const costFromIngredients = dispatch(getCostFromIngredientRows());
            newTotalCost = costFromIngredients !== null ? costFromIngredients + parseFloat(miscCostValue) : null;
        }
        dispatch(onUpdateTotalCost(newTotalCost));
    };
};

const createMenuGroupOption = (optionText : string) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore) => {
        const menuGroupOptions = getState().createOrEditSalesItemState.salesItemForm.menuGroupOptions;
        const cleanedOptionValue = optionText.trim();

        if (menuGroupOptions.map((option) => option.label).indexOf(cleanedOptionValue) < 0) {
            const newMenuGroupOption = {
                value : optionText,
                label : optionText,
                icon : null,
            };
            const newMenuGroupOptions = [newMenuGroupOption, ...menuGroupOptions];
            dispatch(batchActions([
               updateMenuGroup(optionText),
               setSortedMenuGroupOptions(newMenuGroupOptions),
               setComponentIsShown('saveIsDisabled', false)
           ]));
        }
    };
};

const onSelectIngredient = (
    ingredientId : ProductId | SalesItemId | ProductQuickAdd | null,
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        dispatch(setComponentIsShown('ingredientSearchBarDropdown', false));
        if (!(ingredientId instanceof ProductQuickAdd)) {
            dispatch(setSelectedIngredientId(ingredientId));
        }
        let unitForForm : string = '';
        let quantityForFrom : string = '1.00';

        const formData = getState().createOrEditSalesItemState.salesItemFormData;
        if (ingredientId instanceof ProductId && formData !== null) {
            const product = formData.productsById.get(ingredientId);
            if (typeof product !== 'undefined') {
                // WHEN ADDING INGREDIENT: IF DEFAULT POUR, SET THAT. ELSE, BLANK
                if (formData.defaultPourByProductCategory !== null) { // if default pour is set, check product category
                    const category = product.getProductCategoryId();
                    if (typeof formData.defaultPourByProductCategory[category] !== 'undefined') {
                        const defaultPourForCategory = formData.defaultPourByProductCategory[category];

                        const productPackagingsAndMappings = product.getPackagingsAndMappings();
                        const productPackaging = productPackagingsAndMappings.getPackaging();

                        if (defaultPourForCategory.unit === 'unit') {
                            unitForForm = PackagingUtils.getContainerPackagingId(productPackaging).getValue();
                        } else if (defaultPourForCategory.unit === 'EA') {
                            const baseUnit = PackagingUtils.getBaseUnitOfPackaging(productPackaging);
                            if (baseUnit instanceof PackagingId) { // if base is an each, use each.
                                unitForForm = baseUnit.getValue();
                            }
                        } else if (PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(productPackagingsAndMappings, defaultPourForCategory.unit)) {
                            unitForForm = defaultPourForCategory.unit; // Mass or Volume - but must be compatible with product to use here.
                        }

                        quantityForFrom = defaultPourForCategory.quantity.toString();
                    }
                }

                if (unitForForm === '') {
                    const baseUnit = PackagingUtils.getBaseUnitOfPackaging(product.getPackagingsAndMappings().getPackaging());
                    if (!(baseUnit instanceof PackagingId)) {
                        if (VolumeUnit.isVolumeUnit(baseUnit)) {
                            unitForForm = VolumeUnit.OUNCE;
                        } else if (MassUnit.isMassUnit(baseUnit)) {
                            unitForForm = MassUnit.DRY_OUNCE;
                        } else {
                            throw new Error('unexpected value for base unit');
                        }
                    } else {
                        unitForForm = baseUnit.getValue();
                    }
                }
            }
        } else if (ingredientId instanceof ProductQuickAdd && formData !== null) {
            const userSessionId = extraArguments.services.userSessionReader.getSessionId();
            const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
            const categoriesById = formData.categoriesById;
            let newProduct = ProductQuickAddUtils.createProductFromProductQuickAddAndOptionIndex(ingredientId, 0, categoriesById);
            const category = newProduct.getNewProductCategoryId();
            let newCategoryPromise: Promise<CategoryId | null> = Promise.resolve(newProduct.getNewProductCategoryId());

            if (!category || !categoriesById.has(category)) {
                const categoryName = normalizeToTitleCase(newProduct.getProductCategoryId());
                newCategoryPromise = extraArguments.services.categoryService.createCategory(
                    userSessionId,
                    locationId,
                    new Category(
                        categoryName,
                        '',
                        false,
                        ''
                    )
                ).then((categoryResult) => {
                    const newCategoriesById = new StringValueMap(categoriesById);
                    newCategoriesById.set(
                        categoryResult.category_id,
                        new Category(
                            categoryName,
                            '',
                            false,
                            categoryResult.category_hash
                        )
                    );
                    dispatch(setSalesItemFormData({
                        ...formData,
                        categoriesById,
                    }));
                    return Promise.resolve(categoryResult.category_id);
                });
            }

            Promise.resolve(newCategoryPromise).then((result) => {
                newProduct = productUtils.getProductWithNewCategory(newProduct, result, newProduct.getProductCategoryId());
                extraArguments.services.productService.createProduct(userSessionId, locationId, newProduct)
                .then((productId) => {
                    dispatch(onProductCreatedOrEdited(productId, true))
                    .then(() => {
                        dispatch(onSelectIngredient(productId));
                        return;
                    });
                });
            });
        }

        dispatch(setIngredientFormValidationByFieldName('ingredientQuantityAmount', quantityForFrom, '', true));
        dispatch(setIngredientFormValidationByFieldName('ingredientQuantityUnit', unitForForm, '', true));
    };
};

const onAddIngredient = (
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const state = getState().createOrEditSalesItemState;
        const salesItemForm = state.salesItemForm;
        const ingredientId = salesItemForm.ingredientSearchBar.selectedIngredientId;

        if (ingredientId !== null) {
            let formInfoForRow : ProductIngredientRowInfoByFormFieldName | SalesItemIngredientRowInfoByFormFieldName;
            if (ingredientId instanceof ProductId) {
                const oldForm = salesItemForm.productIngredientItemsById.get(ingredientId);

                if (oldForm) {
                    if (state.salesItemFormData === null) {
                        throw new RuntimeException('unexpected');
                    }
                    const product = state.salesItemFormData.productsById.getRequired(ingredientId);

                    const oldUnit = productJSONToObjectSerializer.getProductQuantityUnit(oldForm.formInfo.unit.value);
                    const newUnit = productJSONToObjectSerializer.getProductQuantityUnit(salesItemForm.ingredientForm.ingredientQuantityUnit.value);
                    const newQuantityInUnit = new QuantityInUnit(
                        parseFloat(salesItemForm.ingredientForm.ingredientQuantityAmount.value),
                        newUnit
                    );
                    const quantityInOldUnit = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), newQuantityInUnit, oldUnit, ingredientId);

                    const newQuantity = quantityInOldUnit.getQuantity() + (parseFloat(oldForm.formInfo.quantity.value) || 0);
                    formInfoForRow = {
                        quantity: {
                            value: newQuantity.toString(),
                            isValid: true,
                            errorMessage: ''
                        },
                        unit: oldForm.formInfo.unit,
                    };

                } else {
                    formInfoForRow = {
                        quantity: salesItemForm.ingredientForm.ingredientQuantityAmount,
                        unit: salesItemForm.ingredientForm.ingredientQuantityUnit,
                    };
                }

                dispatch(setProductIngredientItemsRow(ingredientId, formInfoForRow));
            } else if (ingredientId instanceof ProductQuickAdd) {
                // This code should not be hit as we turn a ProductQuickAdd into
                // a Product before the user can add it. This is a safeguard.
                return;
            } else {
                const oldForm = salesItemForm.salesItemIngredientItemsById.get(ingredientId);
                const newQuantity = parseFloat(salesItemForm.ingredientForm.ingredientQuantityAmount.value) + (oldForm ? (parseFloat(oldForm.formInfo.quantity.value) || 0) : 0);

                formInfoForRow = {
                    quantity: {
                        value: newQuantity.toString(),
                        isValid: true,
                        errorMessage: ''
                    }
                };
                dispatch(setSalesItemIngredientItemsRow(ingredientId, formInfoForRow));
            }
            dispatch(onGetAndUpdateTotalCost());
            dispatch(onSetIngredientSearchBarTerm(null));
            dispatch(resetAddNewIngredientFormSection());
            dispatch(setComponentIsShown('saveIsDisabled', false));
        }
    };
};

const onValidateForm = (salesItemForm : ISalesItemForm, salesItemFormData : ISalesItemFormData) : ThunkAction<boolean, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : boolean => {
        // validate form (ingrdient should be present for save to work) create error message incase there are no ingredients
        let formIsValid : boolean = true;

        // salesItemFormFields
        const salesItemFormInfo : SalesItemFormValidationByFieldName = { ...salesItemForm.salesItemInfoForm };
        Object.keys(salesItemFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as SalesItemFormFieldName;
            const value = salesItemFormInfo[fieldName].value;
            const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldName, value);

            formIsValid = formIsValid && isValid;
            dispatch(setSalesItemFormValidationByFieldName(fieldName, value, errorMessage, isValid));
        });

        // salesInformationFormFields
        const salesInformationFormInfo : SalesInformationFormValidationByFieldName = { ...salesItemForm.salesInformationForm };
        Object.keys(salesInformationFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as SalesInformationFormFieldName;

            const value = salesInformationFormInfo[fieldName].value;
            let valueToCheck : string = value;
            if (value === '' && (fieldKey === 'salesPrice' || fieldKey === 'priceAndTax' || fieldKey === 'salesProfit' || fieldKey === 'costPercentage')) {
                valueToCheck = '0.00';
            }

            const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldName, valueToCheck);

            formIsValid = formIsValid && isValid;
            dispatch(setSalesInformationFormValidationByFieldName(fieldName, value, errorMessage, isValid));
        });

        // productIngredientItems
        salesItemForm.productIngredientItemsById.forEach((productIngredientRow, productId) => {
            const product = salesItemFormData.productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const formInfoForRow : ProductIngredientRowInfoByFormFieldName = { ...productIngredientRow.formInfo };
            Object.keys(formInfoForRow).forEach((fieldKey) => {
                const fieldName = fieldKey as ProductIngredientRowFormFieldName;
                const value = formInfoForRow[fieldName].value;
                let { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldName, value);

                // additional checks for unit -- ensure valid with product packaging
                if (fieldName === 'unit') {
                    const productQuantityUnit = productJSONToObjectSerializer.getProductQuantityUnit(value);
                    const isValidForProductPackaging = PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(product.getPackagingsAndMappings(), productQuantityUnit);
                    isValid = isValid && isValidForProductPackaging;
                    if (!isValid) {
                        errorMessage = 'Invalid unit';
                    }
                }

                formIsValid = formIsValid && isValid;
                formInfoForRow[fieldName] = { value, isValid, errorMessage };
                dispatch(setProductIngredientItemsRow(productId, formInfoForRow));
            });
        });

        // salesItemIngredientItems
        salesItemForm.salesItemIngredientItemsById.forEach((salesItemIngredientRow, salesItemId) => {
            const formInfoForRow : SalesItemIngredientRowInfoByFormFieldName = { ...salesItemIngredientRow.formInfo };
            Object.keys(formInfoForRow).forEach((fieldKey) => {
                const fieldName = fieldKey as SalesItemIngredientRowFormFieldName;
                const value = formInfoForRow[fieldName].value;
                const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldName, value);

                formIsValid = formIsValid && isValid;
                formInfoForRow[fieldName] = { value, isValid, errorMessage };
                dispatch(setSalesItemIngredientItemsRow(salesItemId, formInfoForRow));
            });
        });

        // ingredient form fields -- ignore the unadded ingredients, but must validate yield/serving size
        const ingredientFormInfo : IngredientFormValidationByFieldName = { ...salesItemForm.ingredientForm };
        let yieldAndServingSizeFieldsAreAllValid : boolean = true;
        Object.keys(ingredientFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as IngredientFormFieldName;
            if (fieldName === 'yieldAmount' || fieldName === 'yieldUnit' || fieldName === 'servingSizeAmount' || fieldName ===  'servingSizeUnit') {
                const value = ingredientFormInfo[fieldName].value;
                const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldName, value);

                formIsValid = formIsValid && isValid;
                yieldAndServingSizeFieldsAreAllValid = yieldAndServingSizeFieldsAreAllValid && isValid;
                dispatch(setIngredientFormValidationByFieldName(fieldName, value, errorMessage, isValid));
            }
        });
        // ingredient section - additional check that yield/serving size units are compatible
        if (yieldAndServingSizeFieldsAreAllValid) {
            const yieldAndServingSizeAreCompatible = CreateOrEditSalesItemFormUtils.validateServingSizeAndYieldAreCompatible(
                ingredientFormInfo.yieldAmount.value,
                ingredientFormInfo.yieldUnit.value,
                ingredientFormInfo.servingSizeAmount.value,
                ingredientFormInfo.servingSizeUnit.value);
            if (!yieldAndServingSizeAreCompatible) {
                dispatch(setIngredientFormValidationByFieldName('yieldUnit', ingredientFormInfo.yieldUnit.value, 'yield and serving size must have compatible units', false));
                dispatch(setIngredientFormValidationByFieldName('servingSizeUnit', ingredientFormInfo.servingSizeUnit.value, 'yield and serving size must have compatible units', false));
            }
            formIsValid = formIsValid && yieldAndServingSizeAreCompatible;
        }

        return formIsValid;
    };
};

// this function returns null if invalid, and a map of id that was on context page -> new sales item id to replace with if save is successful.
// in the case of "save for all reports" or "create", the map will be the same id -> same id, as those actions do not create more new items under the hood.
// if create from a POS item the map will be pos item id -> new sales item id
// in the case of "update for this and future reports" or "update for this report only", we need to return the old->new map that the api call gives us.
const onSave = (
    selectedSaveOption : SalesItemSaveOption,
    contextReportId : ItemLevelSalesReportConfigurationId | null,
    posInformationByPosItemId : StringValueMap<PosItemId, IPmixItemInformation>
) : ThunkAction<Promise<StringValueMap<SalesInputRowId, SalesItemId> | null>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (
        dispatch : Dispatch<ICreateOrEditSalesItemStore>,
        getState : () => ICreateOrEditSalesItemStore,
        extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments
    ) : Promise<StringValueMap<SalesInputRowId, SalesItemId> | null> => {
        const state = getState().createOrEditSalesItemState;
        const salesItemId = state.salesItemId;
        const salesItemForm = state.salesItemForm;
        const salesItemFormData = state.salesItemFormData;

        if (salesItemFormData === null) {
            throw new RuntimeException('unexpected');
        }

        dispatch(setComponentIsShown('saveInProgress', true));
        dispatch(setComponentIsShown('saveIsDisabled', false));

        const formIsValid = dispatch(onValidateForm(salesItemForm, salesItemFormData));

        if (!formIsValid) {
            dispatch(setComponentIsShown('saveInProgress', false));
            dispatch(setComponentIsShown('saveIsDisabled', true));
            return Promise.resolve(null);
        }

        const sessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        const salesItemInfo = CreateOrEditSalesItemFormUtils.getSalesItemInfo(salesItemForm, salesItemFormData.salesItemsById, locationId);

        if (salesItemId === null || salesItemId instanceof PosItemId) { // create mode
            return extraArguments.services.salesItemService.createSalesItem(
                sessionId,
                salesItemInfo,
            ).then((response : SalesItemId) => {
                const creationTimestamp = momentObjectToThriftSerializer.getThriftTimestampFromMoment(moment()); // not exactly correct but doesn't matter for these UI purposes
                const updatedSalesItemsById = new StringValueMap(salesItemFormData.salesItemsById);

                const newSalesItemWithMetadata = new SalesItemWithMetadata(
                    salesItemInfo,
                    new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), creationTimestamp),
                    null,
                    null,
                    false,
                );
                updatedSalesItemsById.set(response, newSalesItemWithMetadata);
                dispatch(setSalesItemFormData({
                    ...salesItemFormData,
                    salesItemsById: updatedSalesItemsById,
                }));
                dispatch(handleSortingFilteringAndGrouping());

                const oldToNewMap = new StringValueMap<SalesInputRowId, SalesItemId>();
                const old : SalesInputRowId = salesItemId instanceof PosItemId ? salesItemId : response;

                oldToNewMap.set(old, response);

                dispatch(setComponentIsShown('saveInProgress', false));
                return Promise.resolve(oldToNewMap);
            });
        } else { // edit mode
            let savePromise : Promise<StringValueMap<SalesItemId, SalesItemId>>;

            if (selectedSaveOption === SalesItemSaveOption.ALL_REPORTS) {
                savePromise = extraArguments.services.salesItemService.updateSalesItemForAllTime(
                    sessionId,
                    salesItemId,
                    salesItemInfo,
                ).then(() => {
                    const oldToNewMap = new StringValueMap<SalesItemId, SalesItemId>();
                    oldToNewMap.set(salesItemId, salesItemId);
                    return Promise.resolve(oldToNewMap);
                });
            } else if (selectedSaveOption === SalesItemSaveOption.SINGLE_REPORT) {
                if (contextReportId === null) {
                    throw new RuntimeException('context report id must be given if update for single report option is chosen');
                }
                savePromise = extraArguments.services.salesItemService.updateSalesItemForSingleReport(
                    sessionId,
                    salesItemId,
                    salesItemInfo,
                    contextReportId
                );
            } else if (selectedSaveOption === SalesItemSaveOption.THIS_AND_FUTURE_REPORTS) {
                savePromise = extraArguments.services.salesItemService.updateSalesItemForCurrentAndFutureReports(
                    sessionId,
                    salesItemId,
                    salesItemInfo,
                    contextReportId
                );
            } else {
                throw new RuntimeException('unexpected save option');
            }

            return savePromise
            .then((oldToNewMap) => {
                const allIds = Array.from(oldToNewMap.keys()).concat(Array.from(oldToNewMap.values()));
                const salesItemIdsToRefetch = new StringValueSet<SalesItemId>(allIds);

                // future optimization: we don't need to always do this api call, we could fake the updates that the backend does.
                // but that would mean making sure we are always up to date with backend logic, so may not be ideal
                dispatch(fetchAndUpdateDataForSalesItemIds(salesItemIdsToRefetch));

                dispatch(setComponentIsShown('saveInProgress', false));
                dispatch(setComponentIsShown('saveIsDisabled', true));
                dispatch(onShowSnackBarNotification());
                return Promise.resolve(oldToNewMap);
            });
        }
    };
};

const onApplySaveChanges = (
    contextItemLevelReportId : ItemLevelSalesReportConfigurationId | null,
    contextOrderedIds : Array<SalesInputRowId>,
    shouldPrePopulateIngredientSearch : boolean,
    posInformationByPosItemId : StringValueMap<PosItemId, IPmixItemInformation>,
    onSaveCallback : (updatedSalesItemIdMap : StringValueMap<SalesInputRowId, SalesItemId>) => void,
) : ThunkAction<Promise<Array<SalesInputRowId>>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (
        dispatch : Dispatch<ICreateOrEditSalesItemStore>,
        getState : () => ICreateOrEditSalesItemStore,
        extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments
    ) : Promise<Array<SalesInputRowId>> => {
        const createOrEditSalesItemState = getState().createOrEditSalesItemState;

        let updatedContextOrderedIds : Array<SalesInputRowId> = contextOrderedIds;
        const oldSalesIdToNewSalesIdMap = await dispatch(CreateOrEditSalesItemActions.onSave(createOrEditSalesItemState.selectedSaveOption, contextItemLevelReportId, posInformationByPosItemId));
        if (oldSalesIdToNewSalesIdMap !== null) {
            onSaveCallback(oldSalesIdToNewSalesIdMap);

            updatedContextOrderedIds = [...contextOrderedIds];
            updatedContextOrderedIds.forEach((id, index) => {
                const newId = oldSalesIdToNewSalesIdMap.get(id);
                if (typeof newId !== 'undefined') {
                    updatedContextOrderedIds[index] = newId;
                }
            });

            if (createOrEditSalesItemState.idToGoToAfterSave) {
                const newIdToGoToAfterSave = oldSalesIdToNewSalesIdMap.get(createOrEditSalesItemState.idToGoToAfterSave);
                const idToGoToAfterSave = newIdToGoToAfterSave ? newIdToGoToAfterSave : createOrEditSalesItemState.idToGoToAfterSave;
                dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(idToGoToAfterSave, updatedContextOrderedIds, shouldPrePopulateIngredientSearch, posInformationByPosItemId));
            } else if (createOrEditSalesItemState.salesItemId !== null) { // make sure reference is updated if sales item updated
                const newIdForOldId = oldSalesIdToNewSalesIdMap.get(createOrEditSalesItemState.salesItemId);
                if (typeof newIdForOldId === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                if (newIdForOldId !== createOrEditSalesItemState.salesItemId) {
                    dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(newIdForOldId, updatedContextOrderedIds, shouldPrePopulateIngredientSearch, posInformationByPosItemId));
                }
            }
        }
        dispatch(setComponentIsShown('applyChangesModal', false));
        dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(null)); // reset this

        return Promise.resolve(updatedContextOrderedIds);
    };
};

const fetchInitialData = () : ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : Promise<void> => {
        const sessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        // TODO product question: should this be get active sales item ids? or all (active & archived)
        const getAllRelevantSalesItemsByIdPromise = extraArguments.services.salesItemService.getNonDeletedSalesItemIdsForLocation(sessionId, locationId)
        .then((allRelevantIds) => {
            return extraArguments.services.salesItemService.getRelevantSalesItemsForIds(sessionId, allRelevantIds);
        });

        const response = await Promise.all([
            getAllRelevantSalesItemsByIdPromise,
            extraArguments.services.locationProductService.getActiveProductIds(sessionId, locationId), // TODO product question: do we want to get archived products as well?
            extraArguments.services.locationSettingsService.getRetailerTaxPercent(locationId.getValue()),
            extraArguments.services.locationSettingsService.getDefaultPourForRetailer(locationId.getValue()),
            extraArguments.services.categoryService.getCategoriesForRetailer(sessionId, locationId),
        ]);

        const salesItemsById = response[0];
        const activeProductIds = response[1];
        const categoriesById = response[4];
        const allRelevantProductIds = new StringValueSet(activeProductIds); // relevant = active or used in one of the sales items
        const relevantUserAccountIds = new StringValueSet<UserAccountId>();
        const menuGroups = new Set<string>();

        salesItemsById.forEach((salesItemWithMetadata, salesItemId) => {
            salesItemWithMetadata.getSalesItem().getComponentQuantityOfProductByProductId().forEach((quantity, productId) => {
                allRelevantProductIds.add(productId);
            });

            const lastEditedMetadata = salesItemWithMetadata.getLastEditedMetadata();
            if (lastEditedMetadata !== null) {
                relevantUserAccountIds.add(lastEditedMetadata.getUserAccountId());
            }

            if (salesItemWithMetadata.getSalesItem().getMenuGroup().length > 0) {
                menuGroups.add(salesItemWithMetadata.getSalesItem().getMenuGroup());
            }
        });
        const retailerTaxPercentage = response[2]; // TODO product question: do we want to keep this null and not display tax rate, or pick a default number?
        const defaultPourByProductCategory = response[3];

        const userAccountIdsList = Array.from(relevantUserAccountIds.values());

        const productsAndUsersResponse = await Promise.all([
            extraArguments.services.productService.getProductsById(sessionId, locationId, allRelevantProductIds),
            extraArguments.services.productCostService.getCurrentProductCostsByProductId(sessionId, allRelevantProductIds, locationId),
            Promise.all(userAccountIdsList.map((u) => {
                return extraArguments.services.userAccountInfoReader.getNameForAccountId(u);
            })),
       ]);

        const namesByUserAccountId = new StringValueMap<UserAccountId, { firstName : string, lastName : string }>();
        const userNames = productsAndUsersResponse[2];
        userAccountIdsList.forEach((userId, index) => {
            namesByUserAccountId.set(userId, userNames[index]);
        });

        let quickAddProducts : Array<ProductQuickAdd> = [];

        if (activeProductIds.size < 50) {
            await Promise.resolve(extraArguments.services.productQuickAddService.getProductQuickAddData(sessionId))
            .then((res) => {
                quickAddProducts = res;
            });
        }

        const salesItemFormData : ISalesItemFormData = {
            activeProductIds,
            salesItemsById,
            productsById: productsAndUsersResponse[0].productsById,
            categoriesById,
            productCostsByProductId: productsAndUsersResponse[1],
            retailerTaxPercentage,
            currentUserAccountId: new UserAccountId(window.GLOBAL_USER_ID),
            namesByUserAccountId,
            defaultPourByProductCategory,
            quickAddProducts
        };

        const sortedMenuGroupOptions : Array<IOption> = menuGroups.size > 0 ? CreateOrEditSalesItemFormUtils.getSortedMenuGroupOptions(menuGroups) : getState().createOrEditSalesItemState.salesItemForm.menuGroupOptions;

        dispatch(batchActions([
            setSalesItemFormData(salesItemFormData),
            setSortedMenuGroupOptions(sortedMenuGroupOptions),
        ]));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

// in the future we could pass in a mode "duplicate" or "edit" and then not populate certain fields in duplicate mode
const initializeFormFromSalesItem = (
    initialItem : SalesItemId | PosItemId,
    contextOrderedIds : Array<SalesInputRowId>,
    shouldPrePopulateIngredientSearch : boolean,
    posInformationByPosItemId : StringValueMap<PosItemId, IPmixItemInformation>
) : ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : Promise<void> => {
        dispatch(resetSalesItemForm()); // make sure to clear out anything related to old sales item

        const state = getState().createOrEditSalesItemState;
        const data = state.salesItemFormData;

        if (data === null) {
            throw new RuntimeException('unexpected');
        }

        let salesItemDataPromise : Promise<ISalesItemFormData>;
        if (initialItem instanceof SalesItemId && !data.salesItemsById.has(initialItem)) { // if wasn't fetched on the initial load (maybe is deleted or something)
            salesItemDataPromise = dispatch(fetchAndUpdateDataForSalesItemIds(new StringValueSet([initialItem])));
        } else {
            salesItemDataPromise = Promise.resolve(data);
        }

        const updatedData = await salesItemDataPromise;

        const salesItemId = initialItem instanceof SalesItemId ? initialItem : null;

        const oldForm = state.salesItemForm;

        let newSalesItemForm : ISalesItemForm;
        let linkedItems : StringValueSet<SalesItemId>;
        let shouldPrepopulateSearchAndOpenDropdown : boolean;
        if (initialItem instanceof SalesItemId) {
            const costBySalesItemId = getCachedSalesItemCostsForIds(new StringValueSet([initialItem]), updatedData.salesItemsById, updatedData.productsById, updatedData.productCostsByProductId);

            const salesItemWithMetadata = updatedData.salesItemsById.getRequired(initialItem);
            const cost = costBySalesItemId.getRequired(initialItem);

            newSalesItemForm = CreateOrEditSalesItemFormUtils.getSalesItemFormFromSalesItemWithMetadata(
                salesItemWithMetadata,
                oldForm.menuGroupOptions,
                oldForm.ingredientSearchBar,
                updatedData.productsById,
                cost,
                updatedData.retailerTaxPercentage);

            linkedItems = CreateOrEditSalesItemFormUtils.getLinkedSalesItems(initialItem, updatedData.salesItemsById);

            shouldPrepopulateSearchAndOpenDropdown = shouldPrePopulateIngredientSearch && salesItemWithMetadata.getSalesItem().getComponentQuantityOfProductByProductId().size === 0 &&
                salesItemWithMetadata.getSalesItem().getComponentServingsBySalesItemId().size === 0;
        } else {
            const information = posInformationByPosItemId.getRequired(initialItem);
            // initialize form from POS item
            newSalesItemForm = CreateOrEditSalesItemFormUtils.getSalesItemFormFromPosItem(
                information.posItem,
                information.salesEntry.getPrice() || 0,
                oldForm.menuGroupOptions,
                oldForm.ingredientSearchBar,
                updatedData.retailerTaxPercentage);
            linkedItems = new StringValueSet();
            shouldPrepopulateSearchAndOpenDropdown = shouldPrePopulateIngredientSearch;
        }

        let nextSalesItemId : SalesInputRowId | null;
        let previousSalesItemId : SalesInputRowId | null;
        const indexOfItem = contextOrderedIds.findIndex((value) => value.equals(initialItem));
        const shouldShowMiscCostLink : boolean = newSalesItemForm.salesInformationForm.miscCost.value !== '0'; // Handles resetting "Add Misc Cost" link upon saving/switching to new item when mapping
        if (indexOfItem === -1 || contextOrderedIds.length === 1) {
            nextSalesItemId = null;
            previousSalesItemId = null;
        } else if (indexOfItem === 0) {
            nextSalesItemId = contextOrderedIds[1];
            previousSalesItemId = null;
        } else if (indexOfItem === (contextOrderedIds.length - 1)) {
            nextSalesItemId = null;
            previousSalesItemId = contextOrderedIds[indexOfItem - 1];
        } else {
            nextSalesItemId = contextOrderedIds[indexOfItem + 1];
            previousSalesItemId = contextOrderedIds[indexOfItem - 1];
        }

        dispatch(batchActions([
            setSalesItemForm(newSalesItemForm),
            setSalesItemId(initialItem),
            setLinkedItems(linkedItems),
            setNextAndPreviousItemIds(nextSalesItemId, previousSalesItemId),
            setComponentIsShown('miscellaneousCost', shouldShowMiscCostLink),
        ]));

        // if no ingredients and should prepopulate search, set the search term
        if (shouldPrepopulateSearchAndOpenDropdown) {
            const name = newSalesItemForm.salesItemInfoForm.salesItemName.value;
            dispatch(onSetIngredientSearchBarTerm(name));
            dispatch(setComponentIsShown('ingredientSearchBarDropdown', true));
        } else {
            dispatch(onSetIngredientSearchBarTerm(null)); // this dropdown hides linked items so refilter it
            dispatch(setComponentIsShown('ingredientSearchBarDropdown', false));
        }

        dispatch(loadSalesItemImage(salesItemId));  // async - ok
    };
};

const fetchAndUpdateDataForSalesItemIds = (salesItemIds : StringValueSet<SalesItemId>) : ThunkAction<Promise<ISalesItemFormData>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (
        dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore,
        extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments
    ) : Promise<ISalesItemFormData> => {
        const state = getState().createOrEditSalesItemState;
        const data = state.salesItemFormData;
        if (data === null) {
            throw new RuntimeException('unexpected');
        }

        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const relevantSalesItemsById = await extraArguments.services.salesItemService.getRelevantSalesItemsForIds(userSessionId, salesItemIds);

        const allRelevantProductIds = new StringValueSet<ProductId>();
        const relevantUserAccountIds = new StringValueSet<UserAccountId>();
        const menuGroups = new Set<string>();

        const currentMenuGroups = new Set(state.salesItemForm.menuGroupOptions.map((option) => option.value));

        const newSalesItemsById = new StringValueMap(data.salesItemsById);
        relevantSalesItemsById.forEach((salesItemWithMetadata, salesItemId) => {
            newSalesItemsById.set(salesItemId, salesItemWithMetadata);

            salesItemWithMetadata.getSalesItem().getComponentQuantityOfProductByProductId().forEach((quantity, productId) => {
                if (!data.productsById.has(productId)) {
                    allRelevantProductIds.add(productId);
                }
            });

            const lastEditedMetadata = salesItemWithMetadata.getLastEditedMetadata();
            if (lastEditedMetadata !== null && !data.namesByUserAccountId.has(lastEditedMetadata.getUserAccountId())) {
                relevantUserAccountIds.add(lastEditedMetadata.getUserAccountId());
            }

            const menuGroup = salesItemWithMetadata.getSalesItem().getMenuGroup();
            if (menuGroup.length > 0 && !currentMenuGroups.has(menuGroup)) {
                menuGroups.add(menuGroup);
            }
        });

        const userAccountIdsList = Array.from(relevantUserAccountIds.values());
        let userAccountPromise : Promise<Array<{firstName : string, lastName : string}> | null>;
        if (userAccountIdsList.length > 0) {
            userAccountPromise = Promise.all(userAccountIdsList.map((u) => {
                return extraArguments.services.userAccountInfoReader.getNameForAccountId(u);
            }));
        } else {
            userAccountPromise = Promise.resolve(null);
        }

        let productsByIdPromise : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string> } | null>;
        let productCostPromise : Promise<StringValueMap<ProductId, ProductCost> | null>;
        if (allRelevantProductIds.size > 0) {
            productsByIdPromise = extraArguments.services.productService.getProductsById(userSessionId, locationId, allRelevantProductIds);
            productCostPromise = extraArguments.services.productCostService.getCurrentProductCostsByProductId(userSessionId, allRelevantProductIds, locationId);
        } else {
            productsByIdPromise = Promise.resolve(null);
            productCostPromise = Promise.resolve(null);
        }

        const productsAndUsersResponse = await Promise.all([
            productsByIdPromise,
            productCostPromise,
            userAccountPromise,
            extraArguments.services.categoryService.getCategoriesForRetailer(userSessionId, locationId),
        ]);

        const newNamesByUserAccountId = new StringValueMap(data.namesByUserAccountId);
        const userNames = productsAndUsersResponse[2];
        const categoriesById = productsAndUsersResponse[3];
        if (userNames) {
            userAccountIdsList.forEach((userId, index) => {
                newNamesByUserAccountId.set(userId, userNames[index]);
            });
        }

        const newProductsById = new StringValueMap(data.productsById);
        if (productsAndUsersResponse[0]) {
            productsAndUsersResponse[0].productsById.forEach((product, productId) => {
                newProductsById.set(productId, product);
            });
        }
        const newProductCostsById = new StringValueMap(data.productCostsByProductId);
        if (productsAndUsersResponse[1]) {
            productsAndUsersResponse[1].forEach((cost, productId) => {
                newProductCostsById.set(productId, cost);
            });
        }

        const salesItemFormData : ISalesItemFormData = {
            activeProductIds: data.activeProductIds,
            salesItemsById: newSalesItemsById,
            productsById: newProductsById,
            productCostsByProductId: newProductCostsById,
            retailerTaxPercentage: data.retailerTaxPercentage,
            defaultPourByProductCategory: data.defaultPourByProductCategory,
            currentUserAccountId: data.currentUserAccountId,
            namesByUserAccountId: newNamesByUserAccountId,
            quickAddProducts: data.quickAddProducts,
            categoriesById,
        };

        let newSortedMenuGroupOptions : Array<IOption>;
        if (menuGroups.size > 0) {
            const allMenuGroups = new Set(currentMenuGroups);
            menuGroups.forEach((newGroup) => {
                allMenuGroups.add(newGroup);
            });
            newSortedMenuGroupOptions = CreateOrEditSalesItemFormUtils.getSortedMenuGroupOptions(allMenuGroups);
        } else {
            newSortedMenuGroupOptions = state.salesItemForm.menuGroupOptions;
        }

        dispatch(batchActions([
            setSalesItemFormData(salesItemFormData),
            setSortedMenuGroupOptions(newSortedMenuGroupOptions),
        ]));
        dispatch(handleSortingFilteringAndGrouping());

        return Promise.resolve(salesItemFormData);
    };
};

const onProductCreatedOrEdited = (productId : ProductId, isStatusActive : boolean) : ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : Promise<void> => {
        const state = getState().createOrEditSalesItemState;
        const data = state.salesItemFormData;
        if (data === null) {
            throw new RuntimeException('unexpected');
        }

        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const productIdSet = new StringValueSet([productId]);

        const productsResponse = await Promise.all([
            extraArguments.services.productService.getProductsById(userSessionId, locationId, productIdSet),
            extraArguments.services.productCostService.getCurrentProductCostsByProductId(userSessionId, productIdSet, locationId),
        ]);

        const newProductsById = new StringValueMap(data.productsById);
        const newActiveProductIds = new StringValueSet(data.activeProductIds);
        const newCategoriesById = new StringValueMap(data.categoriesById);
        const newProduct = productsResponse[0].productsById.getRequired(productId);
        const newCategory = newProduct.getNewProductCategoryId();
        const productCategorySet = new StringValueSet<CategoryId>();

        if (newCategory) {
            productCategorySet.add(newCategory);
        }

        const categoryResponse = await Promise.resolve(
            newCategory && !data.categoriesById.has(newCategory) ?
                extraArguments.services.categoryService.getCategoriesById(userSessionId, locationId, productCategorySet) :
                Promise.resolve(new StringValueMap<CategoryId, Category>())
        );
        categoryResponse.forEach((category, categoryId) => {
            newCategoriesById.set(categoryId, category);
        });
        newProductsById.set(productId, newProduct);
        if (isStatusActive) {
            newActiveProductIds.add(productId);
        } else {
            newActiveProductIds.delete(productId);
        }

        const newProductCostsById = new StringValueMap(data.productCostsByProductId);
        productsResponse[1].forEach((cost, prodId) => {
            newProductCostsById.set(prodId, cost);
        });

        const salesItemFormData : ISalesItemFormData = {
            ...data,
            activeProductIds: newActiveProductIds,
            productsById: newProductsById,
            productCostsByProductId: newProductCostsById,
            categoriesById: newCategoriesById,
        };

        dispatch(setSalesItemFormData(salesItemFormData));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

const onSlimCreateNewSalesItemIngredient = () : ThunkAction<Promise<SalesItemId | null>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (
        dispatch : Dispatch<ICreateOrEditSalesItemStore>,
        getState : () => ICreateOrEditSalesItemStore,
        extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments
    ) : Promise<SalesItemId | null> => {
        const state = getState().createOrEditSalesItemState;
        const salesItemFormData = state.salesItemFormData;
        if (salesItemFormData === null) {
            throw new RuntimeException('unexpected');
        }
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        // VALIDATION
        const ingredientForm = state.salesItemForm.ingredientForm;
        let createNewItemFormIsValid : boolean = true;
        Object.keys(ingredientForm).forEach((fieldKey) => {
            const fieldName = fieldKey as IngredientFormFieldName;
            if (slimCreateSalesItemFormFieldNames.has(fieldName)) {
                const value = ingredientForm[fieldName].value;

                let fieldNameToCheck : IngredientFormFieldName | 'salesItemName' = fieldName;
                if (fieldName === 'newSalesItemName') {
                    // validate with required
                    fieldNameToCheck = 'salesItemName';
                }
                const { isValid, errorMessage } = CreateOrEditSalesItemFormUtils.validateValueByFieldName(fieldNameToCheck, value);

                createNewItemFormIsValid = createNewItemFormIsValid && isValid;
                dispatch(setIngredientFormValidationByFieldName(fieldName, value, errorMessage, isValid));
            }
        });
        // ingredient section - additional check that yield/serving size units are compatible
        if (createNewItemFormIsValid) {
            const yieldAndServingSizeAreCompatible = CreateOrEditSalesItemFormUtils.validateServingSizeAndYieldAreCompatible(
                ingredientForm.newSalesItemYieldAmount.value,
                ingredientForm.newSalesItemServingSizeUnit.value,
                ingredientForm.newSalesItemServingSizeAmount.value,
                ingredientForm.newSalesItemServingSizeUnit.value);
            if (!yieldAndServingSizeAreCompatible) {
                dispatch(setIngredientFormValidationByFieldName('yieldUnit', ingredientForm.newSalesItemYieldAmount.value, 'yield and serving size must have compatible units', false));
                dispatch(setIngredientFormValidationByFieldName('servingSizeUnit', ingredientForm.newSalesItemServingSizeUnit.value, 'yield and serving size must have compatible units', false));
            }
            createNewItemFormIsValid = createNewItemFormIsValid && yieldAndServingSizeAreCompatible;
        }

        if (!createNewItemFormIsValid) {
            return Promise.resolve(null);
        }

        const salesItem = CreateOrEditSalesItemFormUtils.getSalesItemFromSubrecipeSlimCreateFields(state.salesItemForm.ingredientForm, locationId);

        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        return extraArguments.services.salesItemService.createSalesItem(
            userSessionId,
            salesItem,
        ).then((response : SalesItemId) => {
            const creationTimestamp = momentObjectToThriftSerializer.getThriftTimestampFromMoment(moment()); // not exactly correct but doesn't matter for these UI purposes
            const updatedSalesItemsById = new StringValueMap(salesItemFormData.salesItemsById);

            const newSalesItemWithMetadata = new SalesItemWithMetadata(
                salesItem,
                new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), creationTimestamp),
                null,
                null,
                false,
            );
            updatedSalesItemsById.set(response, newSalesItemWithMetadata);
            dispatch(batchActions([
                setSalesItemFormData({
                    ...salesItemFormData,
                    salesItemsById: updatedSalesItemsById,
                }),
                setSelectedIngredientId(response) // weird but necessary to be able to call the onAddIngredient action after this returns
            ]));
            dispatch(handleSortingFilteringAndGrouping());

            return Promise.resolve(response);
        });

    };
};

const throttledHandleSortingFilteringAndGrouping = _.throttle(
    (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const state = getState().createOrEditSalesItemState;
        const data = state.salesItemFormData;

        if (data === null) {
            throw new RuntimeException('unexpected');
        }

        const salesItemIdsToIncludeInDropdown : Array<SalesItemId> = [];
        data.salesItemsById.forEach((salesItemWithMetadata, salesItemId) => {
            // for dropdown: only include active/non-deleted items, and do not include the current item or any linked items (to avoid some infinite loops)
            if (!salesItemWithMetadata.getIsArchived() && salesItemWithMetadata.getDeletionMetadata() === null &&
                !salesItemId.equals(state.salesItemId) && !state.linkedItems.has(salesItemId)) {
                salesItemIdsToIncludeInDropdown.push(salesItemId);
            }
        });

        Promise.all([
            ProductSearchBarSortingUtil.getSortedFilteredAndGroupedResult(
                Array.from(data.activeProductIds.values()),
                {
                    sortedBy: name,
                    direction: SortDirection.ASCENDING,
                },
                state.salesItemForm.ingredientSearchBar.searchBar.searchTerm,
                GroupByOption.ALL_ITEMS,
                {
                    salesItemFormData: data
                }
            ),
            SalesItemSearchBarSortingUtil.getSortedFilteredAndGroupedResult(
                salesItemIdsToIncludeInDropdown,
                {
                    sortedBy: name,
                    direction: SortDirection.ASCENDING,
                },
                state.salesItemForm.ingredientSearchBar.searchBar.searchTerm,
                GroupByOption.ALL_ITEMS,
                {
                    salesItemFormData: data
                }
            ),
            ProductQuickAddSortingUtil.getSortedFilteredAndGroupedResult(
                data.quickAddProducts,
                {
                    sortedBy: name,
                    direction: SortDirection.ASCENDING,
                },
                state.salesItemForm.ingredientSearchBar.searchBar.searchTerm,
                GroupByOption.ALL_ITEMS,
                {
                    salesItemFormData: data
                }
            )
        ])
        .then((sortingResults) => {
            const productSortingResult = sortingResults[0];
            const salesItemSortingResult = sortingResults[1];
            const quickAddSortingResult = sortingResults[2];

            const sortedProductRowIdsToDisplayByGroupName = productSortingResult.sortedRowIdsToDisplayByGroupName;
            const sortedProductGroupNamesToDisplay = productSortingResult.sortedGroupNamesToDisplay;

            const labelNamesAndSortedProductIdsToDisplay : Array<[string, Array<ProductId>]> = [];
            sortedProductGroupNamesToDisplay.forEach((groupName) => {
                labelNamesAndSortedProductIdsToDisplay.push([groupName, sortedProductRowIdsToDisplayByGroupName[groupName]]);
            });

            const sortedSalesItemRowIdsToDisplayByGroupName = salesItemSortingResult.sortedRowIdsToDisplayByGroupName;
            const sortedSalesItemGroupNamesToDisplay = salesItemSortingResult.sortedGroupNamesToDisplay;

            const labelNamesAndSortedSalesItemIdsToDisplay : Array<[string, Array<SalesItemId>]> = [];
            sortedSalesItemGroupNamesToDisplay.forEach((groupName) => {
                labelNamesAndSortedSalesItemIdsToDisplay.push([groupName, sortedSalesItemRowIdsToDisplayByGroupName[groupName]]);
            });

            const sortedProductQuickAddRowIdsToDisplayByGroupName = quickAddSortingResult.sortedRowIdsToDisplayByGroupName;
            const sortedProductQuickAddGroupNamesToDisplay = quickAddSortingResult.sortedGroupNamesToDisplay;

            const labelNamesAndSortedProductQuickAddItemsToDisplay : Array<[string, Array<ProductQuickAdd>]> = [];
            sortedProductQuickAddGroupNamesToDisplay.forEach((groupName) => {
                labelNamesAndSortedProductQuickAddItemsToDisplay.push([groupName, sortedProductQuickAddRowIdsToDisplayByGroupName[groupName]]);
            });
            dispatch(setIngredientSearchBarLabelNamesAndSortedProductIdsToDisplay(labelNamesAndSortedProductIdsToDisplay));
            dispatch(setIngredientSearchBarLabelNamesAndSortedSalesItemIdsToDisplay(labelNamesAndSortedSalesItemIdsToDisplay));
            dispatch(setIngredientSearchBarLabelNamesAndSortedProductQuickAddItemsToDisplay(labelNamesAndSortedProductQuickAddItemsToDisplay));
        });
    }, 200, { leading : false }
);

const handleSortingFilteringAndGrouping = (
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return throttledHandleSortingFilteringAndGrouping;
};

let hideSnackBarTimeoutPid : number = -1;
const onShowSnackBarNotification = () : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        window.clearTimeout(hideSnackBarTimeoutPid);
        dispatch(setComponentIsShown('onSaveSnackBar', true));
        hideSnackBarTimeoutPid = window.setTimeout(() => {
            dispatch(setComponentIsShown('onSaveSnackBar', false));
        }, NOTIFICATION_TIMEOUT_IN_MILLISECONDS);
    };
};

const onEditItemClick = (
    productId : ProductId
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        $(document).trigger('loadEditItemModal', {
            detail: {
                productId: productId.getValue(),
            }
        });

        Observer.observeAction('sale_items_edit_item_click', {});
    };
};

const exportSalesItemToExcel = (
    salesItemId : SalesItemId
) : ThunkAction<void, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) : void => {
        const locationName = window.GLOBAL_RETAILER_NAME;
        const {
            salesItemFormData
        } = getState().createOrEditSalesItemState;

        if (salesItemFormData === null) {
            throw new RuntimeException('unexpected');
        }

        SalesItemManagerUtils.exportSalesItemToExcel(
            locationName,
            salesItemId,
            salesItemFormData.salesItemsById,
            salesItemFormData.productsById,
            salesItemFormData.productCostsByProductId,
        );
    };
};

// todo: this really should be a SalesItemManager action... but CreateOrEditSalesItem is connected. gonna have to add similar actions to SalesItemManager for bulk export.
const saveSalesItemImage = (
    salesItemId : SalesItemId,
    file : File
) : ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) => {
        dispatch(setComponentIsShown('recipePdfIsLoading', true));
        return extraArguments.services.salesItemService.uploadSalesItemImageAndGetUrl(
            extraArguments.services.userSessionReader.getSessionId(),
            new LocationId(window.GLOBAL_RETAILER_ID),
            salesItemId,
            file
        )
        .then((result : string) => {
            dispatch(setSalesItemImageUploadUrl(result));
            dispatch(setComponentIsShown('recipePdfIsLoading', false));
            Observer.observeAction(
                'recipe_card_image_added_successfully',
                {
                    retailer_id: window.GLOBAL_RETAILER_ID,
                    user_id: window.GLOBAL_USER_ID,
                    sales_item_id: salesItemId.getValue()
                });
        });
    };
};

const deleteSalesItemImage = (
    salesItemId : SalesItemId,
) : ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) => {
        dispatch(setComponentIsShown('recipePdfIsLoading', true));

        const {
            salesItemImageUploadUrl
        } = getState().createOrEditSalesItemState;

        if (!salesItemImageUploadUrl) {
            return;
        }

        dispatch(setSalesItemImageUploadUrl(null));

        return extraArguments.services.salesItemService.deleteSalesItemImageUpload(
            extraArguments.services.userSessionReader.getSessionId(),
            new LocationId(window.GLOBAL_RETAILER_ID),
            salesItemId
        )
        .then(() => {
            dispatch(setComponentIsShown('recipePdfIsLoading', false));
        });
    };
};

const loadSalesItemImage = (
    salesInputRowId : SalesInputRowId | null
) :  ThunkAction<Promise<void>, ICreateOrEditSalesItemStore, CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments> => {
    return async (dispatch : Dispatch<ICreateOrEditSalesItemStore>, getState : () => ICreateOrEditSalesItemStore, extraArguments : CreateOrEditSalesItemActionInterfaces.ICreateOrEditSalesItemExtraArguments) => {
        dispatch(setComponentIsShown('recipePdfIsLoading', true));
        if (salesInputRowId instanceof SalesItemId) {
            return extraArguments.services.salesItemService.getSalesItemImageUploadUrls(
                extraArguments.services.userSessionReader.getSessionId(),
                new LocationId(window.GLOBAL_RETAILER_ID),
                new StringValueSet<SalesItemId>([salesInputRowId]))
            .then((result : StringValueMap<SalesItemId, string>) => {
                dispatch(setSalesItemImageUploadUrl(result.get(salesInputRowId) || null));
                dispatch(setComponentIsShown('recipePdfIsLoading', false));
            });
        }
    };
};

export const CreateOrEditSalesItemActions = {
    setSalesItemId,
    setNeedsAttentionCategory,
    setSalesItemFormValidationByFieldName,
    setIngredientFormValidationByFieldName,
    setSalesInformationFormValidationByFieldName,
    setSalesItemForm,
    setProductIngredientItemsRow,
    setSalesItemIngredientItemsRow,
    setSalesItemFormData,
    setLinkedItems,
    setComponentIsShown,
    setPopoverIngredientIdIsShown,
    updateMenuGroup,
    setIngredientSearchBarHighlightedIngredientId,
    onSetIngredientSearchBarTerm,
    onSalesItemFormFieldChange,
    onIngredientInfoFormFieldChange,
    onSalesInformationFormFieldChange,
    onProductRowFormFieldChange,
    onIngredientRowFormFieldBlur,
    onGetAndUpdateTotalCost,
    onSalesItemRowFormFieldChange,
    createMenuGroupOption,
    initializeFormFromSalesItem,
    setSelectedSaveOption,
    resetSalesItemForm,
    resetAddNewIngredientFormSection,
    onSelectIngredient,
    onAddIngredient,
    onValidateForm,
    onSave,
    fetchInitialData,
    onSalesInformationFormFieldBlur,
    onProductCreatedOrEdited,
    onSlimCreateNewSalesItemIngredient,
    setDefaultPourByProductCategory,
    setNextAndPreviousItemIds,
    setIdToGoToAfterSave,
    onApplySaveChanges,
    onEditItemClick,
    exportSalesItemToExcel,
    saveSalesItemImage,
    deleteSalesItemImage,
    loadSalesItemImage,
};
