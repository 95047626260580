import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { isOmnivoreSupportedPos, OmnivoreSupportedPosType } from 'api/Integration/model/IntegrationType';
import { OmnivoreIntegrationState } from 'api/Integration/model/OmnivoreIntegrationState';
import { IPosLocation } from 'api/Integration/model/PosLocation';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import IntegrationModel from 'gen-thrift/integration_Model_types';
import IntegrationService from 'gen-thrift/IntegrationService';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { PosIntegrationType } from 'shared/models/IntegrationStatus';

export class POSIntegrationServiceImpl implements IPOSIntegrationService {
    constructor(
        private client : IntegrationService.IntegrationServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
    ) {
    }

    public getIntegrationState(type : PosIntegrationType, userSessionId : UserSessionId, locationId : LocationId) : Promise<IntegrationState> {
        switch (type) {
            case 'omnivore':
            case 'toast':
            case 'breadcrumb':
        }

        return new Promise<IntegrationState>((resolve, reject) => {
            this.client.getIntegrationState(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(type),
                (result : IntegrationModel.IntegrationState | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.serializeIntegrateStateFromThrift(result));
                }
            );
        });
    }

    public addIntegrationThroughOAuthFlow(
        type : PosIntegrationType,
        session : UserSessionId,
        locationId : LocationId,
        authorizationCode : string,
        codeVerifier? : string
    ) : Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.addIntegrationThroughOAuthFlow(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(session),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(type),
                authorizationCode,
                codeVerifier || null,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public removeIntegration(type : PosIntegrationType, userSessionId : UserSessionId, locationId : LocationId) : Promise<void> {
        switch (type) {
            case 'toast':
            case 'breadcrumb': {
                throw new RuntimeException('not supported: ' + type);
            }
        }

        return new Promise<void>((resolve, reject) => {
            this.client.removeIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(type),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public getOmnivoreIntegrationState(userSessionId : UserSessionId, locationId : LocationId) : Promise<OmnivoreIntegrationState> {
        return new Promise<OmnivoreIntegrationState>((resolve, reject) => {
            this.client.getOmnivoreIntegrationState(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : IntegrationModel.OmnivoreIntegrationState | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    let posType : null | OmnivoreSupportedPosType = null;
                    if (result.posType !== null) {
                        if (isOmnivoreSupportedPos(result.posType)) {
                            posType = result.posType;
                        } else {
                            throw new RuntimeException('unknown Omnivore-supported pos type: ' + result.posType);
                        }
                    }
                    return resolve(
                        new OmnivoreIntegrationState(
                            posType,
                            result.externalMerchantId ? result.externalMerchantId.value : null,
                            result.isAccessible,
                            result.cacheDataEffectiveTime ? moment(result.cacheDataEffectiveTime.timeSinceUnixEpoch.value) : null
                        )
                    );
                }
            );
        });
    }

    public addOmnivoreIntegration(userSessionId : UserSessionId, locationId : LocationId, omnivoreLocationId : string, posType : OmnivoreSupportedPosType) {
        return new Promise<void>((resolve, reject) => {
            this.client.addOmnivoreIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                omnivoreLocationId,
                posType,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });

    }

    public authorizeAndListAccessiblePosLocations(
        userSessionId : UserSessionId,
        locationId : LocationId,
        integrationType : PosIntegrationType,
        authorizationCode : string,
    ) : Promise<Array<IPosLocation>> {
        return new Promise<Array<IPosLocation>>((resolve, reject) => {
            this.client.authorizeAndListAccessiblePosLocations(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(integrationType),
                authorizationCode,
                (result : Array<IntegrationModel.PosLocation> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(result.map((v) => ({ id: v.id, name: v.name })));
                }
            );
        });
    }

    public associateAuthorizedLocationWithMerchant(
        userSessionId : UserSessionId,
        locationId : LocationId,
        integrationType : PosIntegrationType,
        merchantId : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.client.associateAuthorizedLocationWithMerchant(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(integrationType),
                merchantId,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public addCloverIntegration(
        actor : UserSessionId,
        locationId : LocationId,
        cloverMerchantId : string,
        authorizationCode : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.client.addCloverIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(actor),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                cloverMerchantId,
                authorizationCode,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public addSpotOnIntegration(
        actor : UserSessionId,
        locationId : LocationId,
        spotOnLocationId : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.client.addSpotOnIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(actor),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                spotOnLocationId,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public addHeartlandIntegration(
        actor : UserSessionId,
        locationId : LocationId,
        heartlandApiKey : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.client.addHeartlandIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(actor),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                heartlandApiKey,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public retrieveAccessToken(
        actor : UserSessionId,
        locationId : LocationId,
        integrationType : PosIntegrationType
    ) : Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.client.retrieveAccessToken(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(actor),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.getThriftIntegrationType(integrationType),
                (result : string | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve(result);
                }
            );
        });
    }

    public setPosCacheLastUpdateTime(
        type : PosIntegrationType,
        actor : UserSessionId,
        locationId : LocationId,
        time : Moment,
    ) : Promise<void> {
        switch (type) {
            case 'lightspeed':
            case 'kSeries':
            case 'heartland':
                return new Promise<void>((resolve, reject) => {
                    this.client.setPosCacheLastUpdateTime(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(actor),
                        this.getThriftIntegrationType(type),
                        this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                        this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(time),
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                });
            default:
                throw new RuntimeException('Cache reset not supported: ' + type);
        }
    }

    private serializeIntegrateStateFromThrift(value : IntegrationModel.IntegrationState) : IntegrationState {
        return new IntegrationState(
            value.externalMerchantId ? value.externalMerchantId.value : null,
            value.isAccessible,
            value.cacheDataEffectiveTime ? moment(value.cacheDataEffectiveTime.timeSinceUnixEpoch.value) : null
        );
    }

    private getThriftIntegrationType(type : PosIntegrationType) {
        switch (type) {
            case 'square': {
                return IntegrationModel.PosIntegrationType.SQUARE;
            }
            case 'lightspeed': {
                return IntegrationModel.PosIntegrationType.LIGHTSPEED;
            }
            case 'omnivore': {
                return IntegrationModel.PosIntegrationType.OMNIVORE;
            }
            case 'toast': {
                return IntegrationModel.PosIntegrationType.TOAST;
            }
            case 'breadcrumb': {
                return IntegrationModel.PosIntegrationType.BREADCRUMB;
            }
            case 'clover': {
                return IntegrationModel.PosIntegrationType.CLOVER;
            }
            case 'kSeries': {
                return IntegrationModel.PosIntegrationType.K_SERIES;
            }
            case 'spotOn': {
                return IntegrationModel.PosIntegrationType.SPOTON;
            }
            case 'heartland': {
                return IntegrationModel.PosIntegrationType.HEARTLAND;
            }
            default: {
                throw new RuntimeException('unknown integration type: ' + type);
            }
        }
    }
}
