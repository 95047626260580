import { IPlateIQIntegrationService } from 'api/Integration/interfaces/IPlateIQIntegrationService';
import { PlateIQIntegrationState } from 'api/Integration/model/PlateIQIntegrationState';
import { IntegrationJSONToObjectSerializer } from 'api/Integration/serializer/IntegrationJSONToObjectSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class PlateIQIntegrationServiceImpl implements IPlateIQIntegrationService {
    constructor(
        private readonly integrationJSONToObjectSerializer : IntegrationJSONToObjectSerializer
    ) {}

    public getPlateIQIntegrationState(userSessionId : UserSessionId, locationId : LocationId) : Promise<PlateIQIntegrationState> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:plate_iq_integration'), queryParameters)
        .then((response) => {
            return this.integrationJSONToObjectSerializer.getPlateIQIntegrationState(response);
        });
    }

    public updatePlateIQIntegration(
        userSessionId : UserSessionId,
        locationId : LocationId,
        plateIQRestuarantId : number,
        shouldSyncInvoices : boolean,
        glCodesToSync : Set<string> | null
    ) : Promise<PlateIQIntegrationState> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            plate_iq_restaurant_id: plateIQRestuarantId,
            should_sync_invoices: shouldSyncInvoices,
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:plate_iq_integration'), glCodesToSync, queryParameters)
        .then((response) => {
            return this.integrationJSONToObjectSerializer.getPlateIQIntegrationState(response);
        });
    }
}
