import { NormalizedLineItem } from "./NormalizedLineItem";

export class InvoiceProcessingResultLineItem {
    constructor (
        private readonly lineItemId : string,
        private readonly name : string,
        private readonly sku : string | null,
        private readonly price : number | null,
        private readonly quantity : number | null,
        private readonly unit : string | null,
        private readonly unitDeposit : number | null,
        private readonly discount : number | null,
        private readonly tax : number | null,
        private readonly otherAdjustments : number | null,
        private readonly packSize : string | null,   // may not match unit
        private readonly normalizedLineItem : NormalizedLineItem | null,
        // private readonly normalizedPackaging : NormalizedPackaging | null, TODO need to flesh this out and include it
    ) {}

    public getLineItemId() : string {
        return this.lineItemId;
    }

    public getName() : string {
        return this.name;
    }

    public getSku() : string | null {
        return this.sku;
    }

    public getPrice() : number | null {
        return this.price;
    }

    public getQuantity() : number | null {
        return this.quantity;
    }

    public getUnit() : string | null {
        return this.unit;
    }

    public getUnitDeposit() : number | null {
        return this.unitDeposit;
    }

    public getDiscount() : number | null {
        return this.discount;
    }

    public getTax() : number | null {
        return this.tax;
    }

    public getOtherAdjustments() : number | null {
        return this.otherAdjustments;
    }

    public getPackSize() : string | null {
        return this.packSize;
    }

    public getNormalizedLineItem() : NormalizedLineItem | null {
        return this.normalizedLineItem;
    }

    public getTotalCost() : number | null {
        if (this.price === null || this.quantity === null) {
            return null;
        }
        // ok to assume deposit as 0, but not quite for price and quantity
        return (this.price + (this.unitDeposit || 0)) * this.quantity + (this.tax || 0) - (this.discount || 0) + (this.otherAdjustments || 0);
    }
}
