import { decimalUtils, numberToDecimal } from 'shared/utils/decimalUtils';

import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import {ProductId} from "api/Product/model/ProductId";

const getBottleCostFromProductCost = (packagingsAndMappings : PackagingsAndMappings, productCost : ProductCost, productId? : ProductId) : number => {

    const bottlePackagingId = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());

    // Note that we are using convertProductQuantityToUnit() with the from and to unit switched because
    // in product cost the packaging unit is in the denominator ($/unit).
    return PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit(decimalUtils.decimalToNumber(productCost.getCost()), bottlePackagingId),
        productCost.getUnit(),
        productId
    ).getQuantity();
};

const getProductCostFromBottleCost = (
    packagingsAndMappings : PackagingsAndMappings,
    bottleCost : number,
    productQuantityUnit : ProductQuantityUnit,
    productId? : ProductId
) : ProductCost => {

    const bottlePackagingId = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());

    // Note that we are using convertProductQuantityToUnit() with the from and to unit switched because
    // in product cost the packaging unit is in the denominator ($/unit).
    const productCost = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit(bottleCost, productQuantityUnit),
        bottlePackagingId,
        productId
    ).getQuantity();

    return new ProductCost(
        numberToDecimal(productCost),
        productQuantityUnit);
};

// TODO Duplicated logic in getCostInUnit
const getProductCostInUnit = (packagingsAndMappings : PackagingsAndMappings, productCost : ProductCost, productQuantityUnit : ProductQuantityUnit, productId? : ProductId) : ProductCost => {
    // Note that we are using convertProductQuantityToUnit() with the from and to unit switched because
    // in product cost the packaging unit is in the denominator ($/unit).
    const cost = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit(decimalUtils.decimalToNumber(productCost.getCost()), productQuantityUnit),
        productCost.getUnit(),
        productId
    ).getQuantity();
    return new ProductCost(
        numberToDecimal(cost),
        productQuantityUnit);
};

const totalDollarCostOfQuantity = (
    packagingsAndMappings : PackagingsAndMappings,
    productCost : ProductCost,
    quantity : QuantityInUnit<ProductQuantityUnit>,
    productId? : ProductId)
    : number => {

    const quantityInCostUnits : QuantityInUnit<ProductQuantityUnit> =
        PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, quantity, productCost.getUnit(), productId);

    return quantityInCostUnits.getQuantity() * decimalUtils.decimalToNumber(productCost.getCost());
};

const getDisplayInfoForProductCost = (
    packagingsAndMappings : PackagingsAndMappings,
    productCost : ProductCost,
) : { resolvedCostAmount : number, displayTextForCostUnit : string } => {

    const costAsNumber = decimalUtils.decimalToNumber(productCost.getCost());
    const resolvedQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(
        new QuantityInUnit(costAsNumber, productCost.getUnit()),
        packagingsAndMappings.getMappings()
    );
    const resolvedProductQuantityUnit = resolvedQuantityInUnit.getUnit();

    let displayTextForCostUnit;
    if (window.GLOBAL_FEATURE_ACCESS.multi_packaging) {
        displayTextForCostUnit = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, resolvedProductQuantityUnit, false);
    } else {
        displayTextForCostUnit = PackagingUtils.getDisplayTextForProductQuantityUnit(packagingsAndMappings, resolvedProductQuantityUnit, false);
    }

    return {
        resolvedCostAmount: resolvedQuantityInUnit.getQuantity(),
        displayTextForCostUnit,
    };
};

const divideProductCosts = (
    productId : ProductId,
    packagingsAndMappings : PackagingsAndMappings,
    productCost1 : ProductCost,
    productCost2 : ProductCost,
) : number => {
    const cost1InCost2Unit = getCostInUnit(productCost1, productCost2.getUnit(), packagingsAndMappings);
    return cost1InCost2Unit / decimalUtils.decimalToNumber(productCost2.getCost());
};

const getCostInUnit = (
    productCost : ProductCost,
    unit : ProductQuantityUnit,
    packagingsAndMappings : PackagingsAndMappings,
) : number => {
    return decimalUtils.decimalToNumber(productCost.getCost()) * PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit<ProductQuantityUnit>(1, unit),
        productCost.getUnit()
    ).getQuantity();
};

const shouldWarnAboutProductCost = (
    productCost: ProductCost,
    referenceProductCost: ProductCost,
    packagingsAndMappings: PackagingsAndMappings,
    productId: ProductId
) : boolean => {
    let ratio: number;
    try {
        ratio = divideProductCosts(productId, packagingsAndMappings, productCost, referenceProductCost);
    } catch (e) {
        // don't crash the page if can't calculate for some reason
        // tslint:disable-next-line:no-console
        console.log('failed to calculate ratio for product cost: ' + productId.getValue() + ' ' + JSON.stringify(productCost) + '/' + JSON.stringify(referenceProductCost), e);
        return false;
    }

    // warn if difference is more than 50% (including Infinity)
    return Math.abs(ratio - 1) > 0.5;
};

export const productCostUtils = {
    getBottleCostFromProductCost,
    getProductCostFromBottleCost,
    getProductCostInUnit,
    totalDollarCostOfQuantity,
    getDisplayInfoForProductCost,
    shouldWarnAboutProductCost,
    getCostInUnit,
};
