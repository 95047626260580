import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Conversions } from 'api/Product/model/Conversions';
import { Mappings } from 'api/Product/model/Mappings';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Packaging } from 'api/Product/model/Packaging';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { PackagingFormUtils } from 'shared/components/Product/PackagingFormUtils';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingWeight } from 'api/Product/model/PackagingWeight';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { DateTime } from 'shared/models/DateTime';

import { ProductDistributorAssociationFormId } from '../model/ProductDistributorAssociationFormId';
import { IProductDistributorAssociationForm, PackagingWeightFormFieldName, PackagingWeightFormValidationInputDataByFieldName, ProductDistributorAssociationFormFieldName, ProductFormFieldName, ProductFormValidationInputDataByFieldName } from '../reducers/ItemCardReducers';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { PackagingForm, PackagingFormValidationInputDataByFieldName } from 'shared/components/Product/PackagingForm';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { productJSONToObjectSerializer } from 'shared/lib/manager';
import { IValidationResult, Validation } from 'shared/validators/validators';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { decimalToNumberString, decimalUtils } from 'shared/utils/decimalUtils';
import { ProductCost } from 'api/Product/model/ProductCost';
import { productCostUtils } from 'api/Product/utils/productCostUtils';

const getWeightUnitOptions = () : OptionsAndLabelNameTuples => {
    return [
        [ null, [
            {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(MassUnit.GRAM),
                value: MassUnit.GRAM,
            },
            {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(MassUnit.DRY_OUNCE),
                value: MassUnit.DRY_OUNCE,
            },
            {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(MassUnit.KILOGRAM),
                value: MassUnit.KILOGRAM,
            },
            {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(MassUnit.POUND),
                value: MassUnit.POUND,
            }
        ]]
    ];
};

const getSortedFormProductQuantityUnitValuesAndLabels = (packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName) : Array<{value : string; label: string; shortLabel: string; productQuantityUnit: ProductQuantityUnit}> => {
    // Returns values and labels sorted from largest available packaging to smallest
    // value is used as a compound idenfier for packagings in form dropdowns since this is needed before real PackagingIds are created and they might have the same names
    // productQuantityUnit is for looking up the specific level of packaging in the packaging object that is returned by PackagingForm.getPackaging()

    const sortedUnitValues : Array<{value : string; label: string; shortLabel: string; productQuantityUnit: ProductQuantityUnit}> = [];

    const caseNameValue = packagingFormValidationInputDataByFieldName.caseName.value;
    const comesAsCaseValue = packagingFormValidationInputDataByFieldName.comesAsCase.value;
    const unitNameValue = packagingFormValidationInputDataByFieldName.unitName.value;
    const caseQuantityValue = packagingFormValidationInputDataByFieldName.caseQuantity.value || '?';
    const contentQuantityValue = packagingFormValidationInputDataByFieldName.contentQuantity.value || '?';

    const contentUnitValue = packagingFormValidationInputDataByFieldName.contentUnit.value;
    const contentUnitLabel = oldPackagingUtils.getDisplayTextForUnit(contentUnitValue as any);

    const unitProductQuantityUnitValue = `${ unitNameValue } (${ contentQuantityValue }${ contentUnitValue })`;
    const unitProductQuantityUnitLabel = `${ unitNameValue } (${ contentQuantityValue }${ contentUnitLabel })`;

    const caseProductQuantityUnitValue = `${ caseNameValue } (${ caseQuantityValue } x ${ contentQuantityValue }${ contentUnitValue } (${ unitNameValue }))`;
    const caseProductQuantityUnitLabel = `${ caseNameValue } (${ caseQuantityValue } x ${ contentQuantityValue }${ contentUnitLabel } (${ unitNameValue }))`;

    if (comesAsCaseValue && caseNameValue) {
        sortedUnitValues.push({
            value: caseProductQuantityUnitValue,
            label: caseProductQuantityUnitLabel,
            shortLabel: caseNameValue,
            productQuantityUnit: new PackagingId(caseNameValue),
        });
    }

    sortedUnitValues.push({
        value: unitProductQuantityUnitValue,
        label: unitProductQuantityUnitLabel,
        shortLabel: unitNameValue,
        productQuantityUnit: new PackagingId(unitNameValue),
    });

    sortedUnitValues.push({
        value: contentUnitValue,
        label: contentUnitLabel,
        shortLabel: contentUnitValue,
        productQuantityUnit: productJSONToObjectSerializer.getProductQuantityUnit(contentUnitValue),
    });

    return sortedUnitValues;
};

const getFormProductQuantityUnitValuesByPackagingId = (packagings : Array<Packaging>) : StringValueMap<PackagingId, string> => {
    const formProductQuantityUnitValuesByPackagingId = new StringValueMap<PackagingId, string>();

    packagings.forEach((packaging) => {
        const packagingFormValidationInputDataByFieldName = PackagingFormUtils.getValidationInputDataByFieldNameFromInitialPackaging(packaging);
        const sortedUnitValuesAndLabels = getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);

        let currentPackaging : Packaging | null = packaging;
        let currentSortedUnitValuesIndex = 0;

        while (currentPackaging) {
            const currentPackagingId = currentPackaging.getPackagingId();

            if (currentPackagingId) {
                formProductQuantityUnitValuesByPackagingId.set(currentPackagingId, sortedUnitValuesAndLabels[currentSortedUnitValuesIndex].value);
            }

            currentSortedUnitValuesIndex += 1;
            currentPackaging = currentPackaging.getContent();
        }
    });

    return formProductQuantityUnitValuesByPackagingId;
};

const getAllPackagingUnitIOptions = (packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName>, useDefaultLabels : boolean) : OptionsAndLabelNameTuples => {
    const sortedUnitIOptions : Array<IOption> = [];
    const unitValues = new Set<string>();

    packagingFormsValidationInputDataByFieldName.forEach((packagingFormValidationInputDataByFieldName) => {
        const sortedUnitValuesAndLabels = getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);

        sortedUnitValuesAndLabels.forEach((unitValueAndLabel) => {
            const {
                value,
                label,
                shortLabel
            } = unitValueAndLabel;

            if (!unitValues.has(value)) {
                sortedUnitIOptions.push({
                    icon: null,
                    label: useDefaultLabels ? label : shortLabel,
                    value,
                });

                unitValues.add(value);
            }
        });
    });

    return [[null, sortedUnitIOptions]];
};

const getSortedBaseUnitValues = (packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName>) : Array<string> => {
    const sortedBaseUnitValues : Array<string> = [];
    const baseUnitValues = new Set<string>();

    packagingFormsValidationInputDataByFieldName.forEach((packagingFormValidationInputDataByFieldName) => {
        const contentUnitValue = packagingFormValidationInputDataByFieldName.contentUnit.value;
    
        if (contentUnitValue && !baseUnitValues.has(contentUnitValue)) {
            sortedBaseUnitValues.push(contentUnitValue);
            baseUnitValues.add(contentUnitValue);
        }
    });  

    return sortedBaseUnitValues;
};

const getBaseUnitIOptions = (packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName>) : OptionsAndLabelNameTuples => {
    const sortedBaseUnitValues = getSortedBaseUnitValues(packagingFormsValidationInputDataByFieldName);

    return [[
        null,
        sortedBaseUnitValues.map((value) => {
            return {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(value as any),
                value,
            };
        })
    ]];
};

const getUnitsWithConversionForms = (packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName>, selectedBaseUnitValue : string) : Array<string> => {
    const sortedBaseUnitValues = getSortedBaseUnitValues(packagingFormsValidationInputDataByFieldName);

    let massUnitIsAccountedFor = MassUnit.isMassUnitValue(selectedBaseUnitValue);
    let volumeUnitIsAccountedFor = VolumeUnit.isVolumeUnitValue(selectedBaseUnitValue);
    const conversionUnits : Array<string> = [];
    sortedBaseUnitValues.forEach((unitValue) => {
        if (unitValue === selectedBaseUnitValue) {
            return;
        }

        const isMassUnitValue = MassUnit.isMassUnitValue(unitValue);
        const isVolumeUnitValue = VolumeUnit.isVolumeUnitValue(unitValue);
        if ((isMassUnitValue && massUnitIsAccountedFor) || (isVolumeUnitValue && volumeUnitIsAccountedFor)) {
            return;
        }

        conversionUnits.push(unitValue);
        massUnitIsAccountedFor = massUnitIsAccountedFor || isMassUnitValue;
        volumeUnitIsAccountedFor = volumeUnitIsAccountedFor || isVolumeUnitValue;
    });

    return conversionUnits;
};

// generate options for new packaging weight form from available packagingIds
const getPackagingWeightFormPackagingIdIOptions = (packagingFormsValidationInputDataByFieldName : Array<PackagingFormValidationInputDataByFieldName>, packagingIdValuesWithPackagingWeightForms : Set<string>) : Array<IOption> => {
    const sortedUnitIOptions : Array<IOption> = [];
    const unitValues = new Set<string>();

    packagingFormsValidationInputDataByFieldName.forEach((packagingFormValidationInputDataByFieldName) => {
        const sortedUnitValuesAndLabels = getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);

        sortedUnitValuesAndLabels.forEach((unitValueAndLabel) => {
            const {
                value,
                label
            } = unitValueAndLabel;

            if (!unitValues.has(value) && !MassUnit.isMassUnitValue(value) && !VolumeUnit.isVolumeUnitValue(value)) {
                sortedUnitIOptions.push({
                    icon: null,
                    label,
                    value,
                    disabled: packagingIdValuesWithPackagingWeightForms.has(value)
                });

                unitValues.add(value);
            }
        });
    });

    return sortedUnitIOptions;
};

// For looking up the package data of a layer inside a package among multiple packages from the packaging form state, e.g. for generating default bottle weight.
// Return what's basically a nested/compound key that identifies the (top-level) package data (with the index) and the layer (with the PackagingId).
// We do this because a package may not yet have had a valid PackagingId generated but we still need to refer to it somehow in forms and this is how we do the lookup in the opposite direction
const getPackagingFormDataIndexByFormProductQuantityUnitValue = (packagingFormData : Array<{packagingForm : PackagingForm | null, isShown : boolean}>) => {
    const packagingFormDataIndexByFormProductQuantityUnitValue = new Map<string, {index: number, productQuantityUnit: ProductQuantityUnit}>();

    packagingFormData.forEach((packagingFormDataEntry, index) => {
        const packagingForm = packagingFormDataEntry.packagingForm;

        if (packagingFormDataEntry.isShown && packagingForm) {
            const packagingFormValidationInputDataByFieldName = packagingForm.getValidationInputDataByFieldName();
            const sortedUnitValuesAndLabels = getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);
    
            sortedUnitValuesAndLabels.forEach((unitValueAndLabel) => {
                packagingFormDataIndexByFormProductQuantityUnitValue.set(unitValueAndLabel.value, {index, productQuantityUnit: unitValueAndLabel.productQuantityUnit});
            });
        }
    });

    return packagingFormDataIndexByFormProductQuantityUnitValue;
};

const getPriceUnitIOptions = (packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName) : OptionsAndLabelNameTuples => {
    const sortedPriceUnitValues = [];

    const caseNameValue = packagingFormValidationInputDataByFieldName.caseName.value;
    const comesAsCaseValue = packagingFormValidationInputDataByFieldName.comesAsCase.value;
    const unitNameValue = packagingFormValidationInputDataByFieldName.unitName.value;
    const contentUnitValue = packagingFormValidationInputDataByFieldName.contentUnit.value;

    if (comesAsCaseValue && caseNameValue) {
        sortedPriceUnitValues.push(caseNameValue);
    }

    if (unitNameValue) {
        sortedPriceUnitValues.push(unitNameValue);
    }

    if (contentUnitValue) {
        sortedPriceUnitValues.push(contentUnitValue);
    }

    return [[
        null,
        sortedPriceUnitValues.map((priceUnitValue) => {
            return {
                icon: null,
                label: oldPackagingUtils.getDisplayTextForUnit(priceUnitValue as any),
                value: priceUnitValue,
            };
        })
    ]];
};

const validateProductDistributorAssociationValueByFieldName = (
    fieldName : ProductDistributorAssociationFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    let fieldNameText = '';
    switch (fieldName) {
        case 'unit':
            fieldNameText = 'Unit';
            break;
        case 'distributorId':
            fieldNameText = 'Vendor';
            break;
        case 'priceAmount':
            fieldNameText = 'Price';
            break;
        case 'priceUnit':
            fieldNameText = 'Price Unit';
            break;
    }

    if (fieldNameText) {
        isValid = Validation.validateRequired(value);
        if (!isValid) {
            errorMessage = `${ fieldNameText } is required`;
            return {
                errorMessage,
                isValid,
            };
        }
    }

    // input type specific
    switch (fieldName) {
        case 'priceUnit':
        case 'depositUnit':
            isValid = true;
            errorMessage = '';
            break;
        case 'sku':
            isValid = Validation.validateLongText(value);
            if (!isValid) {
                errorMessage = 'Text too long';
            }
            break;
        case 'priceAmount':
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'depositAmount':
            if (value === '') {
                isValid = true;
                errorMessage = '';
                break;
            }
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

const validatePackagingWeightValueByFieldName = (
    fieldName : PackagingWeightFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // input type specific
    switch (fieldName) {
        case 'emptyWeightUnit':
        case 'fullWeightUnit':
            isValid = MassUnit.isMassUnitValue(value);
            if (!isValid) {
                errorMessage = 'Invalid weight unit';
            }
            break;
        case 'emptyWeightQuantity':
        case 'fullWeightQuantity':
            isValid = (value === '') || Validation.validateNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

const validateValueByFieldName = (
    fieldName : ProductFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    let fieldNameText = '';
    switch (fieldName) {
        case 'name':
            fieldNameText = 'Item name';
            break;
        case 'priceAmount':
            fieldNameText = 'Price amount';
            break;
        case 'priceUnit':
            fieldNameText = 'Price unit';
            break;
        case 'baseUnit':
            fieldNameText = 'Base unit';
            break;
        case 'preferredReportingUnit':
            fieldNameText = 'Preferred reporting unit';
            break;
        case 'categoryId':
            fieldNameText = 'Category';
            break;
        case 'unitDeposit':
            fieldNameText = 'Unit deposit';
            break;
        case 'costUnit':
            fieldNameText = 'Cost unit';
            break;
        case 'parUnit':
            fieldNameText = 'Par unit';
            break;
    }

    if (fieldNameText) {
        isValid = Validation.validateRequired(value);
        if (!isValid) {
            errorMessage = `${ fieldNameText } is required`;
            return {
                errorMessage,
                isValid,
            };
        }
    }

    // input type specific
    switch (fieldName) {
        case 'useCustomWeights':
        case 'distributorId':
            isValid = true;
            errorMessage = '';
            break;
        case 'note':
        case 'type':
        case 'sku':
        case 'glCode':
        case 'categoryId':
            isValid = Validation.validateLongText(value);
            if (!isValid) {
                errorMessage = 'Text too long';
            }
            break;
        case 'name':
        case 'brand':
            isValid = Validation.validateShortText(value);
            if (!isValid) {
                errorMessage = 'Text too long';
            }
            break;
        case 'priceAmount':
            isValid = Validation.validateNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'costAmount':
            if (value === '') {
                isValid = true;
                errorMessage = '';
                break;
            }
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'unitDeposit':
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'parCount':
            if (value === '') {
                isValid = true;
                errorMessage = '';
                break;
            }
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

const getPackagingWithValidPackagingId = (packaging : Packaging, sortedUnitValuesIndex : number, sortedUnitValues : Array<string>, packagingIdsByformProductQuantityUnit : Map<string, PackagingId>, generatePackagingId : () => PackagingId) : Packaging => {
    const unit = packaging.getUnit();

    if (unit) {
        return new Packaging(null, null, null, null, unit);
    } else {
        const unitValue = sortedUnitValues[sortedUnitValuesIndex];

        let packagingId = packagingIdsByformProductQuantityUnit.get(unitValue);
        if (typeof packagingId === 'undefined') {
            packagingId = generatePackagingId();
            packagingIdsByformProductQuantityUnit.set(unitValue, packagingId);
        }

        const content = packaging.getContent();

        return new Packaging(
            packagingId,
            packaging.getName(),
            (content === null) ? null : getPackagingWithValidPackagingId(content, sortedUnitValuesIndex + 1, sortedUnitValues, packagingIdsByformProductQuantityUnit, generatePackagingId),
            packaging.getQuantityOfContent(),
            unit
        );
    }
};

const getProductQuantityUnitFromFormValue = (formProductQuantityUnitValue : string, packagingIdsByformProductQuantityUnit : Map<string, PackagingId>) : ProductQuantityUnit => {
    if (MassUnit.isMassUnitValue(formProductQuantityUnitValue)) {
        return MassUnit.getByMassUnitValue(formProductQuantityUnitValue);
    } else if (VolumeUnit.isVolumeUnitValue(formProductQuantityUnitValue)) {
        return VolumeUnit.getByVolumeUnitValue(formProductQuantityUnitValue);
    }

    const packagingId = packagingIdsByformProductQuantityUnit.get(formProductQuantityUnitValue);
    if (typeof packagingId === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    return packagingId;
};

// TODO Cheezy probably rename
const getProductAndDistributorIdAndPar = (
    productFormValidationInputDataByFieldName : ProductFormValidationInputDataByFieldName,
    packagingsWithDummyPackagingIds : Array<Packaging>,
    savedProduct : Product | null,
    updateEventUserAccountIdAndTimestamp : UserAccountIdAndTimestamp,
    generatePackagingId : () => PackagingId
) : {
    product : Product, distributorId : DistributorId | null, par : QuantityInUnit<ProductQuantityUnit> | null,
    productCost : ProductCost | null, getProductDistributorAssociations : (productId : ProductId) => Array<ProductDistributorAssociation> } => {

    const distributorIdValue = productFormValidationInputDataByFieldName.distributorId.value;

    const brand = productFormValidationInputDataByFieldName.brand.value;
    const name = productFormValidationInputDataByFieldName.name.value;
    const productType = productFormValidationInputDataByFieldName.type.value;
    const sku = productFormValidationInputDataByFieldName.sku.value;
    const glCode = productFormValidationInputDataByFieldName.glCode.value;
    const note = productFormValidationInputDataByFieldName.note.value;
    const baseUnitValue = productFormValidationInputDataByFieldName.baseUnit.value;
    const preferredReportingUnitValue = productFormValidationInputDataByFieldName.preferredReportingUnit.value;

    const packagingIdsByformProductQuantityUnit = new Map<string, PackagingId>();

    if (savedProduct) {
        const nonDeletedPackagings : Array<Packaging> = [];
        savedProduct.getPackagingsAndMappings().getPackagingData().forEach((packagingData) => {
            if (!packagingData.deleted) {
                nonDeletedPackagings.push(packagingData.packaging);
            }
        });

        const formProductQuantityUnitValuesByPackagingId = getFormProductQuantityUnitValuesByPackagingId(nonDeletedPackagings);

        formProductQuantityUnitValuesByPackagingId.forEach((formProductQuantityUnitValue, packagingId) => {
            packagingIdsByformProductQuantityUnit.set(formProductQuantityUnitValue, packagingId);
        });
    }

    const packagings : Array<Packaging> = [];
    packagingsWithDummyPackagingIds.forEach((packaging) => {
        const packagingFormValidationInputDataByFieldName = PackagingFormUtils.getValidationInputDataByFieldNameFromInitialPackaging(packaging);
        const sortedUnitValuesAndLabels = getSortedFormProductQuantityUnitValuesAndLabels(packagingFormValidationInputDataByFieldName);
        const sortedUnitValues = sortedUnitValuesAndLabels.map((unitAndValue) => {
            return unitAndValue.value;
        });

        packagings.push(getPackagingWithValidPackagingId(packaging, 0, sortedUnitValues, packagingIdsByformProductQuantityUnit, generatePackagingId));
    });

    const topLevelPackagingIdsToSave = new StringValueSet<PackagingId>();
    const packagingDataToSave = packagings.map((packaging) => {
        const packagingId = packaging.getPackagingId();
        if (packagingId === null) {
            throw new RuntimeException('unexpected');
        }

        topLevelPackagingIdsToSave.add(packagingId);

        return {
            isActive: true,
            deleted: false,
            packaging
        };
    });

    if (savedProduct) {
        savedProduct.getPackagingsAndMappings().getPackagingData().forEach((packagingData) => {
            if (packagingData.deleted) {
                packagingDataToSave.push(packagingData);
            } else {
                const packagingId = packagingData.packaging.getPackagingId();
                if (packagingId === null) {
                    throw new RuntimeException('unexpected');
                }

                if (!topLevelPackagingIdsToSave.has(packagingId)) {
                    packagingDataToSave.push({
                        isActive: false,
                        deleted: true,
                        packaging: packagingData.packaging
                    });
                }
            }
        });
    }

    const preferredReportingUnit = getProductQuantityUnitFromFormValue(preferredReportingUnitValue, packagingIdsByformProductQuantityUnit);
    const baseUnit = getProductQuantityUnitFromFormValue(baseUnitValue, packagingIdsByformProductQuantityUnit);

    const packagingIdConversions = new StringValueMap<PackagingId, number>();
    const unitOfMeasureConversions = new Map<MassUnit | VolumeUnit, number>();
    Object.keys(productFormValidationInputDataByFieldName.conversions).map((unit) => {
        const productQuantityUnit = getProductQuantityUnitFromFormValue(unit, packagingIdsByformProductQuantityUnit);
        const conversionQuantity = parseFloat(productFormValidationInputDataByFieldName.conversions[unit].value);

        if (productQuantityUnit instanceof PackagingId) {
            packagingIdConversions.set(productQuantityUnit, conversionQuantity);
        } else {
            unitOfMeasureConversions.set(productQuantityUnit, conversionQuantity);
        }
    });

    const packagingsAndMappings = new PackagingsAndMappings(
        packagingDataToSave,
        new Mappings(new StringValueMap(), new Map()),
        new Conversions(baseUnit, packagingIdConversions, unitOfMeasureConversions)
    );
    const productCategoryId = productFormValidationInputDataByFieldName.categoryId.value;
    const depositInDollars = parseFloat(productFormValidationInputDataByFieldName.unitDeposit.value);

    const weightsByPackagingId = new StringValueMap<PackagingId, PackagingWeight>();
    if (productFormValidationInputDataByFieldName.useCustomWeights.value) {
        Object.keys(productFormValidationInputDataByFieldName.packagingWeights).map((packagingIdValue) => {
            const productQuantityUnit = getProductQuantityUnitFromFormValue(packagingIdValue, packagingIdsByformProductQuantityUnit);    
            const packagingWeight = getPackagingWeight(productFormValidationInputDataByFieldName.packagingWeights[packagingIdValue]);

            if (productQuantityUnit instanceof PackagingId) {
                weightsByPackagingId.set(productQuantityUnit, packagingWeight);
            } else {
                throw new RuntimeException(`productQuantityUnit: "${ productQuantityUnit }" is unexpectedly not a PackagingId`);
            }
        });
    }

    const unitPrice = new Price(
        parseFloat(productFormValidationInputDataByFieldName.priceAmount.value),
        oldPackagingUtils.getUnitFromValue(productFormValidationInputDataByFieldName.priceUnit.value)
    );

    let par : QuantityInUnit<ProductQuantityUnit> | null = null;
    if (productFormValidationInputDataByFieldName.parCount.value) {
        par = new QuantityInUnit(
            parseFloat(productFormValidationInputDataByFieldName.parCount.value),
            getProductQuantityUnitFromFormValue(productFormValidationInputDataByFieldName.parUnit.value, packagingIdsByformProductQuantityUnit)
        );
    }

    let productCost : ProductCost | null = null;
    if (productFormValidationInputDataByFieldName.costAmount.value) {
        productCost = new ProductCost(
            decimalUtils.numberStringToDecimal(productFormValidationInputDataByFieldName.costAmount.value),
            getProductQuantityUnitFromFormValue(productFormValidationInputDataByFieldName.costUnit.value, packagingIdsByformProductQuantityUnit)
        );
    }

    const getProductDistributorAssociations = (productId : ProductId) : Array<ProductDistributorAssociation> => {
        const productDistributorAssociations : Array<ProductDistributorAssociation> = [];

        productFormValidationInputDataByFieldName.productDistributorAssociations.forEach((productDistributorAssociationForm) => {
            let price : ProductCost | null = null;
            if (productDistributorAssociationForm.validationInputDataByFieldName.priceAmount.value) {
                price = new ProductCost(
                    decimalUtils.numberStringToDecimal(productDistributorAssociationForm.validationInputDataByFieldName.priceAmount.value),
                    getProductQuantityUnitFromFormValue(productDistributorAssociationForm.validationInputDataByFieldName.priceUnit.value, packagingIdsByformProductQuantityUnit)
                );
            }

            let deposit : ProductCost | null = null;
            if (productDistributorAssociationForm.validationInputDataByFieldName.depositAmount.value) {
                deposit = new ProductCost(
                    decimalUtils.numberStringToDecimal(productDistributorAssociationForm.validationInputDataByFieldName.depositAmount.value),
                    getProductQuantityUnitFromFormValue(productDistributorAssociationForm.validationInputDataByFieldName.depositUnit.value, packagingIdsByformProductQuantityUnit)
                );
            }

            productDistributorAssociations.push(new ProductDistributorAssociation(
                productId,
                getProductQuantityUnitFromFormValue(productDistributorAssociationForm.validationInputDataByFieldName.unit.value, packagingIdsByformProductQuantityUnit),
                new DistributorId(productDistributorAssociationForm.validationInputDataByFieldName.distributorId.value),
                productDistributorAssociationForm.validationInputDataByFieldName.sku.value ? productDistributorAssociationForm.validationInputDataByFieldName.sku.value : null,
                price,
                deposit
            ));
        });

        return productDistributorAssociations;
    };

    return {
        product: new Product(
            brand,
            name,
            packagingsAndMappings,
            preferredReportingUnit,
            weightsByPackagingId,
            productCategoryId,
            productCategoryId.length > 0 ? new CategoryId(productCategoryId) : null,
            productType,
            unitPrice,
            depositInDollars,
            sku,
            glCode,
            note,
            updateEventUserAccountIdAndTimestamp
        ),
        distributorId: distributorIdValue ? new DistributorId(distributorIdValue) : null,
        par,
        productCost,
        getProductDistributorAssociations
    };
};

// TODO Cheezy where should this live?
const getProductDistributorAndParForEditOrCreate = (
    productWithDummyPackaging : Product,
    savedProduct : Product | null,
    distributorId : DistributorId | null,
    productCost : ProductCost | null,
    par : QuantityInUnit<ProductQuantityUnit> | null,
) : { product : Product, distributorId : DistributorId | null, par : QuantityInUnit<ProductQuantityUnit> | null } => {
    const packagingsWithDummyPackagingIds : Array<Packaging> = [];
    productWithDummyPackaging.getPackagingsAndMappings().getPackagingData().forEach((packagingData) => {
        if (!packagingData.deleted) {
            packagingsWithDummyPackagingIds.push(packagingData.packaging);
        }
    });

    // TODO Cheezy
    return getProductAndDistributorIdAndPar(
        getValidationInputDataByFieldName(productWithDummyPackaging, distributorId, productCost, par, []),
        packagingsWithDummyPackagingIds,
        savedProduct,
        new UserAccountIdAndTimestamp(new UserAccountId(window.GLOBAL_USER_ID), DateTime.now().toTimestampWithMillisecondPrecision()),
        PackagingUtils.generatePackagingId,
    );
};

const getValidationInputDataByFieldName = (
    product : Product,
    distributorId : DistributorId | null,
    productCost : ProductCost | null,
    par : QuantityInUnit<ProductQuantityUnit> | null,
    productDistributorAssociations : Array<ProductDistributorAssociation>,
) : ProductFormValidationInputDataByFieldName => {
    const defaultFormFieldValidation = {
        isValid: true,
        errorMessage: '',
    };

    const nonDeletedPackagings : Array<Packaging> = [];
    product.getPackagingsAndMappings().getPackagingData().forEach((packagingData) => {
        if (!packagingData.deleted) {
            nonDeletedPackagings.push(packagingData.packaging);
        }
    });
    const formProductQuantityUnitValuesByPackagingId = getFormProductQuantityUnitValuesByPackagingId(nonDeletedPackagings);

    const conversions : { [productQuantityUnitValue : string] : IValidationInputData } = {};
    product.getPackagingsAndMappings().getConversions().getUnitOfMeasureConversions().forEach((value, unitOfMeasure) => {
        conversions[unitOfMeasure] = {
            value: value.toString(),
            ...defaultFormFieldValidation,
        };
    });

    product.getPackagingsAndMappings().getConversions().getPackagingIdConversions().forEach((value, packagingId) => {
        const formProductQuantityUnitValue = formProductQuantityUnitValuesByPackagingId.getRequired(packagingId);
        conversions[formProductQuantityUnitValue] = {
            value: value.toString(),
            ...defaultFormFieldValidation,
        };
    });

    const preferredReportingUnit = product.getPreferredReportingUnit();
    const baseUnit = product.getPackagingsAndMappings().getConversions().getBaseUnit();

    const resolvedPar = par ? PackagingUtils.resolveProductQuantityUnit(par, product.getPackagingsAndMappings().getMappings()) : null;
    const parUnit = resolvedPar ? resolvedPar.getUnit() : null;

    const resolvedCostUnit = productCost ? PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(1, productCost.getUnit()), product.getPackagingsAndMappings().getMappings()).getUnit() : null;
    const resolvedProductCostAmount = (productCost && resolvedCostUnit) ? productCostUtils.getCostInUnit(productCost, resolvedCostUnit, product.getPackagingsAndMappings()) : null;

    const packagingWeights : { [productQuantityUnitValue : string] : PackagingWeightFormValidationInputDataByFieldName } = {};
    product.getWeightsByPackagingId().forEach((packagingWeight, packagingId) => {
        const formProductQuantityUnitValue = formProductQuantityUnitValuesByPackagingId.get(packagingId);

        const emptyWeight = packagingWeight.getEmptyWeight();
        const fullWeight = packagingWeight.getFullWeight();

        if (formProductQuantityUnitValue) {
            packagingWeights[formProductQuantityUnitValue] = {
                emptyWeightQuantity: {
                    value: emptyWeight ? emptyWeight.getQuantity().toString() : '',
                    ...defaultFormFieldValidation,
                },
                emptyWeightUnit: {
                    value: emptyWeight ? emptyWeight.getUnit() : MassUnit.DRY_OUNCE,
                    ...defaultFormFieldValidation,
                },
                fullWeightQuantity: {
                    value: fullWeight ? fullWeight.getQuantity().toString() : '',
                    ...defaultFormFieldValidation,
                },
                fullWeightUnit: {
                    value: fullWeight ? fullWeight.getUnit() : MassUnit.DRY_OUNCE,
                    ...defaultFormFieldValidation,
                },
            };
        }
    });

    const categoryId = product.getNewProductCategoryId();

    const productDistributorAssociationsByFormId = new StringValueMap<ProductDistributorAssociationFormId, IProductDistributorAssociationForm>();
    productDistributorAssociations.forEach((productDistributorAssociation) => {
        const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(
            new QuantityInUnit(1, productDistributorAssociation.getProductQuantityUnit()),
            product.getPackagingsAndMappings().getMappings()
        ).getUnit(); // TODO Cheezy do we need to resolve?

        const unitValue = (resolvedProductQuantityUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(resolvedProductQuantityUnit) : resolvedProductQuantityUnit;

        const price = productDistributorAssociation.getPrice();
        const deposit = productDistributorAssociation.getDeposit();

        let priceUnitValue = unitValue;
        if (price) {
            const priceUnit = price.getUnit();
            const resolvedPriceUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(1, priceUnit), product.getPackagingsAndMappings().getMappings()).getUnit();
            priceUnitValue = (resolvedPriceUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(resolvedPriceUnit) : resolvedPriceUnit;
        }

        let depositUnitValue = priceUnitValue;
        if (deposit) {
            const depositUnit = deposit.getUnit();
            const resolvedDepositUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(1, depositUnit), product.getPackagingsAndMappings().getMappings()).getUnit();
            depositUnitValue = (resolvedDepositUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(resolvedDepositUnit) : resolvedDepositUnit;
        }

        productDistributorAssociationsByFormId.set(
            ProductDistributorAssociationFormId.generateUniqueProductDistributorAssociationFormId(),
            {
                validationInputDataByFieldName: {
                    unit: {
                        value: unitValue,
                        isValid: true,
                        errorMessage: '',
                    },
                    distributorId: {
                        value: productDistributorAssociation.getDistributorId().getValue(),
                        isValid: true,
                        errorMessage: '',
                    },
                    sku: {
                        value: productDistributorAssociation.getSku() || '',
                        isValid: true,
                        errorMessage: '',
                    },
                    priceAmount: {
                        value: price ? decimalToNumberString(price.getCost()) : '0.00',
                        isValid: true,
                        errorMessage: '',
                    },
                    priceUnit: {
                        value: priceUnitValue,
                        isValid: true,
                        errorMessage: '',
                    },
                    depositAmount: {
                        value: deposit ? decimalToNumberString(deposit.getCost()) : '',
                        isValid: true,
                        errorMessage: '',
                    },
                    depositUnit: {
                        value: depositUnitValue,
                        isValid: true,
                        errorMessage: '',
                    },
                },
                isValid: true,
                errorMessage: '',
                packagingIndex: null,
            }
        );
    });

    return {
        name: {
            value: product.getName(),
            ...defaultFormFieldValidation,
        },
        brand: {
            value: product.getBrand(),
            ...defaultFormFieldValidation,
        },
        distributorId: {
            value: distributorId ? distributorId.getValue() : '',
            ...defaultFormFieldValidation,
        },
        useCustomWeights: {
            value: (product.getWeightsByPackagingId().size === 0) ? '' : 'true',
            ...defaultFormFieldValidation,
        },
        packagingWeights,
        baseUnit: {
            value: (baseUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(baseUnit) : baseUnit,
            ...defaultFormFieldValidation,
        },
        preferredReportingUnit: {
            value: (preferredReportingUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(preferredReportingUnit) : preferredReportingUnit,
            ...defaultFormFieldValidation,
        },
        priceAmount: {
            value: product.getUnitPrice().getDollarValue().toString(),
            ...defaultFormFieldValidation,
        },
        priceUnit: {
            value: product.getUnitPrice().getUnit(),
            ...defaultFormFieldValidation,
        },
        categoryId: {
            value: categoryId ? categoryId.getValue() : '',
            ...defaultFormFieldValidation,
        },
        type: {
            value: product.getProductType(),
            ...defaultFormFieldValidation,
        },
        sku: {
            value: product.getSku(),
            ...defaultFormFieldValidation,
        },
        unitDeposit: {
            value: product.getDepositInDollars().toString(),
            ...defaultFormFieldValidation,
        },
        note: {
            value: product.getNote(),
            ...defaultFormFieldValidation,
        },
        glCode: {
            value: product.getGLCode(),
            ...defaultFormFieldValidation,
        },
        costAmount: {
            value: (resolvedProductCostAmount !== null) ? resolvedProductCostAmount.toString() : '',
            ...defaultFormFieldValidation,
        },
        costUnit: {
            value: resolvedCostUnit ? ((resolvedCostUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(resolvedCostUnit) : resolvedCostUnit) : '',
            ...defaultFormFieldValidation,
        },
        parCount: {
            value: resolvedPar ? resolvedPar.getQuantity().toString() : '',
            ...defaultFormFieldValidation,
        },
        parUnit: {
            value: parUnit ? ((parUnit instanceof PackagingId) ? formProductQuantityUnitValuesByPackagingId.getRequired(parUnit) : parUnit) : '',
            ...defaultFormFieldValidation,
        },
        conversions,
        productDistributorAssociations: productDistributorAssociationsByFormId,
    };
};

const getPackagingWeight = (packagingWeightFormValidationInputDataByFieldName : PackagingWeightFormValidationInputDataByFieldName) : PackagingWeight => {    
    let emptyWeight : QuantityInUnit<MassUnit> | null = null;
    let fullWeight : QuantityInUnit<MassUnit> | null = null;

    const emptyWeightQuantityValue = packagingWeightFormValidationInputDataByFieldName.emptyWeightQuantity.value;
    const emptyWeightUnitValue = packagingWeightFormValidationInputDataByFieldName.emptyWeightUnit.value;
    if (emptyWeightQuantityValue && emptyWeightUnitValue) {
        if (MassUnit.isMassUnitValue(emptyWeightUnitValue)) {
            emptyWeight = new QuantityInUnit(
                parseFloat(emptyWeightQuantityValue),
                MassUnit.getByMassUnitValue(emptyWeightUnitValue)
            );
        } else {
            throw new RuntimeException(`emptyWeightUnitValue: "${ emptyWeightUnitValue }" is unexpectedly not a massUnit`);
        }
    }

    const fullWeightQuantityValue = packagingWeightFormValidationInputDataByFieldName.fullWeightQuantity.value;
    const fullWeightUnitValue = packagingWeightFormValidationInputDataByFieldName.fullWeightUnit.value;
    if (fullWeightQuantityValue && fullWeightUnitValue) {
        if (MassUnit.isMassUnitValue(fullWeightUnitValue)) {
            fullWeight = new QuantityInUnit(
                parseFloat(fullWeightQuantityValue),
                MassUnit.getByMassUnitValue(fullWeightUnitValue)
            );
        } else {
            throw new RuntimeException(`fullWeightUnitValue: "${ fullWeightUnitValue }" is unexpectedly not a massUnit`);
        }
    }

    return new PackagingWeight(emptyWeight, fullWeight);
};

const getDistributorSortedOptionsAndLabelNames = (
    distributorsByDistributorId : StringValueMap<DistributorId, Distributor>,
    distributorIdsWithRelationship : StringValueSet<DistributorId>,
    includeNullVendorOption : boolean,
) : OptionsAndLabelNameTuples => {
    const myVendorIOptions : Array<IOption> = [];
    if (includeNullVendorOption) {
        myVendorIOptions.push({
            label: 'Other',
            value: '',
            icon: null,
        });
    }

    const otherVendorIOptions : Array<IOption> = [];

    distributorsByDistributorId.forEach((distributor, distributorId) => {
        distributorsByDistributorId.set(distributorId, distributor);

        const iOption = {
            value: distributorId.getValue(),
            label: distributor.getName(),
            icon: null,
        };

        if (distributorIdsWithRelationship.has(distributorId)) {
            myVendorIOptions.push(iOption);
        } else {
            otherVendorIOptions.push(iOption);
        }
    });

    myVendorIOptions.sort((a : IOption, b : IOption) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
    otherVendorIOptions.sort((a : IOption, b : IOption) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);

    const distributorSortedOptionsAndLabelNames : OptionsAndLabelNameTuples = [];
    if (myVendorIOptions.length > 0) {
        distributorSortedOptionsAndLabelNames.push(['My Vendors', myVendorIOptions]);
    }

    if (otherVendorIOptions.length > 0) {
        distributorSortedOptionsAndLabelNames.push(['Other Vendors', otherVendorIOptions]);
    }

    return distributorSortedOptionsAndLabelNames;
};

const makeCategoryOption = (categoryId : CategoryId, category : Category) : IOption => {
    return {
        value: categoryId.getValue(),
        label: CategoryUtils.createCategoryLabelName(category),
        icon: null,
    };
};

export const ProductFormUtils = {
    validateValueByFieldName,
    validatePackagingWeightValueByFieldName,
    getWeightUnitOptions,
    getAllPackagingUnitIOptions,
    getBaseUnitIOptions,
    getUnitsWithConversionForms,
    getPriceUnitIOptions,
    validateProductDistributorAssociationValueByFieldName,
    getPackagingWeightFormPackagingIdIOptions,
    getValidationInputDataByFieldName,
    getProductAndDistributorIdAndPar,
    getDistributorSortedOptionsAndLabelNames,
    getPackagingFormDataIndexByFormProductQuantityUnitValue,
    getPackagingWeight,
    getProductDistributorAndParForEditOrCreate,
    makeCategoryOption,
};
