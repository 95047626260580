import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { IInvoiceUploadService } from 'api/Ordering/interfaces/IInvoiceUploadService';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { InvoiceUpload } from 'api/Ordering/model/InvoiceUpload';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { OrderingJSONToObjectSerializer } from 'api/Ordering/serializer/OrderingJSONToObjectSerializer';

import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { Observer } from 'shared/utils/observer';

export class InvoiceUploadServiceImpl implements IInvoiceUploadService {

    constructor (
        private readonly orderingJSONToObjectSerializer : OrderingJSONToObjectSerializer
    ) { }

    public getInvoiceUploadsByDeliveryId(
        locationId : LocationId,
        deliveryIds : StringValueSet<DeliveryId>,
    ) : Promise<StringValueMap<DeliveryId, StringValueMap<InvoiceUploadId, InvoiceUpload>>> {
        const deliveryIdValues = Array.from(deliveryIds.values()).map((deliveryId : DeliveryId) => {
            return deliveryId.getValue();
        });

        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:invoice_upload'), deliveryIdValues, queryParameters)
        .then((response) => {
            return this.orderingJSONToObjectSerializer.getInvoiceUploadsByDeliveryId(response);
        });
    }

    public getUnreviewedInvoiceUploadsById(
        locationId : LocationId,
    ) : Promise<StringValueMap<InvoiceUploadId, InvoiceUpload>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:invoice_upload'), queryParameters)
        .then((response) => {
            return this.orderingJSONToObjectSerializer.getInvoiceUploadsById(response);
        });
    }

    public createInvoiceUpload (
        locationId : LocationId,
        invoiceUploadFile : File,
        deliveryId : DeliveryId | null,
    ) : Promise<{invoiceUploadId : InvoiceUploadId, invoiceUpload : InvoiceUpload}> {
        const formData = new FormData();
        formData.append('invoice', invoiceUploadFile);

        const queryParameters = {
            retailer_id: locationId.getValue(),
            order_id: deliveryId ? deliveryId.getValue() : ''
        };

        return AjaxUtils.ajaxPostForm(urlWithoutRetailerId('api:invoice_upload'), formData, queryParameters)
        .then((invoiceUploadResponse) => {
            Observer.observeAction('invoice_uploaded', {});
            return Promise.resolve({
                invoiceUploadId: new InvoiceUploadId(invoiceUploadResponse.invoice_upload_id),
                invoiceUpload: this.orderingJSONToObjectSerializer.getInvoiceUpload(invoiceUploadResponse.invoice_upload)
            });
        });
    }

    public setInvoiceUploadName(
        locationId : LocationId,
        invoiceUploadId : InvoiceUploadId,
        name : string,
    ) : Promise<void> {
        const queryParameters = {
            invoice_id: invoiceUploadId.getValue(),
            new_name: name,
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:invoice_upload'), null, queryParameters, 'text');
    }

    public setInvoiceUploadDeliveryId(
        locationId : LocationId,
        invoiceUploadId : InvoiceUploadId,
        deliveryId : DeliveryId | null,
    ) : Promise<void> {
        const queryParameters = {
            invoice_id: invoiceUploadId.getValue(),
            new_order_id: deliveryId ? deliveryId.getValue() : '',
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:invoice_upload'), null, queryParameters, 'text');
    }

    public deleteInvoiceUpload(
        locationId : LocationId,
        invoiceUploadId : InvoiceUploadId
    ) : Promise<void> {
        const queryParameters = {
            invoice_id: invoiceUploadId.getValue(),
        };

        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:invoice_upload'), queryParameters);
    }

    public submitInvoiceUploadsForProcessing(
        locationId : LocationId,
        invoiceUploadIds : Array<InvoiceUploadId>,
        mergeFiles : boolean
    ) : Promise<void> {
        const queryParameters = {
            invoice_ids: invoiceUploadIds.map((invoiceUploadId) => invoiceUploadId.getValue()).join(","),
            merge_files: mergeFiles
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:invoice_upload'), null, queryParameters, 'text');
    }

    public setInvoiceProcessingResultIsConfirmed(
        locationId : LocationId,
        invoiceUploadId : InvoiceUploadId,
        isConfirmed : boolean,
    ) : Promise<void> {
        const queryParameters = {
            invoice_id: invoiceUploadId.getValue(),
            is_confirmed: isConfirmed,
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:invoice_upload'), null, queryParameters);
    }

    public detachInvoiceUploadsFromOrder(
        deliveryId : DeliveryId
    ) : Promise<void> {
        const queryParameters = {
            delivery_id: deliveryId.getValue()
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:detach_invoice_upload'), null, queryParameters, 'text');
    }
}
