import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Delivery } from 'api/Ordering/model/Delivery';

import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { InvoiceProcessingData } from 'api/Ordering/model/InvoiceProcessingData';
import { InvoiceProcessingStatus } from 'api/Ordering/model/InvoiceProcessingStatus';
import { InvoiceStatus } from 'api/Ordering/model/InvoiceStatus';
import { InvoiceUpload } from 'api/Ordering/model/InvoiceUpload';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import moment from 'moment-timezone';
import { SUPPORTED_FILE_TYPES } from 'shared/components/FileViewer/utils/FileViewerUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const getInvoiceProcessingStatus = (invoiceUpload : InvoiceUpload) : InvoiceProcessingStatus => {
    const invoiceProcessingData = invoiceUpload.getInvoiceProcessingData();

    if (invoiceProcessingData === null) {
        return InvoiceProcessingStatus.NONE;
    }

    if (invoiceProcessingData.getReviewTime()) {
        return InvoiceProcessingStatus.CONFIRMED;
    }

    if (invoiceProcessingData.getResultTime()) {
        return InvoiceProcessingStatus.READY_FOR_REVIEW;
    }

    return InvoiceProcessingStatus.PROCESSING;
};

const getDisplayStringForSplitInvoiceProcessingStatus = (invoiceProcessingData : InvoiceProcessingData) : string | null => {
    const processedInvoiceUploadIdsByChildInvoiceExternalId = invoiceProcessingData.getProcessedInvoiceUploadIdsByChildInvoiceExternalId();
    const childInvoiceExternalIds = Object.keys(processedInvoiceUploadIdsByChildInvoiceExternalId);
    const totalCount = childInvoiceExternalIds.length;
    let processingCount = 0;
    childInvoiceExternalIds.forEach((externalInvoiceId: string) => {
        if (processedInvoiceUploadIdsByChildInvoiceExternalId[externalInvoiceId] === null) {
            processingCount += 1;
        }
    });

    if (totalCount > 1) {
        return ` (${ processingCount } out of ${ totalCount })`;
    }

    return null;
};

const getInvoiceStatus = (invoiceUpload : InvoiceUpload) : InvoiceStatus => {
    const invoiceProcessingData = invoiceUpload.getInvoiceProcessingData();

    if (invoiceProcessingData === null) {
        return InvoiceStatus.UPLOADED;
    }

    if (invoiceProcessingData.getReviewTime()) {
        return InvoiceStatus.APPROVED;
    }

    const result = invoiceProcessingData.getResult();
    if (result) {
        if (result.getErrorMessage()) {
            return InvoiceStatus.PROCESSING_FAILED;
        }
        if (invoiceUpload.getDeliveryId()) {
            return InvoiceStatus.NEEDS_REVIEW;
        } else {
            return InvoiceStatus.ATTACH_TO_DELIVERY;
        }
    }

    return InvoiceStatus.PROCESSING;
};

const getDisplayStringForInvoiceProcessingStatus = (invoiceProcessingStatus : InvoiceProcessingStatus) : string => {
    switch (invoiceProcessingStatus) {
        case InvoiceProcessingStatus.NONE:
            return '';
        case InvoiceProcessingStatus.PROCESSING:
            return 'Processing';
        case InvoiceProcessingStatus.READY_FOR_REVIEW:
            return 'Ready For Review';
        case InvoiceProcessingStatus.CONFIRMED:
            return 'Confirmed';
        default:
            throw new RuntimeException(`unexpected invoiceProcessingStatus: ${ invoiceProcessingStatus }`);
    }
};

const getInvoiceUploadWithUpdatedDeliveryId = (invoiceUpload : InvoiceUpload, deliveryId : DeliveryId | null) : InvoiceUpload => {
    return new InvoiceUpload(
        invoiceUpload.getName(),
        invoiceUpload.getUploadUrl(),
        invoiceUpload.getUploadTime(),
        invoiceUpload.getFileExtension(),
        deliveryId,
        invoiceUpload.getInvoiceProcessingData()
    );
};

const getInvoiceUploadWithUpdatedProcessingRequest = (invoiceUpload : InvoiceUpload, requestUserId : UserAccountId) : InvoiceUpload => {
    return new InvoiceUpload(
        invoiceUpload.getName(),
        invoiceUpload.getUploadUrl(),
        invoiceUpload.getUploadTime(),
        invoiceUpload.getFileExtension(),
        invoiceUpload.getDeliveryId(),
        new InvoiceProcessingData(requestUserId, moment().utc(), {}, null, null, null, null)
    );
};

const getInvoiceUploadWithUpdatedProcessingReview = (invoiceUpload : InvoiceUpload, reviewUserId : UserAccountId) : InvoiceUpload => {
    const invoiceProcessingData = invoiceUpload.getInvoiceProcessingData();
    if (!invoiceProcessingData) {
        throw new RuntimeException('unexpected');
    }

    return new InvoiceUpload(
        invoiceUpload.getName(),
        invoiceUpload.getUploadUrl(),
        invoiceUpload.getUploadTime(),
        invoiceUpload.getFileExtension(),
        invoiceUpload.getDeliveryId(),
        new InvoiceProcessingData(
            invoiceProcessingData.getRequestUserId(),
            invoiceProcessingData.getRequestTime(),
            invoiceProcessingData.getProcessedInvoiceUploadIdsByChildInvoiceExternalId(),
            invoiceProcessingData.getResult(),
            invoiceProcessingData.getResultTime(),
            reviewUserId,
            moment().utc(),
        )
    );
};

const getCountByInvoiceProcessingStatus = (invoiceUploadsById : StringValueMap<InvoiceUploadId, InvoiceUpload>) : {[key in InvoiceProcessingStatus] : number} => {
    const countByStatus : {[key in InvoiceProcessingStatus] : number} = {
        NONE: 0,
        PROCESSING: 0,
        READY_FOR_REVIEW: 0,
        CONFIRMED: 0,
    };
    invoiceUploadsById.forEach((invoiceUpload, invoiceUploadId) => {
        const invoiceProcessingStatus = InvoiceUploadUtils.getInvoiceProcessingStatus(invoiceUpload);
        countByStatus[invoiceProcessingStatus] += 1;
    });

    return countByStatus;
};

const getInvoiceUploadWithNewName = (invoiceUpload : InvoiceUpload, newInvoiceUploadName : string) : InvoiceUpload => {
    return new InvoiceUpload(
        newInvoiceUploadName,
        invoiceUpload.getUploadUrl(),
        invoiceUpload.getUploadTime(),
        invoiceUpload.getFileExtension(),
        invoiceUpload.getDeliveryId(),
        invoiceUpload.getInvoiceProcessingData(),
    );
};

const isInvoiceUploadSupportedByViewer = (invoiceUpload : InvoiceUpload) => {
    return SUPPORTED_FILE_TYPES.indexOf(invoiceUpload.getFileExtension()) >= 0;
};

const isDuplicateInvoiceNumberForDelivery = (
    deliveryId : DeliveryId | null,
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
    distributorId : DistributorId | null,
    invoiceNumber : string | null
) : boolean => {
    let hasDuplicateInvoiceId = false;

    if (invoiceNumber === null || invoiceNumber.trim().length === 0) {
        return false;
    }

    if (distributorId !== null) {
        deliveriesById.forEach((deliveryToCheck, deliveryIdToCheck) => {
            if (!hasDuplicateInvoiceId) {
                // Check if the invoice number is the same as another recent delivery from the same distributor, ignoring 'Other' distributors
                hasDuplicateInvoiceId =
                    (deliveryId === null || !deliveryId.equals(deliveryIdToCheck)) &&
                    invoiceNumber !== null &&
                    invoiceNumber === deliveryToCheck.getInvoiceNumber() &&
                    distributorId !== null &&
                    distributorId.equals(deliveryToCheck.getDistributorId());
            }
        });
    }
    return hasDuplicateInvoiceId;
};

// translate common synonyms of unit values to valid BevSpot unit todo: handle more cleanly in multi-vendor update
const resolveUnitSynonym = (value : string) : string => {
    switch (value) {
        case 'cs':
            return 'case';
        default:
            return value;
    }
};

export const InvoiceUploadUtils = {
    getInvoiceProcessingStatus,
    getDisplayStringForSplitInvoiceProcessingStatus,
    getInvoiceStatus,
    getDisplayStringForInvoiceProcessingStatus,
    getInvoiceUploadWithUpdatedDeliveryId,
    getInvoiceUploadWithUpdatedProcessingRequest,
    getInvoiceUploadWithUpdatedProcessingReview,
    getInvoiceUploadWithNewName,
    getCountByInvoiceProcessingStatus,
    isInvoiceUploadSupportedByViewer,
    isDuplicateInvoiceNumberForDelivery,
    resolveUnitSynonym,
};
