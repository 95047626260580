import { Cart } from 'api/Cart/model/Cart';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { CartItem } from 'api/Cart/model/CartItem';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { productUtils } from 'api/Product/utils/productUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';

import { CartDistributorInfo } from '../model/CartDistributorInfo';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { decimalUtils } from 'shared/utils/decimalUtils';

const getProductIdsInCart = (cart : Cart) : StringValueSet<ProductId> => {
    const productIds = new StringValueSet<ProductId>();

    cart.getCartItems().forEach((cartItem) => {
        productIds.add(cartItem.getProductId());
    });

    return productIds;
};

// TODO Cheezy this should no longer be used
const getCartItemsByProductId = (cart : Cart) : StringValueMap<ProductId, CartItem> => {
    const cartItemsByProductId = new StringValueMap<ProductId, CartItem>();

    cart.getCartItems().forEach((cartItem) => {
        const productId = cartItem.getProductId();
        cartItemsByProductId.set(productId, cartItem);
    });

    return cartItemsByProductId;
};

// TODO Cheezy can probably cache to make this more efficient
const getCartItemsForProductId = (cart : Cart, productId : ProductId) : Array<CartItem> => {
    const cartItems : Array<CartItem> = [];

    cart.getCartItems().forEach((cartItem) => {
        if (productId.equals(cartItem.getProductId())) {
            cartItems.push(cartItem);
        }
    });

    return cartItems;
};

const getCartItemsByProductDistributorAssociationIdentifier = (cart : Cart) : StringValueMap<ProductDistributorAssociationIdentifier, CartItem> => {
    const cartItemsByProductDistributorAssociationIdentifier = new StringValueMap<ProductDistributorAssociationIdentifier, CartItem>();

    cart.getCartItems().forEach((cartItem) => {
        const identifier = cartItem.getProductDistributorAssociationIdentifier();
        cartItemsByProductDistributorAssociationIdentifier.set(identifier, cartItem);
    });

    return cartItemsByProductDistributorAssociationIdentifier;
};

const getCartItemsByDistributorId = (cart : Cart, distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>) : StringValueMap<DistributorId | null, Array<CartItem>> => {
    const hasMultiVendorFeature = window.GLOBAL_FEATURE_ACCESS.multi_vendor;

    const cartItemsByDistributorId = new StringValueMap<DistributorId | null, Array<CartItem>>();

    cart.getCartItems().forEach((cartItem) => {
        const productId = cartItem.getProductId();
        let distributorId : DistributorId | null;

        if (hasMultiVendorFeature) {
            distributorId = cartItem.getDistributorId();
        } else {
            distributorId = distributorIdsByProductId.getRequired(productId);
        }

        const distributorCartItems = cartItemsByDistributorId.get(distributorId) || [];
        distributorCartItems.push(cartItem);

        cartItemsByDistributorId.set(distributorId, distributorCartItems);
    });

    return cartItemsByDistributorId;
};

// TODO Cheezy more efficient way to get data
// TODO Cheezy how to deal with need to resolve
const getCartItemForProductAndDistributor = (cart : Cart, productId : ProductId, distributorId : DistributorId | null) : CartItem | null => {
    let relevantCartItem : CartItem | null = null;

    cart.getCartItems().forEach((cartItem) => {
        if (cartItem.getProductId().equals(productId) && ((distributorId === cartItem.getDistributorId()) || ((distributorId !== null) && (distributorId.equals(cartItem.getDistributorId()))))) {
            relevantCartItem = cartItem;
        }
    });

    return relevantCartItem;
};

const getCartTotal = (cart : Cart, includeSavedForLater : boolean, productsById : StringValueMap<ProductId, Product>, distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>, productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>) : number => {
    const hasMultiVendorFeature = window.GLOBAL_FEATURE_ACCESS.multi_vendor;

    let cartTotal = 0;
    cart.getCartItems().forEach((cartItem) => {
        const productId = cartItem.getProductId();
        const productDistributorAssociations = hasMultiVendorFeature ? productDistributorAssociationsByProductId.getRequired(productId) : new StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>();

        let shouldInclude = true;
        if (!includeSavedForLater) {
            let distributorId;
            if (hasMultiVendorFeature) {
                distributorId = cartItem.getDistributorId();
            } else {
                distributorId = distributorIdsByProductId.getRequired(productId);
            }

            const cartDistributorInfo = cart.getCartDistributorInfoById().get(distributorId) || null;
            const isSavedForLater = cartDistributorInfo ? cartDistributorInfo.getIsSavedForLater() : false;
            shouldInclude = !isSavedForLater;
        }

        if (shouldInclude) {
            cartTotal += getCartItemTotal(cartItem, productsById.getRequired(productId), productDistributorAssociations, hasMultiVendorFeature);
        }
    });

    return cartTotal;
};

const getCartDepositTotal = (cart : Cart, includeSavedForLater : boolean, productsById : StringValueMap<ProductId, Product>, distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>, productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>) : number => {
    const hasMultiVendorFeature = window.GLOBAL_FEATURE_ACCESS.multi_vendor;
    let cartDepositTotal = 0;

    cart.getCartItems().forEach((cartItem) => {
        const productId = cartItem.getProductId();
        const productDistributorAssociations = hasMultiVendorFeature ? productDistributorAssociationsByProductId.getRequired(productId) : new StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>();

        let shouldInclude = true;
        if (!includeSavedForLater) {
            let distributorId;
            if (hasMultiVendorFeature) {
                distributorId = cartItem.getDistributorId();
            } else {
                distributorId = distributorIdsByProductId.getRequired(productId);
            }

            const cartDistributorInfo = cart.getCartDistributorInfoById().get(distributorId) || null;
            const isSavedForLater = cartDistributorInfo ? cartDistributorInfo.getIsSavedForLater() : false;
            shouldInclude = !isSavedForLater;
        }

        if (shouldInclude) {
            cartDepositTotal += getCartItemDepositTotal(cartItem, productsById.getRequired(productId), productDistributorAssociations, hasMultiVendorFeature);
        }
    });

    return cartDepositTotal;
};

const getCartItemTotal = (cartItem : CartItem, product : Product, productDistributorAssociations : StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>, hasMultiVendorFeature : boolean) : number => {
    const resolvedCartItemQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(cartItem.getQuantityInUnits(), cartItem.getProductQuantityUnit()), product.getPackagingsAndMappings().getMappings());
    const quantityInUnits = resolvedCartItemQuantityInUnit.getQuantity();

    let pricePerUnit : number;
    if (hasMultiVendorFeature) {
        const cartItemIdentifier = new ProductDistributorAssociationIdentifier(cartItem.getProductId(), resolvedCartItemQuantityInUnit.getUnit(), cartItem.getDistributorId());
        let productDistributorAssociation = productDistributorAssociations.get(cartItemIdentifier);

        if (typeof productDistributorAssociation === 'undefined') {
            productDistributorAssociations.forEach((association, identifier) => {
                const associationDistributorId = association.getDistributorId();
                const resolvedAssociationUnit = PackagingUtils.resolveProductQuantityUnit(
                    new QuantityInUnit(1, association.getProductQuantityUnit()),
                    product.getPackagingsAndMappings().getMappings()
                ).getUnit();

                if (PackagingUtils.productQuantityUnitsAreEqual(resolvedAssociationUnit, resolvedCartItemQuantityInUnit.getUnit()) && 
                    ((associationDistributorId === cartItem.getDistributorId()) || ((associationDistributorId !== null) && associationDistributorId.equals(cartItem.getDistributorId())))) {
                    productDistributorAssociation = association;
                }
            });
        }

        if (typeof productDistributorAssociation === 'undefined') {
            pricePerUnit = 0;
        } else {
            const productDistributorAssociationPrice = productDistributorAssociation.getPrice();
            pricePerUnit = productDistributorAssociationPrice ? decimalUtils.decimalToNumber(productDistributorAssociationPrice.getCost()) : 0;
        }
    } else {
        pricePerUnit = productUtils.convertPriceToProductQuantityUnit(product, resolvedCartItemQuantityInUnit.getUnit());
    }

    return quantityInUnits * pricePerUnit;
};

const getCartItemDepositTotal = (cartItem : CartItem, product : Product, productDistributorAssociations : StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>, hasMultiVendorFeature : boolean) : number => {
    const resolvedCartItemQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(cartItem.getQuantityInUnits(), cartItem.getProductQuantityUnit()), product.getPackagingsAndMappings().getMappings());
    const quantityInUnits = resolvedCartItemQuantityInUnit.getQuantity();

    let depositPerUnit : number;
    if (hasMultiVendorFeature) {
        const cartItemIdentifier = new ProductDistributorAssociationIdentifier(cartItem.getProductId(), resolvedCartItemQuantityInUnit.getUnit(), cartItem.getDistributorId());
        let productDistributorAssociation = productDistributorAssociations.get(cartItemIdentifier);

        if (typeof productDistributorAssociation === 'undefined') {
            productDistributorAssociations.forEach((association, identifier) => {
                const associationDistributorId = association.getDistributorId();
                const resolvedAssociationUnit = PackagingUtils.resolveProductQuantityUnit(
                    new QuantityInUnit(1, association.getProductQuantityUnit()),
                    product.getPackagingsAndMappings().getMappings()
                ).getUnit();

                if (PackagingUtils.productQuantityUnitsAreEqual(resolvedAssociationUnit, resolvedCartItemQuantityInUnit.getUnit()) && 
                    ((associationDistributorId === cartItem.getDistributorId()) || ((associationDistributorId !== null) && associationDistributorId.equals(cartItem.getDistributorId())))) {
                    productDistributorAssociation = association;
                }
            });
        }

        if (typeof productDistributorAssociation === 'undefined') {
            depositPerUnit = 0;
        } else {
            const productDistributorAssociationDeposit = productDistributorAssociation.getDeposit();
            depositPerUnit = productDistributorAssociationDeposit ? decimalUtils.decimalToNumber(productDistributorAssociationDeposit.getCost()) : 0;
        }
    } else {
        depositPerUnit = productUtils.convertDepositToProductQuantityUnit(product, resolvedCartItemQuantityInUnit.getUnit());
    }

    return quantityInUnits * depositPerUnit;
};

const getCartWithUpdatedRetailerNote = (cart : Cart, distributorId : DistributorId, note : string) : Cart => {
    const cartDistributorInfo = cart.getCartDistributorInfoById().get(distributorId);

    let newCartDistributorInfo : CartDistributorInfo;
    if (cartDistributorInfo) {
        newCartDistributorInfo = new CartDistributorInfo(cartDistributorInfo.getIsSavedForLater(), note, cartDistributorInfo.getIncludedDistributorReps(), cartDistributorInfo.getPurchaseOrderNumber());
    } else {
        newCartDistributorInfo = new CartDistributorInfo(false, note, new StringValueSet(), '');
    }

    const newCartDistributorInfoById = new StringValueMap(cart.getCartDistributorInfoById());
    newCartDistributorInfoById.set(distributorId, newCartDistributorInfo);

    return new Cart(
        cart.getCartItems(),
        newCartDistributorInfoById,
        ''
    );
};

const getCartWithUpdatedPurchaseOrderNumber = (cart : Cart, distributorId : DistributorId, purchaseOrderNumber : string) : Cart => {
    const cartDistributorInfo = cart.getCartDistributorInfoById().get(distributorId);

    let newCartDistributorInfo : CartDistributorInfo;
    if (cartDistributorInfo) {
        newCartDistributorInfo = new CartDistributorInfo(cartDistributorInfo.getIsSavedForLater(), cartDistributorInfo.getRetailerNote(), cartDistributorInfo.getIncludedDistributorReps(), purchaseOrderNumber);
    } else {
        newCartDistributorInfo = new CartDistributorInfo(false, '', new StringValueSet(), purchaseOrderNumber);
    }

    const newCartDistributorInfoById = new StringValueMap(cart.getCartDistributorInfoById());
    newCartDistributorInfoById.set(distributorId, newCartDistributorInfo);

    return new Cart(
        cart.getCartItems(),
        newCartDistributorInfoById,
        ''
    );
};

const getCartWithUpdatedIsSavedForLater = (cart : Cart, distributorId : DistributorId | null, isSavedForLater : boolean) : Cart => {
    const cartDistributorInfo = cart.getCartDistributorInfoById().get(distributorId);

    let newCartDistributorInfo : CartDistributorInfo;
    if (cartDistributorInfo) {
        newCartDistributorInfo = new CartDistributorInfo(isSavedForLater, cartDistributorInfo.getRetailerNote(), cartDistributorInfo.getIncludedDistributorReps(), cartDistributorInfo.getPurchaseOrderNumber());
    } else {
        newCartDistributorInfo = new CartDistributorInfo(isSavedForLater, '', new StringValueSet(), '');
    }

    const newCartDistributorInfoById = new StringValueMap(cart.getCartDistributorInfoById());
    newCartDistributorInfoById.set(distributorId, newCartDistributorInfo);

    return new Cart(
        cart.getCartItems(),
        newCartDistributorInfoById,
        ''
    );
};

export const CartUtils = {
    getProductIdsInCart,
    getCartItemsByProductId,
    getCartItemsForProductId,
    getCartItemsByProductDistributorAssociationIdentifier,
    getCartItemsByDistributorId,
    getCartItemForProductAndDistributor,
    getCartItemTotal,
    getCartTotal,
    getCartDepositTotal,
    getCartWithUpdatedRetailerNote,
    getCartWithUpdatedPurchaseOrderNumber,
    getCartWithUpdatedIsSavedForLater,
};
