import { IntegrationType, PosIntegrationType } from 'shared/models/IntegrationStatus';

// should be kept in sync with com.bevspot.integration.omnivore.model.OmnivorePosType
// https://panel.omnivore.io/docs/guides/features/compatibility#feature-support
// note: this should NOT duplicate IntegrationType i.e. it's invalid to have a POS that's handled by both Omnivore and direct integration
export type OmnivoreSupportedPosType = 'aloha' | 'brink' | 'dinerware' | 'micros' | 'positouch' | 'squirrel' | 'xpient' | 'lavu' | 'maitreD';
export const OMNIVORE_SUPPORTED_POS_NAMES : { [key in OmnivoreSupportedPosType] : string} = {
    aloha: 'Aloha',
    brink: 'Brink',
    dinerware: 'Dinerware',
    lavu: 'Lavu',
    maitreD: 'Maitre\'D',
    micros: 'Micros',
    positouch: 'POSitouch',
    xpient: 'XPIENT',
    squirrel: 'Squirrel'
};
export const ALL_INTEGRATION_NAMES : {[key in IntegrationType | OmnivoreSupportedPosType] : string} = {
    ...OMNIVORE_SUPPORTED_POS_NAMES,
    breadcrumb: 'Upserve POS',
    toast: 'Toast POS',
    lightspeed: 'Lightspeed POS',
    square: 'Square POS',
    omnivore: 'Omnivore',   // not displayed
    clover: 'Clover POS',
    kSeries: 'Lightspeed (K Series) POS',
    spotOn: 'SpotOn POS',
    heartland: 'Heartland POS',

    fintech: 'Fintech',
    usfoods: 'US Foods',
    sysco: 'Sysco'
};

export const OMNIVORE_SUPPORTED_POS_TYPES : Array<OmnivoreSupportedPosType> = Object.keys(OMNIVORE_SUPPORTED_POS_NAMES) as Array<OmnivoreSupportedPosType>;

export function isOmnivoreSupportedPos(v : string) : v is OmnivoreSupportedPosType {
    return (OMNIVORE_SUPPORTED_POS_TYPES as Array<string>).indexOf(v) >= 0;
}

export const ALL_POS_INTEGRATION_TYPES : Array<PosIntegrationType | OmnivoreSupportedPosType> =
    (['square', 'toast', 'lightspeed', 'breadcrumb', 'clover', 'kSeries', 'spotOn', 'heartland'].concat(OMNIVORE_SUPPORTED_POS_TYPES) as Array<PosIntegrationType | OmnivoreSupportedPosType>);   // have to cast because of .concat
