import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { ValidationInput } from 'shared/components/ValidationInput';

// TODO move to InventoryTransfer app? this isn't shared...
interface IUnitQuantityWithEditableQuantityProps {
    product : Product;
    quantity : string | null;
    unit : ProductQuantityUnit;
    isValid : boolean;
    errorMessage : string | null;
    setQuantity(quantity : string) : void;
    validateQuantity(quantity : string) : void;
}

export class UnitQuantityWithEditableQuantity extends React.Component<IUnitQuantityWithEditableQuantityProps, object> {

    constructor(props : IUnitQuantityWithEditableQuantityProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    public render() {
        const {
          quantity,
          unit,
          isValid,
          errorMessage,
          product,
        } = this.props;

        const invalidMessageElement = !isValid && (
            <span className="invalid">{ errorMessage }</span>
        );

        return (
            <div className="unit-quantity-with-editable-quantity">
                { invalidMessageElement }
                <div className="cell col-xs-6 text-right">
                    <ValidationInput
                        type="text"
                        label={ null }
                        hintText={ null }
                        value={ (quantity !== null) ? quantity : '' }
                        autoFocus={ false }
                        autoComplete={ null }
                        isValid={ isValid }
                        errorMessage=""
                        inputClassName=""
                        handleBlur={ this.handleBlur }
                        handleFocus={ null }
                        handleChange={ this.handleChange }
                        handleEnterClick={ this.returnUndefined }
                        isDisabled={ false }
                    />
                </div>
                <div className="cell unit-cell col-xs-6 text-left">
                    <span>{ PackagingUtils.getDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), unit, false) }</span>
                </div>
            </div>
        );
    }

    private returnUndefined = () => undefined;

    private handleChange(event : React.ChangeEvent<HTMLInputElement>) : void {
        const input = event.currentTarget;
        const value = input.value;
        this.props.setQuantity(value);
    }

    private handleBlur(event : React.FocusEvent<HTMLInputElement>) : void {
        const input = event.currentTarget;
        const value = input.value;
        this.props.validateQuantity(value);
    }
}
