import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

import { UnitQuantityWithEditableQuantity } from 'shared/components/UnitQuantityWithEditableQuantity';
import { BrandAndName } from 'shared/components/Product/BrandAndName';

interface ITransferListRowProps {
    product : Product;
    unit : ProductQuantityUnit;
    quantity : string | null;
    isValid : boolean;
    errorMessage : string | null;
    setQuantity(quantity : string) : void;
    validateQuantity(quantity : string) : void;
    removeRow() : void;
}
export class TransferListRow extends React.Component<ITransferListRowProps, object> {

    public render() {
        const {
            product,
            unit,
            quantity,
            isValid,
            errorMessage,
            setQuantity,
            validateQuantity,
            removeRow,
        } = this.props;

        return (
            <div className="transfer-list-row ctable-row">
                <div className="col-row">
                    <div className="cell col-xs-4">
                        <UnitQuantityWithEditableQuantity
                            quantity={ quantity }
                            unit={ unit }
                            validateQuantity={ validateQuantity }
                            errorMessage={ errorMessage }
                            isValid={ isValid }
                            setQuantity={ setQuantity }
                            product={ product }
                        />
                    </div>
                    <div className="cell cell-ptb-md col-xs-7">
                        <BrandAndName 
                            brand={ product.getBrand() }
                            name={ product.getName() }
                            category={ product.getProductCategoryId() }
                            subcategory={ product.getProductType() }
                        />
                    </div>
                    <div className="cell col-xs-1">
                        <button
                            className="transfer-list-row-delete"
                            onClick={ removeRow }
                        >
                            <span className="bevicon bevico-remove-inverse"/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
