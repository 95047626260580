import { CartItem } from 'api/Cart/model/CartItem';
import { DistributorDependentObjectSet } from 'api/Distributor/model/DistributorDependentObjectSet';
import { LocationId } from 'api/Location/model/LocationId';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { ProductId } from 'api/Product/model/ProductId';
import DistributorModel from 'gen-thrift/distributor_Model_types';
import CartModel from 'gen-thrift/cart_Model_types';
import DistributorDependencyModel from 'gen-thrift/distributor_dependency_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRegionId } from 'api/Distributor/model/DistributorRegionId';

import { ProductThriftToObjectSerializer} from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { ScopeThriftToObjectSerializer } from 'api/Location/serializer/ScopeThriftToObjectSerializer';

export class DistributorThriftToObjectSerializer {
    constructor(
        private readonly scopeSerializer : ScopeThriftToObjectSerializer,
        private readonly productThriftToObjectSerializer : ProductThriftToObjectSerializer
    ) {}

    ////////////////////////////////////
    // CORE MODELS
    ////////////////////////////////////

    public getDistributorId(
        distributorId : DistributorModel.DistributorId
    ) : DistributorId {
        return new DistributorId(distributorId.value);
    }

    public getDistributor(
        distributor : DistributorModel.Distributor
    ) : Distributor {
        return new Distributor(
            this.scopeSerializer.getScope(distributor.owner),
            distributor.name,
            distributor.shortName,
        );
    }

    public getDistributorRegionId(
        regionId : DistributorModel.DistributorRegionId
    ) : DistributorRegionId {
        return new DistributorRegionId(regionId.value);
    }

    ////////////////////////////////////
    // MISC SERIALIZERS
    ////////////////////////////////////

    public getDistributorsById(
        distributorAndIds : Array<DistributorModel.DistributorIdAndDistributor>
    ) : StringValueMap<DistributorId, Distributor> {
        const distributorsById : StringValueMap<DistributorId, Distributor> = new StringValueMap();
        distributorAndIds.forEach((distributorAndId : DistributorModel.DistributorIdAndDistributor) => {
            distributorsById.set(
                this.getDistributorId(distributorAndId.distributorId),
                this.getDistributor(distributorAndId.distributor)
            );
        });

        return distributorsById;
    }

    public getRegionIdsByDistributorId(
        distributorAndRegionIds : Array<DistributorModel.DistributorIdAndRegionId>
    ) : StringValueMap<DistributorId, DistributorRegionId | null> {
        const regionIdsByDistributorId : StringValueMap<DistributorId, DistributorRegionId | null> = new StringValueMap();
        distributorAndRegionIds.forEach((regionIdAndDistributorId : DistributorModel.DistributorIdAndRegionId) => {
            regionIdsByDistributorId.set(
                this.getDistributorId(regionIdAndDistributorId.distributorId),
                regionIdAndDistributorId.regionId === null ? null : this.getDistributorRegionId(regionIdAndDistributorId.regionId)
            );
        });

        return regionIdsByDistributorId;
    }

    public getDistributorRegionIds(
        thriftRegionIds : Array<DistributorModel.DistributorRegionId>
    ) : StringValueSet<DistributorRegionId> {
        const distributorRegionIds : StringValueSet<DistributorRegionId> = new StringValueSet();
        thriftRegionIds.map((thriftRegionId : DistributorModel.DistributorRegionId) => {
            distributorRegionIds.add(this.getDistributorRegionId(thriftRegionId));
        });

        return distributorRegionIds;
    }

    public getDistributorDependentObjectSet(
        distributorDependentObjectSet : DistributorDependencyModel.DistributorDependentObjectSet
    ) : DistributorDependentObjectSet {
        const deliveryIds : Array<DeliveryId> = [];
        distributorDependentObjectSet.deliveryIds.forEach((deliveryId) => deliveryIds.push(new DeliveryId(deliveryId.value)));

        const productIds : Array<ProductId> = [];
        distributorDependentObjectSet.productIds.forEach((productId) => productIds.push(new ProductId(productId.value)));

        const retailerIds : Array<LocationId> = [];
        distributorDependentObjectSet.retailerIds.forEach((retailerId) => retailerIds.push(new LocationId(retailerId.value)));

        const cartIds : Array<LocationId> = [];
        distributorDependentObjectSet.cartIds.forEach((cartId) => cartIds.push(new LocationId(cartId.value)));

        const cartItemsByCartId : StringValueMap<LocationId, Array<CartItem>> = new StringValueMap<LocationId, Array<CartItem>>();
        distributorDependentObjectSet.cartItemsByCartId.forEach((cartIdAndCartItems) => {
            const locationId : LocationId = new LocationId(cartIdAndCartItems.cartId.value);
            const cartItems : Array<CartItem> = [];

            cartIdAndCartItems.cartItems.forEach((cartItem : CartModel.CartItem) => {
                const distributorId : DistributorId | null = cartItem.distributorId != null ? new DistributorId(cartItem.distributorId.value) : null;

                const productQuantityUnit = this.productThriftToObjectSerializer.getProductQuantityUnit(cartItem.productQuantityUnit);

                cartItems.push(new CartItem(
                    new ProductId(cartItem.productId.value),
                    distributorId,
                    productQuantityUnit,
                    cartItem.quantityInUnits
                ));
            });

            cartItemsByCartId.set(locationId, cartItems);
        });

        return new DistributorDependentObjectSet(
            deliveryIds,
            productIds,
            retailerIds,
            cartIds,
            cartItemsByCartId
        );
    }
}
