import { DistributorNameMappingServiceImpl } from 'api/Distributor/impl/DistributorNameMappingServiceImpl';
import { DistributorObjectToJSONSerializer } from 'api/Distributor/serializer/DistributorObjectToJSONSerializer';
import { VendorIntegrationServiceImpl } from 'api/Integration/impl/VendorIntegrationServiceImpl';
import { CategoryServiceImpl } from 'api/Product/impl/CategoryServiceImpl';
import { ExpectedInventoryServiceImpl } from 'api/InventoryCount/impl/ExpectedInventoryServiceImpl';
import { ExpectedInventoryDeserializer } from 'api/InventoryCount/serializer/ExpectedInventoryDeserializer';
import { ProductMergeServiceImpl } from 'api/Product/impl/ProductMergeServiceImpl';
import { IncidentBannerServiceImpl } from 'shared/components/IncidentBanner/impl/IncidentBannerServiceImpl';
import Thrift from 'thrift';

import AdminSalesItemService from 'gen-thrift/AdminSalesItemService';
import BreakageReportService from 'gen-thrift/BreakageReportService';
import ThriftDistributorService from 'gen-thrift/DistributorService';
import DrinkPriceToolService from 'gen-thrift/DrinkPriceToolService';
import IntegrationService from 'gen-thrift/IntegrationService';
import InterLocationProductMappingService from 'gen-thrift/InterLocationProductMappingService';
import InventoryCountService from 'gen-thrift/InventoryCountService';
import InventoryCurrentConfigurationService from 'gen-thrift/InventoryCurrentConfigurationService';
import InventoryTransferReportService from 'gen-thrift/InventoryTransferReportService';
import PrepEventService from 'gen-thrift/PrepEventService';
import ProductCostService from 'gen-thrift/ProductCostService';
import ProductDistributorService from 'gen-thrift/ProductDistributorService';
import ProductParService from 'gen-thrift/ProductParService';
import ThriftProductService from 'gen-thrift/ProductService';
import ThriftPublicContentViewEventService from 'gen-thrift/PublicContentViewEventService';
import SalesItemService from 'gen-thrift/SalesItemService';
import UnauthenticatedUserSessionService from 'gen-thrift/UnauthenticatedUserSessionService';
import UserAccountService from 'gen-thrift/UserAccountService';
import UserActionLogService from 'gen-thrift/UserActionLogService';
import UserEmailAddressVerificationService from 'gen-thrift/UserEmailAddressVerificationService';
import UserPasswordService from 'gen-thrift/UserPasswordService';
import UserSessionService from 'gen-thrift/UserSessionService';

import { AdminServiceImpl } from 'api/Admin/impl/AdminServiceImpl';
import { BillingAddOnServiceImpl } from 'api/Billing/impl/BillingAddOnServiceImpl';
import { DelinquentPaymentStatusService } from 'api/Billing/impl/DelinquentPaymentStatusService';
import { StripeCustomerServiceImpl } from 'api/Billing/impl/StripeCustomerServiceImpl';
import { BillingDataSerializer } from 'api/Billing/serializer/BillingDataSerializer';
import { BreakageServiceImpl } from 'api/Breakage/impl/BreakageServiceImpl';
import { BreakageObjectToThriftSerializer } from 'api/Breakage/serializer/BreakageObjectToThriftSerializer';
import { BreakageThriftToObjectSerializer } from 'api/Breakage/serializer/BreakageThriftToObjectSerializer';
import { CartServiceImpl } from 'api/Cart/impl/CartServiceImpl';
import { CartJSONToObjectSerializer } from 'api/Cart/serializer/CartJSONToObjectSerializer';
import { CartObjectToJSONSerializer } from 'api/Cart/serializer/CartObjectToJSONSerializer';
import { OfflineQueueManagerImpl } from 'api/Core/OfflineQueueManager/impl/OfflineQueueManagerImpl';
import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { DistributorService } from 'api/Distributor/impl/DistributorService';
import { DistributorObjectToThriftSerializer } from 'api/Distributor/serializer/DistributorObjectToThriftSerializer';
import { DistributorThriftToObjectSerializer } from 'api/Distributor/serializer/DistributorThriftToObjectSerializer';
import { POSIntegrationServiceImpl } from 'api/Integration/impl/POSIntegrationServiceImpl';
import { PlateIQIntegrationServiceImpl } from 'api/Integration/impl/PlateIQIntegrationServiceImpl';
import { ToastIntegrationServiceImpl } from 'api/Integration/impl/ToastIntegrationServiceImpl';
import { CurrentInventoryConfigurationServiceImpl } from 'api/InventoryCount/impl/CurrentInventoryConfigurationServiceImpl';
import { InventoryCountServiceImpl } from 'api/InventoryCount/impl/InventoryCountServiceImpl';
import { InventoryCountViewPreferencesServiceImpl } from 'api/InventoryCount/impl/InventoryCountViewPreferencesServiceImpl';
import { InventoryCountObjectToThriftSerializer } from 'api/InventoryCount/serializer/InventoryCountObjectToThriftSerializer';
import { InventoryCountThriftToObjectSerializer } from 'api/InventoryCount/serializer/InventoryCountThriftToObjectSerializer';
import { GettingStartedGuideService } from 'api/Location/impl/GettingStartedGuideService';
import { LocationProductServiceImpl } from 'api/Location/impl/LocationProductServiceImpl';
import { LocationServiceImpl } from 'api/Location/impl/LocationServiceImpl';
import { LocationJSONToObjectSerializer } from 'api/Location/serializer/LocationJSONToObjectSerializer';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { LocationThriftToObjectSerializer } from 'api/Location/serializer/LocationThriftToObjectSerializer';
import { ScopeObjectToThriftSerializer } from 'api/Location/serializer/ScopeObjectToThriftSerializer';
import { ScopeThriftToObjectSerializer } from 'api/Location/serializer/ScopeThriftToObjectSerializer';
import { WelcomeQuestionnaireResponseObjectToJsonSerializer } from 'api/Location/serializer/WelcomeQuestionnaireResponseObjectToJsonSerializer';
import { UserActionLogCommentService } from 'api/Messaging/impl/UserActionLogCommentService';
import { InventorySheetsServiceImpl } from 'api/Onboarding/impl/InventorySheetsServiceImpl';
import { ProductQuickAddServiceImpl } from 'api/Onboarding/impl/ProductQuickAddServiceImpl';
import { ProductUploadServiceImpl } from 'api/Onboarding/impl/ProductUploadServiceImpl';
import { RetailerPopulationFromInvoiceUploadServiceImpl } from 'api/Onboarding/impl/RetailerPopulationFromInvoiceUploadServiceImpl';
import { ProductQuickAddJSONToObjectSerializer } from 'api/Onboarding/serializer/ProductQuickAddJSONToObjectSerializer';
import { ProductUploadJSONToObjectSerializer } from 'api/Onboarding/serializer/ProductUploadJSONToObjectSerializer';
import { RetailerPopulationFromInvoiceUploadJSONToObjectSerializer } from 'api/Onboarding/serializer/RetailerPopulationFromInvoiceUploadJSONToObjectSerializer';
import { InvoiceUploadServiceImpl } from 'api/Ordering/impl/InvoiceUploadServiceImpl';
import { OrderingServiceImpl } from 'api/Ordering/impl/OrderingServiceImpl';
import { OrderingJSONToObjectSerializer } from 'api/Ordering/serializer/OrderingJSONToObjectSerializer';
import { OrderingObjectToJSONSerializer } from 'api/Ordering/serializer/OrderingObjectToJSONSerializer';
import { PrepEventServiceImpl } from 'api/PrepEvent/impl/PrepEventServiceImpl';
import { PrepEventObjectToThriftSerializer } from 'api/PrepEvent/serializer/PrepEventObjectToThriftSerializer';
import { PrepEventThriftToObjectSerializer } from 'api/PrepEvent/serializer/PrepEventThriftToObjectSerilaizer';
import { PresentationCacheDataService } from 'api/PresentationCache/impl/PresentationCacheDataService';
import { CachedProductServiceImpl } from 'api/Product/impl/CachedProductServiceImpl';
import { ProductCostServiceImpl } from 'api/Product/impl/ProductCostServiceImpl';
import { ProductDistributorAssociationServiceImpl } from 'api/Product/impl/ProductDistributorAssociationServiceImpl';
import { ProductDistributorServiceImpl } from 'api/Product/impl/ProductDistributorServiceImpl';
import { ProductServiceImpl } from 'api/Product/impl/ProductServiceImpl';
import { ProductDistributorAssociationObjectToThriftSerializer } from 'api/Product/serializer/ProductDistributorAssociationObjectToThriftSerializer';
import { ProductDistributorAssociationThriftToObjectSerializer } from 'api/Product/serializer/ProductDistributorAssociationThriftToObjectSerializer';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { ProductParServiceImpl } from 'api/ProductPar/impl/ProductParServiceImpl';
import { PublicContentViewEventServiceImpl } from 'api/PublicContent/impl/PublicContentViewEventServiceImpl';
import { PublicContentObjectToThriftSerializer } from 'api/PublicContent/serializer/PublicContentObjectToThriftSerializer';
import { PublicContentThriftToObjectSerializer } from 'api/PublicContent/serializer/PublicContentThriftToObjectSerializer';
import { CategoryLevelSalesReportConfigurationServiceImpl } from 'api/Reports/impl/CategoryLevelSalesReportConfigurationServiceImpl';
import { ItemLevelSalesReportConfigurationServiceImpl } from 'api/Reports/impl/ItemLevelSalesReportConfigurationServiceImpl';
import { PmixUploadServiceImpl } from 'api/Reports/impl/PmixUploadServiceImpl';
import { CategoryLevelSalesReportConfigurationSerializer } from 'api/Reports/serializer/CategoryLevelSalesReportConfigurationSerializer';
import { ItemLevelSalesReportConfigurationSerializer } from 'api/Reports/serializer/ItemLevelSalesReportConfigurationSerializer';
import { SalesItemServiceImpl } from 'api/SalesItem/impl/SalesItemServiceImpl';
import { SalesItemObjectToThriftSerializer } from 'api/SalesItem/serializer/SalesItemObjectToThriftSerializer';
import { SalesItemThriftToObjectSerializer } from 'api/SalesItem/serializer/SalesItemThriftToObjectSerializer';
import { ProductAndCatalogSearchService } from 'api/Search/impl/ProductAndCatalogSearchService';
import { CatalogJSONToObjectSerializer } from 'api/Search/serializer/CatalogJSONToObjectSerializer';
import { ProductMappingServiceImpl } from 'api/Transfer/impl/ProductMappingServiceImpl';
import { TransferServiceImpl } from 'api/Transfer/impl/TransferServiceImpl';
import { ProductMappingObjectToThriftSerializer } from 'api/Transfer/serializer/ProductMappingObjectToThriftSerializer';
import { ProductMappingThriftToObjectSerializer } from 'api/Transfer/serializer/ProductMappingThriftToObjectSerializer';
import { TransferObjectToThriftSerializer } from 'api/Transfer/serializer/TransferObjectToThriftSerializer';
import { TransferThriftToObjectSerializer } from 'api/Transfer/serializer/TransferThriftToObjectSerializer';
import { UsageDataServiceImpl } from 'api/UsageData/impl/UsageDataServiceImpl';
import { UsageDataSerializer } from 'api/UsageData/serializer/UsageDataSerializer';
import { UserAccountInfoServiceImpl } from 'api/UserAccount/impl/UserAccountInfoServiceImpl';
import { UserInviteServiceImpl } from 'api/UserAccount/impl/UserInviteServiceImpl';
import { InviteInfoJSONToObjectSerializer } from 'api/UserAccount/serializer/InviteInfoJSONToObjectSerializer';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

import { DrinkPriceToolManager } from 'apps/DrinkPriceTool/api/DrinkPriceToolManager';

import { PathUtils } from 'shared/utils/pathUtils';

import { UserAccountManagerImpl } from './account/impl/UserAccountManagerImpl';
import { UserEmailAddressVerifierImpl } from './account/impl/UserEmailAddressVerifierImpl';
import { UserPasswordManagerImpl } from './account/impl/UserPasswordManagerImpl';
import { UserSessionManagerImpl } from './account/impl/UserSessionManagerImpl';
import { CookieManagerImpl } from './cookie/impl/CookieManagerImpl';
import { RuntimeException } from './general/exceptions/RuntimeException';

import { IntegrationJSONToObjectSerializer } from 'api/Integration/serializer/IntegrationJSONToObjectSerializer';
import { LocationSettingsServiceImpl } from 'api/Location/impl/LocationSettingsServiceImpl';
import { SalesImportServiceImpl } from 'api/SalesData/impl/SalesImportServiceImpl';
import { SalesSettingsServiceImpl } from 'api/SalesData/impl/SalesSettingsServiceImpl';
import { UnauthenticatedUserSessionManagerImpl } from './unauthenticated_user_session/impl/UnauthenticatedUserSessionManagerImpl';
import { QueryStringManagerImpl } from './url/impl/QueryStringManagerImpl';
import { UserInfoManagerImpl } from './user_info/impl/UserInfoManagerImpl';

// PRIVATE
const getThriftProtocol = (thriftServiceName : string) : Thrift.TJSONProtocol => {
    if (thriftServiceName.length === 0) {
        throw new RuntimeException('thriftServiceName must not be empty string');
    }

    return new Thrift.TJSONProtocol(
        new Thrift.TXHRTransport(
            PathUtils.getAbsolutePathForRequest('/t/' + thriftServiceName),
            { useCORS: true }
        )
    );
};

// IMPLS
export const cookieManager = new CookieManagerImpl();

export const userInfoManager = new UserInfoManagerImpl();

export const queryStringManager = new QueryStringManagerImpl();

export const offlineQueueManager = new OfflineQueueManagerImpl();

export const unauthenticatedUserSessionManager = new UnauthenticatedUserSessionManagerImpl(
    new UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient(getThriftProtocol('UnauthenticatedUserSessionService')),
    new UserInfoManagerImpl(),
    cookieManager,
    cookieManager
);

const userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer = new UserAccountObjectToThriftSerializer();
const userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer = new UserAccountThriftToObjectSerializer();

const scopeObjectToThriftSerializer : ScopeObjectToThriftSerializer = new ScopeObjectToThriftSerializer();
const scopeThriftToObjectSerializer : ScopeThriftToObjectSerializer = new ScopeThriftToObjectSerializer();

const distributorObjectToThriftSerializer : DistributorObjectToThriftSerializer = new DistributorObjectToThriftSerializer(scopeObjectToThriftSerializer);

const distributorObjectToJsonSerializer = new DistributorObjectToJSONSerializer();

const locationJSONToObjectSerializer = new LocationJSONToObjectSerializer();
const locationObjectToThriftSerializer = new LocationObjectToThriftSerializer();
const locationThriftToObjectSerializer = new LocationThriftToObjectSerializer();

export const productThriftToObjectSerializer = new ProductThriftToObjectSerializer();   // currently some models/components still use thrift-gen models and need this serializer - remove the export when they are cleaned up
export const productObjectToThriftSerializer = new ProductObjectToThriftSerializer();

const distributorThriftToObjectSerializer : DistributorThriftToObjectSerializer = new DistributorThriftToObjectSerializer(scopeThriftToObjectSerializer, productThriftToObjectSerializer);

// this should not be exported, but because we use the thrift time model in various models right now, this is to help conversion
export const momentObjectToThriftSerializer = new MomentObjectToThriftSerializer();

export const userSessionManager = new UserSessionManagerImpl(
    new UserSessionService.UserSessionServiceClient(getThriftProtocol('UserSessionService')),
    cookieManager,
    cookieManager,
    cookieManager,
    userAccountObjectToThriftSerializer,
);

export const userPasswordManager = new UserPasswordManagerImpl(
    new UserPasswordService.UserPasswordServiceClient(getThriftProtocol('UserPasswordService')),
    unauthenticatedUserSessionManager,
    userSessionManager,
    userAccountObjectToThriftSerializer,
    userAccountThriftToObjectSerializer
);

export const userEmailAddressVerifier = new UserEmailAddressVerifierImpl(
    new UserEmailAddressVerificationService.UserEmailAddressVerificationServiceClient(getThriftProtocol('UserEmailAddressVerificationService')),
    userSessionManager,
    userAccountObjectToThriftSerializer,
);

export const userAccountManager = new UserAccountManagerImpl(
    new UserAccountService.UserAccountServiceClient(getThriftProtocol('UserAccountService')),
    unauthenticatedUserSessionManager,
    userSessionManager,
    userAccountThriftToObjectSerializer
);

export const drinkPriceToolManager = new DrinkPriceToolManager(
    new DrinkPriceToolService.DrinkPriceToolServiceClient(getThriftProtocol('DrinkPriceToolService')),
    userAccountObjectToThriftSerializer,
);

export const DistributorServiceInstance = new DistributorService(
    new ThriftDistributorService.DistributorServiceClient(getThriftProtocol('DistributorService')),
    distributorObjectToThriftSerializer,
    distributorThriftToObjectSerializer,
    userAccountObjectToThriftSerializer,
    new LocationObjectToThriftSerializer(),
);

export const DistributorNameMappingServiceInstance = new DistributorNameMappingServiceImpl(distributorObjectToJsonSerializer);

export const ProductDistributorServiceInstance = new ProductDistributorServiceImpl(
    new ThriftProductService.ProductServiceClient(getThriftProtocol('ProductService')),
    productObjectToThriftSerializer,
    productThriftToObjectSerializer,
    distributorObjectToThriftSerializer,
    distributorThriftToObjectSerializer,
    userAccountObjectToThriftSerializer,
    DistributorServiceInstance,
);

const productDistributorAssociationObjectToThriftSerializer = new ProductDistributorAssociationObjectToThriftSerializer(productObjectToThriftSerializer, distributorObjectToThriftSerializer);
const productDistributorAssociationThriftToObjectSerializer = new ProductDistributorAssociationThriftToObjectSerializer(productThriftToObjectSerializer, distributorThriftToObjectSerializer);

export const productDistributorAssociationService = new ProductDistributorAssociationServiceImpl(
    new ProductDistributorService.ProductDistributorServiceClient(getThriftProtocol('ProductDistributorService')),
    productObjectToThriftSerializer,
    distributorObjectToThriftSerializer,
    userAccountObjectToThriftSerializer,
    productDistributorAssociationObjectToThriftSerializer,
    productDistributorAssociationThriftToObjectSerializer
);

const publicContentObjectToThriftSerializer : PublicContentObjectToThriftSerializer = new PublicContentObjectToThriftSerializer();
const publicContentThriftToObjectSerializer : PublicContentThriftToObjectSerializer = new PublicContentThriftToObjectSerializer();

export const publicContentViewEventService = new PublicContentViewEventServiceImpl(
    new ThriftPublicContentViewEventService.PublicContentViewEventServiceClient(getThriftProtocol('PublicContentViewEventService')),
    userAccountObjectToThriftSerializer,
    publicContentObjectToThriftSerializer,
    publicContentThriftToObjectSerializer
);

const inviteInfoJSONToObjectSerializer = new InviteInfoJSONToObjectSerializer();
export const userInviteService = new UserInviteServiceImpl(inviteInfoJSONToObjectSerializer);

export const usageDataJSONToObjectSerializer : UsageDataSerializer = new UsageDataSerializer();

const inventoryCountObjectToThriftSerializer = new InventoryCountObjectToThriftSerializer(userAccountObjectToThriftSerializer, productObjectToThriftSerializer);
const inventoryCountThriftToObjectSerializer = new InventoryCountThriftToObjectSerializer(userAccountThriftToObjectSerializer, productThriftToObjectSerializer);

export const inventoryCountService = new InventoryCountServiceImpl(
    new InventoryCountService.InventoryCountServiceClient(getThriftProtocol('InventoryCountService')),
    offlineQueueManager,
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    productObjectToThriftSerializer,
    inventoryCountObjectToThriftSerializer,
    inventoryCountThriftToObjectSerializer,
    momentObjectToThriftSerializer
);

export const currentInventoryConfigurationService = new CurrentInventoryConfigurationServiceImpl(
    new InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient(getThriftProtocol('InventoryCurrentConfigurationService')),
    offlineQueueManager,
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    productObjectToThriftSerializer,
    inventoryCountObjectToThriftSerializer,
    inventoryCountThriftToObjectSerializer
);

export const productJSONToObjectSerializer = new ProductJSONToObjectSerializer();
export const productObjectToJSONSerializer = new ProductObjectToJSONSerializer();
export const productQuickAddJSONToObjectSerializer = new ProductQuickAddJSONToObjectSerializer();

export const productService = new CachedProductServiceImpl(new ProductServiceImpl(productJSONToObjectSerializer, productObjectToJSONSerializer));
export const categoryService = new CategoryServiceImpl(productJSONToObjectSerializer, productObjectToJSONSerializer);

export const productQuickAddService = new ProductQuickAddServiceImpl(productQuickAddJSONToObjectSerializer);
export const inventorySheetsService = new InventorySheetsServiceImpl();

export const locationProductService = new LocationProductServiceImpl(productJSONToObjectSerializer);
export const locationService = new LocationServiceImpl();

const productUploadJSONToObjectSerializer = new ProductUploadJSONToObjectSerializer(productJSONToObjectSerializer);
export const productUploadService = new ProductUploadServiceImpl(productUploadJSONToObjectSerializer);

export const productCostService = new ProductCostServiceImpl(
    new ProductCostService.ProductCostServiceClient(getThriftProtocol('ProductCostService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    productObjectToThriftSerializer,
    productThriftToObjectSerializer,
    inventoryCountObjectToThriftSerializer,
);

export const productParService = new ProductParServiceImpl(
    new ProductParService.ProductParServiceClient(getThriftProtocol('ProductParService')),
    userAccountObjectToThriftSerializer,
    productObjectToThriftSerializer,
    productThriftToObjectSerializer,
);

export const productMergeService = new ProductMergeServiceImpl(
    productJSONToObjectSerializer,
    productObjectToJSONSerializer,
);

export const usageDataService = new UsageDataServiceImpl(usageDataJSONToObjectSerializer, productService);

const cartJSONToObjectSerializer = new CartJSONToObjectSerializer(productJSONToObjectSerializer);
const cartObjectToJSONSerializer = new CartObjectToJSONSerializer();

export const cartService = new CartServiceImpl(cartJSONToObjectSerializer, cartObjectToJSONSerializer, productObjectToJSONSerializer);

const orderingJSONToObjectSerializer = new OrderingJSONToObjectSerializer(productJSONToObjectSerializer);
export const orderingObjectToJSONSerializer = new OrderingObjectToJSONSerializer(productObjectToJSONSerializer);

export const orderingService = new OrderingServiceImpl(orderingJSONToObjectSerializer, orderingObjectToJSONSerializer);
export const invoiceUploadService = new InvoiceUploadServiceImpl(orderingJSONToObjectSerializer);

const transferObjectToThriftSerializer = new TransferObjectToThriftSerializer(locationObjectToThriftSerializer, productObjectToThriftSerializer);

// exported due to legacy transfer code which do not use the service
export const transferThriftToObjectSerializer = new TransferThriftToObjectSerializer(userAccountThriftToObjectSerializer, productThriftToObjectSerializer, locationThriftToObjectSerializer);

export const transferService = new TransferServiceImpl(
    new InventoryTransferReportService.InventoryTransferReportServiceClient(getThriftProtocol('InventoryTransferReportService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    transferObjectToThriftSerializer,
    transferThriftToObjectSerializer,
    momentObjectToThriftSerializer,
);

const productMappingObjectToThriftSerializer = new ProductMappingObjectToThriftSerializer(locationObjectToThriftSerializer, productObjectToThriftSerializer);
const productMappingThriftToObjectSerializer = new ProductMappingThriftToObjectSerializer(locationThriftToObjectSerializer, productThriftToObjectSerializer);

export const productMappingService = new ProductMappingServiceImpl(
    new InterLocationProductMappingService.InterLocationProductMappingServiceClient(getThriftProtocol('InterLocationProductMappingService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    productMappingObjectToThriftSerializer,
    productMappingThriftToObjectSerializer,
);

const breakageObjectToThriftSerializer = new BreakageObjectToThriftSerializer(productObjectToThriftSerializer);
const breakageThriftToObjectSerializer = new BreakageThriftToObjectSerializer(userAccountThriftToObjectSerializer, productThriftToObjectSerializer, locationThriftToObjectSerializer);

export const breakageService = new BreakageServiceImpl(
    new BreakageReportService.BreakageReportServiceClient(getThriftProtocol('BreakageReportService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    breakageObjectToThriftSerializer,
    breakageThriftToObjectSerializer,
    momentObjectToThriftSerializer,
);

export const inventoryCountViewPreferencesService = new InventoryCountViewPreferencesServiceImpl(offlineQueueManager);

const itemLevelSalesReportConfigurationSerializer = new ItemLevelSalesReportConfigurationSerializer();
export const itemLevelSalesReportConfigurationService = new ItemLevelSalesReportConfigurationServiceImpl(itemLevelSalesReportConfigurationSerializer);

const categoryLevelSalesReportConfigurationSerializer = new CategoryLevelSalesReportConfigurationSerializer();
export const categoryLevelSalesReportConfigurationService = new CategoryLevelSalesReportConfigurationServiceImpl(categoryLevelSalesReportConfigurationSerializer);

export const userAccountInfoService = new UserAccountInfoServiceImpl(locationJSONToObjectSerializer);
export const adminService = new AdminServiceImpl();

export const toastIntegrationService = new ToastIntegrationServiceImpl(new IntegrationJSONToObjectSerializer());

export const incidentBannerService = new IncidentBannerServiceImpl();

export const plateIQIntegrationService = new PlateIQIntegrationServiceImpl(new IntegrationJSONToObjectSerializer());

export const integrationService = new POSIntegrationServiceImpl(
    new IntegrationService.IntegrationServiceClient(getThriftProtocol('IntegrationService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    momentObjectToThriftSerializer
);

export const vendorIntegrationService = new VendorIntegrationServiceImpl();

export const gettingStartedGuideService = new GettingStartedGuideService(new WelcomeQuestionnaireResponseObjectToJsonSerializer());

const prepEventObjectToThriftSerializer = new PrepEventObjectToThriftSerializer();
const prepEventThriftToObjectSerializer = new PrepEventThriftToObjectSerializer(userAccountThriftToObjectSerializer, productThriftToObjectSerializer);
export const prepEventService = new PrepEventServiceImpl(
    new PrepEventService.PrepEventServiceClient(getThriftProtocol('PrepEventService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    productThriftToObjectSerializer,
    productObjectToThriftSerializer,
    momentObjectToThriftSerializer,
    prepEventObjectToThriftSerializer,
    prepEventThriftToObjectSerializer
);

const catalogJSONToObjectSerializer = new CatalogJSONToObjectSerializer(productJSONToObjectSerializer);
export const productAndCatalogSearchService = new ProductAndCatalogSearchService(catalogJSONToObjectSerializer);

const billingDataSerializer = new BillingDataSerializer();
export const stripeCustomerService = new StripeCustomerServiceImpl(billingDataSerializer);

export const billingAddOnService = new BillingAddOnServiceImpl();

export const delinquentPaymentStatusService = new DelinquentPaymentStatusService();

export const presentationCacheDataService = new PresentationCacheDataService();

const retailerPopulationFromInvoiceUploadJSONToObjectSerializer = new RetailerPopulationFromInvoiceUploadJSONToObjectSerializer(productUploadJSONToObjectSerializer);

export const retailerPopulationFromInvoiceUploadService = new RetailerPopulationFromInvoiceUploadServiceImpl(retailerPopulationFromInvoiceUploadJSONToObjectSerializer);

export const userActionLogCommentService = new UserActionLogCommentService(
    new UserActionLogService.UserActionLogServiceClient(getThriftProtocol('UserActionLogService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    userAccountThriftToObjectSerializer,
    momentObjectToThriftSerializer,
    );

const salesItemThriftToObjectSerializer = new SalesItemThriftToObjectSerializer(userAccountThriftToObjectSerializer, productThriftToObjectSerializer, locationThriftToObjectSerializer);
const salesItemObjectToThriftSerializer = new SalesItemObjectToThriftSerializer(locationObjectToThriftSerializer, productObjectToThriftSerializer);

export const salesItemService = new SalesItemServiceImpl(
    new SalesItemService.SalesItemServiceClient(getThriftProtocol('SalesItemService')),
    new AdminSalesItemService.AdminSalesItemServiceClient(getThriftProtocol('AdminSalesItemService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    momentObjectToThriftSerializer,
    salesItemThriftToObjectSerializer,
    salesItemObjectToThriftSerializer,
    itemLevelSalesReportConfigurationSerializer
);

export const locationSettingsService = new LocationSettingsServiceImpl(productJSONToObjectSerializer);

export const salesImportService = new SalesImportServiceImpl(
    new IntegrationService.IntegrationServiceClient(getThriftProtocol('IntegrationService')),
    userAccountObjectToThriftSerializer,
    locationObjectToThriftSerializer,
    momentObjectToThriftSerializer,
    salesItemThriftToObjectSerializer
);

export const POSUploadService = new PmixUploadServiceImpl(itemLevelSalesReportConfigurationSerializer);

export const salesSettingsService = new SalesSettingsServiceImpl();

export const expectedInventoryService = new ExpectedInventoryServiceImpl(new ExpectedInventoryDeserializer());
