import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class Packaging {
    constructor(
        private readonly packagingId : PackagingId | null,
        private readonly name : string | null,
        private readonly content : Packaging | null,
        private readonly quantityOfContent : number | null,
        private readonly unit : MassUnit | VolumeUnit | null,
    ) {
        if (unit) {
            if (packagingId !== null) {
                throw new RuntimeException('packagingId must be null when unit exists');
            }

            if (name !== null) {
                throw new RuntimeException('name must be null when unit exists');
            }

            if (content !== null) {
                throw new RuntimeException('content must be null when unit exists');
            }

            if (quantityOfContent !== null) {
                throw new RuntimeException('quantityOfContent must be null when unit exists');
            }
        } else {
            if (packagingId === null) {
                throw new RuntimeException('content must not be null when unit does not exist');
            }

            if (name === null) { // PTODO
                throw new RuntimeException('content must not be null when unit does not exist');
            }

            if ((content === null) !== (quantityOfContent === null)) {
                throw new RuntimeException('content and quantityOfContent must either both exist or both be null when unit does not exist');
            }
        }
    }

    public getPackagingId() : PackagingId | null {
        return this.packagingId;
    }

    public getName() : string | null {
        return this.name;
    }

    public getContent() : Packaging | null {
        return this.content;
    }

    public getQuantityOfContent() : number | null {
        return this.quantityOfContent;
    }

    public getUnit() : MassUnit | VolumeUnit | null {
        return this.unit;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof Packaging)) {
            return false;
        }

        const otherPackagingId = other.getPackagingId();
        const otherContent = other.getContent();

        if ((this.packagingId === otherPackagingId) || ((this.packagingId && otherPackagingId) ? this.packagingId.equals(otherPackagingId) : false) &&
            (this.name === other.getName()) &&
            (this.quantityOfContent === other.getQuantityOfContent()) &&
            (this.unit === other.getUnit())) {
            return (this.content === otherContent) || ((this.content && otherContent) ? this.content.equals(otherContent) : false);
        }

        return false;
    }

    public toString() : string {
        return `{packagingId: ${this.getPackagingId()}, name: ${this.getName()}, content: ${this.getContent()}, quantityOfContent: ${this.getQuantityOfContent()}, unit: ${this.getUnit()}`;
    }
}
