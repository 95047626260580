//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import DistributorModel from './distributor_Model_types';
import ProductModel from './product_Model_types';



var CartModel = {};
export default CartModel;
CartModel.CartItem = function(args) {
  this.productId = null;
  this.distributorId = null;
  this.productQuantityUnit = null;
  this.quantityInUnits = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    }
    if (args.productQuantityUnit !== undefined && args.productQuantityUnit !== null) {
      this.productQuantityUnit = new ProductModel.ProductQuantityUnit(args.productQuantityUnit);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnit is unset!');
    }
    if (args.quantityInUnits !== undefined && args.quantityInUnits !== null) {
      this.quantityInUnits = args.quantityInUnits;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantityInUnits is unset!');
    }
  }
};
CartModel.CartItem.prototype = {};
CartModel.CartItem.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.productQuantityUnit = new ProductModel.ProductQuantityUnit();
        this.productQuantityUnit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.DOUBLE) {
        this.quantityInUnits = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CartModel.CartItem.prototype.write = function(output) {
  output.writeStructBegin('CartItem');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnit !== null && this.productQuantityUnit !== undefined) {
    output.writeFieldBegin('productQuantityUnit', Thrift.Type.STRUCT, 3);
    this.productQuantityUnit.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityInUnits !== null && this.quantityInUnits !== undefined) {
    output.writeFieldBegin('quantityInUnits', Thrift.Type.DOUBLE, 4);
    output.writeDouble(this.quantityInUnits);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CartModel.Cart = function(args) {
  this.cartItems = null;
  if (args) {
    if (args.cartItems !== undefined && args.cartItems !== null) {
      this.cartItems = Thrift.copyList(args.cartItems, [CartModel.CartItem]);
    }
  }
};
CartModel.Cart.prototype = {};
CartModel.Cart.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.cartItems = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new CartModel.CartItem();
          elem6.read(input);
          this.cartItems.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CartModel.Cart.prototype.write = function(output) {
  output.writeStructBegin('Cart');
  if (this.cartItems !== null && this.cartItems !== undefined) {
    output.writeFieldBegin('cartItems', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.cartItems.length);
    for (var iter7 in this.cartItems)
    {
      if (this.cartItems.hasOwnProperty(iter7))
      {
        iter7 = this.cartItems[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

